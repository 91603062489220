/** @jsxImportSource @emotion/react */
import React, { FC, useEffect } from "react";
import { toast } from "sonner";
import { addDays } from "date-fns";

import { Spinner } from "src/@/components/ui/spinner";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "src/@/components/ui/dialog";
import { TimeInput } from "#dashboard/components/pause-orders/pause-orders-modal/time-input";
import { Button } from "src/@/components/ui/button";
import { Separator } from "src/@/components/ui/separator";
import { Calendar } from "src/@/components/ui/calendar";

import {
    Action,
    PauseReason,
    PauseTime,
    useToggleOrders,
} from "../use-toggle-orders";

import { ReasonInput } from "./reason-input";

interface ModalProps {
    showModal: boolean;
    setShowModal: (e: boolean) => void;
}

export const PauseOrdersModal: FC<ModalProps> = ({
    showModal,
    setShowModal,
}) => {
    const {
        isLoading,
        resetFields,
        selectedTime,
        setSelectedTime,
        selectedDate,
        setSelectedDate,
        selectedReason,
        setSelectedReason,
        toggleOrders,
        closedUntil,
        customReason,
        setCustomReason,
    } = useToggleOrders();

    const onConfirm = async () => {
        if (isLoading) return;
        if (selectedReason === PauseReason.Other && customReason.length < 1) {
            toast.error("Please enter a custom reason");
            return;
        }
        await toggleOrders({ action: Action.Pause });
        resetFields();
        setShowModal(false);
    };

    useEffect(() => {
        if (closedUntil) {
            toast.success(`App and Online orders paused until ${closedUntil}`, {
                description:
                    "If desired, please manually refund any orders scheduled for this period.",
            });
        }
    }, [closedUntil]);
    return (
        <>
            <Dialog onOpenChange={setShowModal} open={showModal}>
                <DialogContent className="min-w-min">
                    <DialogHeader>
                        <DialogTitle>Pause Online Orders</DialogTitle>
                        <DialogDescription>
                            Stop new App and Online Orders from coming in for a
                            set amount of time. In-Person Orders won’t be
                            affected.
                        </DialogDescription>
                    </DialogHeader>

                    <Separator />
                    <div className="flex max-h-80 min-w-min flex-col space-y-4 overflow-y-auto p-1 lg:max-h-[50vh]">
                        <TimeInput
                            selectedOption={selectedTime}
                            selectOption={setSelectedTime}
                        />

                        <div className="flex w-full flex-col items-stretch justify-between lg:flex-row lg:space-x-4">
                            {selectedTime === PauseTime.Custom ? (
                                <div className="flex flex-col">
                                    <h6 className="my-4">Pause orders until</h6>
                                    <Calendar
                                        onSelect={(e?: Date) => {
                                            setSelectedDate(e);
                                        }}
                                        mode="single"
                                        className="self-center"
                                        selected={selectedDate}
                                        disabled={{
                                            before: addDays(new Date(), 1),
                                            after: addDays(new Date(), 30),
                                        }}
                                    />
                                </div>
                            ) : null}
                            <ReasonInput
                                options={PauseReason}
                                selectedOption={selectedReason}
                                selectOption={setSelectedReason}
                                customReason={customReason}
                                setCustomReason={setCustomReason}
                            />
                        </div>
                    </div>
                    <Separator className="mt-3" />
                    <DialogFooter>
                        <div className="flex w-full justify-end space-x-2">
                            <Button
                                variant="ghost"
                                onClick={() => setShowModal(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={onConfirm}
                                disabled={
                                    !selectedReason ||
                                    (selectedReason === PauseReason.Other &&
                                        customReason.length === 0) ||
                                    isLoading
                                }
                            >
                                {isLoading ? <Spinner /> : "Confirm"}
                            </Button>
                        </div>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    );
};
