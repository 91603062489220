import { useMemo } from "react";
import { PaymentProvider } from "@snackpass/snackpass-types";
import { Button } from "src/@/components/ui/button";
import { CalendarCheck, Printer } from "lucide-react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "src/@/components/ui/table";
import { formatCurrency } from "src/@/lib/format-currency";
import { useOrders } from "../../orders-context";
import { DateTime } from "luxon";
import { formatPurchaseProvider } from "#utils/helpers";

// Type definitions for report data
type AmountCountPair = { total: number; count: number };
type OrderTypeBreakdown = {
    dineIn: AmountCountPair;
    delivery: AmountCountPair;
    pickup: AmountCountPair;
};
type PaymentBreakdown = {
    cashPayments: AmountCountPair;
    dineInCashPayments: AmountCountPair;
    deliveryCashPayments: AmountCountPair;
    pickupCashPayments: AmountCountPair;
    cardPayments: AmountCountPair;
    dineInCardPayments: AmountCountPair;
    deliveryCardPayments: AmountCountPair;
    pickupCardPayments: AmountCountPair;
    thirdPartyPayments: AmountCountPair;
    providerBreakdown?: Array<{ name: string; count: number; total: number }>;
};
type ReportData = {
    // Financial summary
    grossSales: number;
    discountTotal: number;
    discountCount: number;
    netSales: number;
    totalTax: number;
    totalProcessingFee: number;
    totalSales: number;

    // Order stats
    orderCount: number;

    // Tips
    totalTips: number;
    dineInTips: number;
    pickupTips: number;
    deliveryTips: number;

    // Voids and refunds
    totalVoidAmount: number;
    voidCount: number;
    refundTotal: number;
    refundCount: number;

    // Cash management
    paidOutTotal: number;
    expectedCashInDrawers: number;
    tipOutTotal: number;

    // Breakdowns
    orderTypeBreakdown: OrderTypeBreakdown;
    paymentBreakdown: PaymentBreakdown;
};

// Reusable section component
const ReportSection = ({
    title,
    children,
}: {
    title?: string;
    children: React.ReactNode;
}) => (
    <div className="mb-6">
        {title && <h3 className="text-lg font-semibold mt-6 mb-2">{title}</h3>}
        {children}
    </div>
);

// Reusable table row component
const DataRow = ({
    label,
    value,
    count,
    indent = false,
    bold = false,
}: {
    label: string;
    value: number;
    count?: number;
    indent?: boolean;
    bold?: boolean;
}) => (
    <TableRow>
        <TableCell
            className={`${indent ? "pl-8" : ""} ${bold ? "font-semibold" : ""}`}
        >
            {label}
        </TableCell>
        <TableCell className={`text-right ${bold ? "font-semibold" : ""}`}>
            {formatCurrency(value)}
        </TableCell>
        {count !== undefined && (
            <TableCell className="text-right">{count}</TableCell>
        )}
        {count === undefined && <TableCell></TableCell>}
    </TableRow>
);

// Add a TableHead component with consistent styling for all tables
const TableHeadWithWidth = ({
    children,
    className,
    width,
}: {
    children: React.ReactNode;
    className?: string;
    width?: string;
}) => (
    <TableHead className={`${className || ""} ${width || ""}`}>
        {children}
    </TableHead>
);

export const EndOfDayReportButton = () => {
    const { setShowEndOfDayReport } = useOrders();

    return (
        <Button variant="outline" onClick={() => setShowEndOfDayReport(true)}>
            <CalendarCheck className="mr-2 h-4 w-4" />
            End of Day Report
        </Button>
    );
};

export const EndOfDayReport = () => {
    const {
        startDate,
        endDate,
        purchaseReportTotals: { total: report, isLoading: reportLoading },
        purchaseReportRows: { data: rows, isLoading: rowsLoading },
    } = useOrders();

    // Convert dates directly using Luxon
    const { startLuxon, endLuxon } = useMemo(
        () => ({
            startLuxon: DateTime.fromISO(startDate.toISOString()),
            endLuxon: DateTime.fromISO(endDate.toISOString()),
        }),
        [startDate, endDate],
    );

    const getDateString = () => {
        if (startLuxon.year !== endLuxon.year) {
            return `${startLuxon.toFormat("LLL d, yyyy")} ${startLuxon.toFormat("h:mm a")} – ${endLuxon.toFormat("LLL d, yyyy")} ${endLuxon.toFormat("h:mm a")}`;
        } else if (startLuxon.hasSame(endLuxon, "day")) {
            return `${endLuxon.toFormat("LLLL d, yyyy")} ${startLuxon.toFormat("h:mm a")} – ${endLuxon.toFormat("h:mm a")}`;
        }
        return `${startLuxon.toFormat("LLL dd")} ${startLuxon.toFormat("h:mm a")} – ${endLuxon.toFormat("LLL d, yyyy")} ${endLuxon.toFormat("h:mm a")}`;
    };

    const getCurrentTimestamp = () => {
        return DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss");
    };

    // Helper function to calculate total from orders
    const calculateOrderTotal = (orders: any[]) => {
        return orders.reduce((sum, order) => sum + (order.netSales || 0), 0);
    };

    // Process report data
    const reportData = useMemo((): ReportData => {
        if (!report || !rows?.length) {
            return {
                grossSales: 0,
                discountTotal: 0,
                discountCount: 0,
                netSales: 0,
                totalTax: 0,
                totalProcessingFee: 0,
                totalSales: 0,
                totalTips: 0,
                dineInTips: 0,
                pickupTips: 0,
                deliveryTips: 0,
                orderCount: 0,
                totalVoidAmount: 0,
                voidCount: 0,
                paidOutTotal: 0,
                expectedCashInDrawers: 0,
                tipOutTotal: 0,
                refundTotal: 0,
                refundCount: 0,
                orderTypeBreakdown: {
                    dineIn: { total: 0, count: 0 },
                    delivery: { total: 0, count: 0 },
                    pickup: { total: 0, count: 0 },
                },
                paymentBreakdown: {
                    cashPayments: { total: 0, count: 0 },
                    dineInCashPayments: { total: 0, count: 0 },
                    deliveryCashPayments: { total: 0, count: 0 },
                    pickupCashPayments: { total: 0, count: 0 },
                    cardPayments: { total: 0, count: 0 },
                    dineInCardPayments: { total: 0, count: 0 },
                    deliveryCardPayments: { total: 0, count: 0 },
                    pickupCardPayments: { total: 0, count: 0 },
                    thirdPartyPayments: { total: 0, count: 0 },
                    providerBreakdown: [],
                },
            };
        }

        // First, categorize orders by different criteria to avoid repeated filtering
        const orderCategories = {
            // By fulfillment type
            dineIn: rows.filter(
                (order) => order.purchase?.fulfillment === "DINE_IN",
            ),
            delivery: rows.filter(
                (order) => order.purchase?.fulfillment === "DELIVERY",
            ),
            pickup: rows.filter(
                (order) => order.purchase?.fulfillment === "PICKUP",
            ),

            // By payment type
            cash: rows.filter(
                (order) =>
                    order.purchase?.paymentProviderId ===
                    PaymentProvider.inStore,
            ),
            card: rows.filter(
                (order) =>
                    order.purchase?.paymentProviderId ===
                    PaymentProvider.stripe,
            ),

            // Special categories
            voided: rows.filter((order) =>
                order.purchase?.auditEvents?.some(
                    (event) => event.action === "PURCHASE_VOIDED",
                ),
            ),
            refunded: rows.filter((order) =>
                order.purchase?.auditEvents?.some(
                    (event) => event.action === "PURCHASE_REFUNDED",
                ),
            ),
        };

        // Calculate void amount
        const totalVoidAmount = orderCategories.voided.reduce(
            (sum, order) =>
                sum +
                (order.items?.reduce(
                    (itemSum, item) => itemSum + (item.totalPrice || 0),
                    0,
                ) || 0),
            0,
        );

        // Calculate tips by order type
        const dineInTips = orderCategories.dineIn.reduce(
            (sum, order) => sum + (order.tip || 0),
            0,
        );
        const pickupTips = orderCategories.pickup.reduce(
            (sum, order) => sum + (order.tip || 0),
            0,
        );
        const deliveryTips = orderCategories.delivery.reduce(
            (sum, order) => sum + (order.tip || 0),
            0,
        );

        // Create payment breakdowns by order type
        const paymentByOrderType = {
            dineInCash: orderCategories.dineIn.filter(
                (order) =>
                    order.purchase?.paymentProviderId ===
                    PaymentProvider.inStore,
            ),
            dineInCard: orderCategories.dineIn.filter(
                (order) =>
                    order.purchase?.paymentProviderId ===
                    PaymentProvider.stripe,
            ),
            deliveryCash: orderCategories.delivery.filter(
                (order) =>
                    order.purchase?.paymentProviderId ===
                    PaymentProvider.inStore,
            ),
            deliveryCard: orderCategories.delivery.filter(
                (order) =>
                    order.purchase?.paymentProviderId ===
                    PaymentProvider.stripe,
            ),
            pickupCash: orderCategories.pickup.filter(
                (order) =>
                    order.purchase?.paymentProviderId ===
                    PaymentProvider.inStore,
            ),
            pickupCard: orderCategories.pickup.filter(
                (order) =>
                    order.purchase?.paymentProviderId ===
                    PaymentProvider.stripe,
            ),
        };

        // Process provider breakdown
        const providerMap = new Map<string, { count: number; total: number }>();
        rows.forEach((order) => {
            const providerName = formatPurchaseProvider(order.purchase);
            const existing = providerMap.get(providerName) || {
                count: 0,
                total: 0,
            };
            providerMap.set(providerName, {
                count: existing.count + 1,
                total: existing.total + (order.netSales || 0),
            });
        });

        return {
            // Financial summary
            grossSales: report.grossSalesAmount || 0,
            // @ts-expect-error
            discountTotal: report.discountsAndComps || 0,
            // Estimate discount count as a percentage of total orders
            discountCount: Math.round((report.count || 0) * 0.3), // Assuming roughly 30% of orders have discounts
            netSales: report.netSales || 0,
            totalTax:
                (report.storeTaxesToRemit || 0) +
                (report.store3PTaxesToRemit || 0),
            totalProcessingFee: report.processingFee || 0,
            totalSales: report.totalSales || 0,

            // Order stats
            orderCount: report.count || 0,

            // Tips
            totalTips: report.tip || 0,
            dineInTips,
            pickupTips,
            deliveryTips,

            // Voids and refunds
            totalVoidAmount,
            voidCount: orderCategories.voided.length,
            refundTotal: report.refundedAmount || 0,
            refundCount: orderCategories.refunded.length,

            // Cash management
            paidOutTotal: 0, // This would come from cash drawer reporting
            expectedCashInDrawers: report.expectedCashCollected || 0,
            tipOutTotal: 0, // This would come from SnackOS

            // Breakdowns
            orderTypeBreakdown: {
                dineIn: {
                    total: calculateOrderTotal(orderCategories.dineIn),
                    count: orderCategories.dineIn.length,
                },
                delivery: {
                    total: calculateOrderTotal(orderCategories.delivery),
                    count: orderCategories.delivery.length,
                },
                pickup: {
                    total: calculateOrderTotal(orderCategories.pickup),
                    count: orderCategories.pickup.length,
                },
            },
            paymentBreakdown: {
                cashPayments: {
                    total:
                        calculateOrderTotal(paymentByOrderType.dineInCash) +
                        calculateOrderTotal(paymentByOrderType.deliveryCash) +
                        calculateOrderTotal(paymentByOrderType.pickupCash),
                    count: orderCategories.cash.length,
                },
                dineInCashPayments: {
                    total: calculateOrderTotal(paymentByOrderType.dineInCash),
                    count: paymentByOrderType.dineInCash.length,
                },
                deliveryCashPayments: {
                    total: calculateOrderTotal(paymentByOrderType.deliveryCash),
                    count: paymentByOrderType.deliveryCash.length,
                },
                pickupCashPayments: {
                    total: calculateOrderTotal(paymentByOrderType.pickupCash),
                    count: paymentByOrderType.pickupCash.length,
                },
                cardPayments: {
                    total:
                        calculateOrderTotal(paymentByOrderType.dineInCard) +
                        calculateOrderTotal(paymentByOrderType.deliveryCard) +
                        calculateOrderTotal(paymentByOrderType.pickupCard),
                    count: orderCategories.card.length,
                },
                dineInCardPayments: {
                    total: calculateOrderTotal(paymentByOrderType.dineInCard),
                    count: paymentByOrderType.dineInCard.length,
                },
                deliveryCardPayments: {
                    total: calculateOrderTotal(paymentByOrderType.deliveryCard),
                    count: paymentByOrderType.deliveryCard.length,
                },
                pickupCardPayments: {
                    total: calculateOrderTotal(paymentByOrderType.pickupCard),
                    count: paymentByOrderType.pickupCard.length,
                },
                thirdPartyPayments: {
                    total: calculateOrderTotal(rows),
                    count: rows.length,
                },
                providerBreakdown: Array.from(providerMap.entries()).map(
                    ([name, data]) => ({
                        name,
                        count: data.count,
                        total: data.total,
                    }),
                ),
            },
        };
    }, [report, rows]);

    // Render loading or empty state
    const renderLoadingOrEmpty = () => {
        if (reportLoading || rowsLoading) {
            return (
                <div className="flex items-center justify-center p-8">
                    <p>Loading report data...</p>
                </div>
            );
        }

        if (!report || !rows?.length) {
            return (
                <div className="flex items-center justify-center p-8">
                    <p>No data available for the selected date range.</p>
                </div>
            );
        }

        return null;
    };

    // Render financial summary section
    const renderFinancialSummary = () => (
        <ReportSection title="Financial Summary">
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHeadWithWidth
                            className="text-left font-bold"
                            width="w-1/2"
                        >
                            Category
                        </TableHeadWithWidth>
                        <TableHeadWithWidth
                            className="text-right font-bold"
                            width="w-1/4"
                        >
                            Amount
                        </TableHeadWithWidth>
                        <TableHeadWithWidth
                            className="text-right font-bold"
                            width="w-1/4"
                        >
                            Orders
                        </TableHeadWithWidth>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    <DataRow
                        label="Gross Sales"
                        value={reportData.grossSales}
                        count={reportData.orderCount}
                    />
                    <DataRow
                        label="Refunds"
                        value={reportData.refundTotal}
                        count={reportData.refundCount}
                    />
                    <DataRow
                        label="Discounts & Comps"
                        value={reportData.discountTotal}
                        count={reportData.discountCount}
                    />
                    <DataRow
                        label="Net Sales"
                        value={reportData.netSales}
                        bold={true}
                    />
                    <DataRow label="Total Tax" value={reportData.totalTax} />
                    <DataRow
                        label="Total Processing Fee"
                        value={reportData.totalProcessingFee}
                    />
                    <DataRow label="Total Tips" value={reportData.totalTips} />
                    <DataRow
                        label="Pickup Tips"
                        value={reportData.pickupTips}
                        indent={true}
                    />
                    <DataRow
                        label="Dine In Tips"
                        value={reportData.dineInTips}
                        indent={true}
                    />
                    <DataRow
                        label="Delivery Tips"
                        value={reportData.deliveryTips}
                        indent={true}
                    />
                    <DataRow
                        label="Total Sales"
                        value={reportData.totalSales}
                        bold={true}
                    />
                </TableBody>
            </Table>
        </ReportSection>
    );

    // Render voids and refunds section
    const renderVoidsAndRefundsSection = () => (
        <ReportSection title="Voids">
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHeadWithWidth
                            className="text-left font-bold"
                            width="w-1/2"
                        >
                            Type
                        </TableHeadWithWidth>
                        <TableHeadWithWidth
                            className="text-right font-bold"
                            width="w-1/4"
                        >
                            Amount
                        </TableHeadWithWidth>
                        <TableHeadWithWidth
                            className="text-right font-bold"
                            width="w-1/4"
                        >
                            Orders
                        </TableHeadWithWidth>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    <DataRow
                        label="Voided Orders"
                        value={reportData.totalVoidAmount}
                        count={reportData.voidCount}
                    />
                </TableBody>
            </Table>
        </ReportSection>
    );

    // Render cash management section
    const renderCashManagementSection = () => (
        <ReportSection title="Cash Management">
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHeadWithWidth
                            className="text-left font-bold"
                            width="w-1/2"
                        >
                            Category
                        </TableHeadWithWidth>
                        <TableHeadWithWidth
                            className="text-right font-bold"
                            width="w-1/4"
                        >
                            Amount
                        </TableHeadWithWidth>
                        <TableHeadWithWidth
                            className="text-right font-bold"
                            width="w-1/4"
                        >
                            Orders
                        </TableHeadWithWidth>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    <DataRow
                        label="Paid Out Total"
                        value={reportData.paidOutTotal}
                    />
                    <DataRow
                        label="Expected Cash in Drawers"
                        value={reportData.expectedCashInDrawers}
                        bold={true}
                    />
                </TableBody>
            </Table>
        </ReportSection>
    );

    // Render payment methods section
    const renderPaymentMethodsSection = () => (
        <ReportSection title="Payment Methods">
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHeadWithWidth
                            className="text-left font-bold"
                            width="w-1/2"
                        >
                            Payment Type
                        </TableHeadWithWidth>
                        <TableHeadWithWidth
                            className="text-right font-bold"
                            width="w-1/4"
                        >
                            Amount
                        </TableHeadWithWidth>
                        <TableHeadWithWidth
                            className="text-right font-bold"
                            width="w-1/4"
                        >
                            Orders
                        </TableHeadWithWidth>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    <DataRow
                        label="Cash"
                        value={reportData.paymentBreakdown.cashPayments.total}
                        count={reportData.paymentBreakdown.cashPayments.count}
                        bold={true}
                    />
                    <DataRow
                        label="Dine In Cash"
                        value={
                            reportData.paymentBreakdown.dineInCashPayments.total
                        }
                        count={
                            reportData.paymentBreakdown.dineInCashPayments.count
                        }
                        indent={true}
                    />
                    <DataRow
                        label="Delivery Cash"
                        value={
                            reportData.paymentBreakdown.deliveryCashPayments
                                .total
                        }
                        count={
                            reportData.paymentBreakdown.deliveryCashPayments
                                .count
                        }
                        indent={true}
                    />
                    <DataRow
                        label="Pickup Cash"
                        value={
                            reportData.paymentBreakdown.pickupCashPayments.total
                        }
                        count={
                            reportData.paymentBreakdown.pickupCashPayments.count
                        }
                        indent={true}
                    />
                    <DataRow
                        label="Card"
                        value={reportData.paymentBreakdown.cardPayments.total}
                        count={reportData.paymentBreakdown.cardPayments.count}
                        bold={true}
                    />
                    <DataRow
                        label="Dine In Card"
                        value={
                            reportData.paymentBreakdown.dineInCardPayments.total
                        }
                        count={
                            reportData.paymentBreakdown.dineInCardPayments.count
                        }
                        indent={true}
                    />
                    <DataRow
                        label="Delivery Card"
                        value={
                            reportData.paymentBreakdown.deliveryCardPayments
                                .total
                        }
                        count={
                            reportData.paymentBreakdown.deliveryCardPayments
                                .count
                        }
                        indent={true}
                    />
                    <DataRow
                        label="Pickup Card"
                        value={
                            reportData.paymentBreakdown.pickupCardPayments.total
                        }
                        count={
                            reportData.paymentBreakdown.pickupCardPayments.count
                        }
                        indent={true}
                    />
                </TableBody>
            </Table>
        </ReportSection>
    );

    // Render fulfillment section
    const renderFulfillmentSection = () => (
        <ReportSection title="Fulfillment">
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHeadWithWidth
                            className="text-left font-bold"
                            width="w-1/2"
                        >
                            Fulfillment Type
                        </TableHeadWithWidth>
                        <TableHeadWithWidth
                            className="text-right font-bold"
                            width="w-1/4"
                        >
                            Amount
                        </TableHeadWithWidth>
                        <TableHeadWithWidth
                            className="text-right font-bold"
                            width="w-1/4"
                        >
                            Orders
                        </TableHeadWithWidth>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    <DataRow
                        label="Dine In"
                        value={reportData.orderTypeBreakdown.dineIn.total}
                        count={reportData.orderTypeBreakdown.dineIn.count}
                    />
                    <DataRow
                        label="Delivery"
                        value={reportData.orderTypeBreakdown.delivery.total}
                        count={reportData.orderTypeBreakdown.delivery.count}
                    />
                    <DataRow
                        label="Pickup"
                        value={reportData.orderTypeBreakdown.pickup.total}
                        count={reportData.orderTypeBreakdown.pickup.count}
                    />
                </TableBody>
            </Table>
        </ReportSection>
    );

    // Render payment providers section
    const renderPaymentProvidersSection = () => (
        <ReportSection title="Providers">
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHeadWithWidth
                            className="text-left font-bold"
                            width="w-1/2"
                        >
                            Provider
                        </TableHeadWithWidth>
                        <TableHeadWithWidth
                            className="text-right font-bold"
                            width="w-1/4"
                        >
                            Amount
                        </TableHeadWithWidth>
                        <TableHeadWithWidth
                            className="text-right font-bold"
                            width="w-1/4"
                        >
                            Orders
                        </TableHeadWithWidth>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    <DataRow
                        label="All Providers"
                        value={
                            reportData.paymentBreakdown.thirdPartyPayments.total
                        }
                        count={
                            reportData.paymentBreakdown.thirdPartyPayments.count
                        }
                        bold={true}
                    />
                    {reportData.paymentBreakdown.providerBreakdown
                        ?.sort((a, b) => b.total - a.total)
                        .map((provider) => (
                            <TableRow key={provider.name}>
                                <TableCell className="pl-8">
                                    {provider.name}
                                </TableCell>
                                <TableCell className="text-right">
                                    {formatCurrency(provider.total)}
                                </TableCell>
                                <TableCell className="text-right">
                                    {provider.count}
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </ReportSection>
    );

    return (
        <div className="space-y-6">
            <div className="space-y-2">
                <h3 className="text-2xl font-semibold">End of Day Report</h3>
            </div>

            <div className="space-y-6 py-4">
                <div className="flex justify-between items-center">
                    <div>
                        <h3 className="text-lg font-semibold">
                            Date: {getDateString()}
                        </h3>
                        <p className="text-sm text-muted-foreground">
                            Generated: {getCurrentTimestamp()}
                        </p>
                    </div>
                    <Button
                        onClick={() => {
                            if (typeof window !== "undefined") {
                                window.print();
                            }
                        }}
                        variant="outline"
                        size="sm"
                    >
                        <Printer className="mr-2 h-4 w-4" />
                        Print Report
                    </Button>
                </div>

                {renderLoadingOrEmpty() || (
                    <>
                        {renderFinancialSummary()}
                        {renderVoidsAndRefundsSection()}
                        {renderCashManagementSection()}
                        {renderPaymentMethodsSection()}
                        {renderFulfillmentSection()}
                        {renderPaymentProvidersSection()}
                    </>
                )}
            </div>
        </div>
    );
};
