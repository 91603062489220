import { MessageCircle } from "lucide-react";

import { Button } from "src/@/components/ui/button";

/**
 * A floating button to open up the intercom chat.
 * We use this instead of the default launcher because of the greater level of responsive control, this button is hidden on mobile.
 */
export const IntercomButton = () => (
    <Button
        className="fixed bottom-6 right-6 hidden shadow-lg md:flex"
        size="lg"
        onClick={() => {
            if (window.Intercom) {
                window.Intercom("show");
            }
        }}
        icon={MessageCircle}
    >
        Support
    </Button>
);
