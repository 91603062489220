import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
    T extends { [key: string]: unknown },
    K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
    | T
    | {
          [P in keyof T]?: P extends " $fragmentName" | "__typename"
              ? T[P]
              : never;
      };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    /** A date and time, represented as an ISO-8601 string */
    Date: { input: string; output: string };
    DateTime: { input: any; output: any };
};

export type Addon = {
    __typename?: "Addon";
    _id: Scalars["ID"]["output"];
    hiddenForThirdParty?: Maybe<Scalars["Boolean"]["output"]>;
    integrationIds?: Maybe<IntegrationIds>;
    inventoryItems: Array<Maybe<ProductIngredientT>>;
    isArchived?: Maybe<Scalars["Date"]["output"]>;
    name: Scalars["String"]["output"];
    price: Scalars["Float"]["output"];
    soldOut: Scalars["Boolean"]["output"];
    soldOutDates?: Maybe<SoldOutRange>;
    soldOutToday: Scalars["Boolean"]["output"];
    thirdPartyPrice?: Maybe<Scalars["Float"]["output"]>;
};

export type AddonGroup = {
    __typename?: "AddonGroup";
    _id: Scalars["ID"]["output"];
    addons: Array<Maybe<Addon>>;
    hiddenForThirdParty?: Maybe<Scalars["Boolean"]["output"]>;
    integrationIds?: Maybe<IntegrationIds>;
    isArchived?: Maybe<Scalars["Date"]["output"]>;
    limit?: Maybe<Scalars["Int"]["output"]>;
    name: Scalars["String"]["output"];
    required: Scalars["Boolean"]["output"];
    supportsMultiple?: Maybe<Scalars["Boolean"]["output"]>;
};

export type AdjustmentScope =
    | CategoriesAdjustmentScope
    | MenuAdjustmentScope
    | ProductsAdjustmentScope;

export type AutomaticTrigger = {
    __typename?: "AutomaticTrigger";
    automatic: Scalars["Boolean"]["output"];
};

export type AverageSalesForHour = {
    __typename?: "AverageSalesForHour";
    averageTotalSales: Scalars["Float"]["output"];
    hour: Scalars["Int"]["output"];
};

export type BarrierProperties = Properties & {
    __typename?: "BarrierProperties";
    /** Discriminator for validation and serialization */
    type: Element;
};

export type BirthdayTrigger = {
    __typename?: "BirthdayTrigger";
    birthday: Scalars["Date"]["output"];
};

export type Break = {
    __typename?: "Break";
    actualDuration?: Maybe<Scalars["Float"]["output"]>;
    end?: Maybe<Scalars["Date"]["output"]>;
    expectedDuration: Scalars["Int"]["output"];
    id: Scalars["ID"]["output"];
    isDeleted: Scalars["Boolean"]["output"];
    start: Scalars["Date"]["output"];
    type: BreakType;
};

export type BreakInput = {
    end?: InputMaybe<Scalars["Date"]["input"]>;
    expectedDuration: Scalars["Int"]["input"];
    id: Scalars["ID"]["input"];
    start: Scalars["Date"]["input"];
    type: BreakType;
};

export enum BreakType {
    PaidBreak = "PAID_BREAK",
    UnpaidBreak = "UNPAID_BREAK",
}

export type Campaign = {
    __typename?: "Campaign";
    approvedBy?: Maybe<CampaignApprovedBy>;
    audienceType: CampaignAudienceType;
    createdAt: Scalars["Date"]["output"];
    deliveryMethod: DeliveryMethod;
    deliveryStatus?: Maybe<CampaignDeliveryStatus>;
    expirationDate?: Maybe<Scalars["Date"]["output"]>;
    history: Array<CampaignHistoryEvent>;
    id: Scalars["ID"]["output"];
    isNewCampaignStyle?: Maybe<Scalars["Boolean"]["output"]>;
    maxUses?: Maybe<Scalars["Int"]["output"]>;
    message: CampaignMessageData;
    metrics: CampaignMetrics;
    numberOfUsers: Scalars["Int"]["output"];
    openRate: Scalars["Float"]["output"];
    promotion?: Maybe<Scalars["ID"]["output"]>;
    reachableSizeAtCreation: Scalars["Int"]["output"];
    sections: Array<TemplateSection>;
    segmentSizeAtCreation: Scalars["Int"]["output"];
    status: CampaignStatus;
    store: Store;
    template: Template;
    type?: Maybe<CampaignType>;
    updatedAt: Scalars["Date"]["output"];
    userBreakdown: CampaignUserBreakdown;
    uses: Scalars["Int"]["output"];
};

export type CampaignApprovedBy = {
    __typename?: "CampaignApprovedBy";
    id: Scalars["String"]["output"];
    name?: Maybe<Scalars["String"]["output"]>;
};

export enum CampaignAudienceType {
    Followers = "FOLLOWERS",
    NeedsAttention = "NEEDS_ATTENTION",
    NewCustomers = "NEW_CUSTOMERS",
    RecentCustomers = "RECENT_CUSTOMERS",
    Test = "TEST",
    Vips = "VIPS",
}

export enum CampaignDeliveryStatus {
    Finished = "FINISHED",
    InProgress = "IN_PROGRESS",
}

export type CampaignEvent = {
    __typename?: "CampaignEvent";
    campaignId: Scalars["String"]["output"];
    createdAt: Scalars["Date"]["output"];
    eventType: CampaignEventType;
    platform?: Maybe<CampaignEventPlatform>;
    storeId: Scalars["String"]["output"];
    timeZone: Scalars["String"]["output"];
    user: CampaignEventUser;
};

export enum CampaignEventPlatform {
    Push = "push",
    Sms = "sms",
}

export enum CampaignEventType {
    Claimed = "claimed",
    Delivered = "delivered",
    Failed = "failed",
    Notified = "notified",
    Optout = "optout",
    Sent = "sent",
    Used = "used",
    Viewed = "viewed",
}

export type CampaignEventUser = {
    __typename?: "CampaignEventUser";
    id: Scalars["String"]["output"];
    name?: Maybe<Scalars["String"]["output"]>;
};

export type CampaignHistoryEvent = {
    __typename?: "CampaignHistoryEvent";
    createdAt?: Maybe<Scalars["Date"]["output"]>;
    type: CampaignHistoryEventType;
    updatedAt?: Maybe<Scalars["Date"]["output"]>;
    userId?: Maybe<Scalars["String"]["output"]>;
};

export enum CampaignHistoryEventType {
    Approved = "APPROVED",
    Completed = "COMPLETED",
    Created = "CREATED",
    Rejected = "REJECTED",
    Sent = "SENT",
}

export type CampaignMessage = {
    __typename?: "CampaignMessage";
    createdAt: Scalars["Date"]["output"];
    expiresAt?: Maybe<Scalars["Date"]["output"]>;
    id: Scalars["ID"]["output"];
    status?: Maybe<CampaignMessageStatus>;
    store?: Maybe<CampaignMessageStore>;
    template: Template;
    timeZone: Scalars["String"]["output"];
};

export type CampaignMessageData = {
    __typename?: "CampaignMessageData";
    body?: Maybe<Scalars["String"]["output"]>;
    title?: Maybe<Scalars["String"]["output"]>;
};

export enum CampaignMessageStatus {
    Claimed = "Claimed",
    Sent = "Sent",
    Used = "Used",
    Viewed = "Viewed",
}

export type CampaignMessageStore = {
    __typename?: "CampaignMessageStore";
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    thumbnailImageUrl?: Maybe<Scalars["String"]["output"]>;
};

export type CampaignMetrics = {
    __typename?: "CampaignMetrics";
    attributedOrders: Scalars["Int"]["output"];
    attributedSales: Scalars["Float"]["output"];
    claimCount: Scalars["Int"]["output"];
    numberOf: CampaignMetricsEventTypeCluster;
    numberOfUsers: Scalars["Int"]["output"];
    sendCount: Scalars["Int"]["output"];
    usedCount: Scalars["Int"]["output"];
    viewCount: Scalars["Int"]["output"];
};

export type CampaignMetricsChannelCluster = {
    __typename?: "CampaignMetricsChannelCluster";
    none: Scalars["Int"]["output"];
    push: Scalars["Int"]["output"];
    sms: Scalars["Int"]["output"];
};

export type CampaignMetricsEventTypeCluster = {
    __typename?: "CampaignMetricsEventTypeCluster";
    claimed: CampaignMetricsChannelCluster;
    delivered: CampaignMetricsChannelCluster;
    failed: CampaignMetricsChannelCluster;
    notified: CampaignMetricsChannelCluster;
    optout: CampaignMetricsChannelCluster;
    sent: CampaignMetricsChannelCluster;
    used: CampaignMetricsChannelCluster;
    viewed: CampaignMetricsChannelCluster;
};

export type CampaignRewardData = {
    __typename?: "CampaignRewardData";
    description: Scalars["String"]["output"];
    discount?: Maybe<Scalars["String"]["output"]>;
    expiresAt?: Maybe<Scalars["Date"]["output"]>;
    imageUrl?: Maybe<Scalars["String"]["output"]>;
    title: Scalars["String"]["output"];
};

export enum CampaignStatus {
    Approved = "APPROVED",
    Pending = "PENDING",
    Rejected = "REJECTED",
}

export enum CampaignType {
    Announcement = "ANNOUNCEMENT",
    Discount = "DISCOUNT",
    Product = "PRODUCT",
}

export type CampaignUserBreakdown = {
    __typename?: "CampaignUserBreakdown";
    users: Array<CampaignUserMetric>;
};

export type CampaignUserMetric = {
    __typename?: "CampaignUserMetric";
    events: Array<CampaignUserMetricEvent>;
    name?: Maybe<Scalars["String"]["output"]>;
};

export type CampaignUserMetricEvent = {
    __typename?: "CampaignUserMetricEvent";
    date: Scalars["Date"]["output"];
    errorMessage?: Maybe<Scalars["String"]["output"]>;
    type: CampaignEventType;
};

export type CampaignWithReward = {
    __typename?: "CampaignWithReward";
    campaign: Campaign;
    reward?: Maybe<CampaignRewardData>;
};

export type CampaignsFilters = {
    ids?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    newStyle?: InputMaybe<Scalars["Boolean"]["input"]>;
    storeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CartItemTrigger = {
    __typename?: "CartItemTrigger";
    productId: Scalars["String"]["output"];
};

export type CategoriesAdjustmentScope = {
    __typename?: "CategoriesAdjustmentScope";
    categories: Array<Scalars["String"]["output"]>;
};

export type Category = {
    __typename?: "Category";
    /** Creation timestamp of the category */
    createdAt: Scalars["DateTime"]["output"];
    /** Unique identifier for the category */
    id: Scalars["ID"]["output"];
    /** Ordered list of item IDs in the category */
    items: Array<Maybe<Scalars["String"]["output"]>>;
    /** Name of the category */
    name: Scalars["String"]["output"];
    /** ID of the store this category belongs to */
    store: Scalars["String"]["output"];
    /** Last updated timestamp of the category */
    updatedAt: Scalars["DateTime"]["output"];
};

export type CategoryCountCondition = PromotionCondition & {
    __typename?: "CategoryCountCondition";
    category: Scalars["String"]["output"];
    count: Scalars["Int"]["output"];
    scope: ConditionScope;
};

export type CategoryInput = {
    /** Ordered list of item IDs in the category */
    items?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    /** Name of the category */
    name?: InputMaybe<Scalars["String"]["input"]>;
    /** ID of the store this category belongs to */
    store?: InputMaybe<Scalars["String"]["input"]>;
};

export enum ChannelType {
    App = "app",
    Catering = "catering",
    Kiosk = "kiosk",
    Online = "online",
    Register = "register",
}

export type CircleGeometry = Geometry & {
    __typename?: "CircleGeometry";
    /**
     * Absolute radius of the circle.
     * Width and height will be equivalent to its diameter.
     */
    radius: Scalars["Float"]["output"];
    /** The type of geometry */
    type: Shape;
    /** Absolute x coordinates */
    x: Scalars["Float"]["output"];
    /** Absolute y coordinates */
    y: Scalars["Float"]["output"];
};

export type ClaimCampaignRewardResponse = {
    __typename?: "ClaimCampaignRewardResponse";
    rewardId: Scalars["String"]["output"];
};

export enum ConditionScope {
    Round = "ROUND",
    Session = "SESSION",
}

export type CreateAutomaticTrigger = {
    automatic: Scalars["Boolean"]["input"];
};

export type CreateBarrierElement = {
    /** Barriers can only be a rect shape */
    rectGeometry: CreateRectGeometry;
};

export type CreateBirthdayTrigger = {
    birthday: Scalars["DateTime"]["input"];
};

export type CreateCampaignInput = {
    audienceType: CampaignAudienceType;
    dealItems?: InputMaybe<Array<InputMaybe<DealItem>>>;
    deliveryMethod: DeliveryMethod;
    expirationDate?: InputMaybe<Scalars["Date"]["input"]>;
    isNewCampaignStyle?: InputMaybe<Scalars["Boolean"]["input"]>;
    maxUses?: InputMaybe<Scalars["Int"]["input"]>;
    numberOfUsers: Scalars["Int"]["input"];
    template: TemplateInput;
    title: Scalars["String"]["input"];
    type?: InputMaybe<CampaignType>;
};

export type CreateCartItemTrigger = {
    productId: Scalars["String"]["input"];
};

export type CreateCategoryCountCondition = {
    category: Scalars["String"]["input"];
    count: Scalars["Int"]["input"];
    scope: ConditionScope;
};

export type CreateCircleGeometry = {
    /**
     * Absolute radius of the circle.
     * Width and height is equivalent to its diameter.
     */
    radius: Scalars["Float"]["input"];
    /** Absolute x coordinates */
    x: Scalars["Float"]["input"];
    /** Absolute y coordinates */
    y: Scalars["Float"]["input"];
};

export type CreateDiamondGeometry = {
    /** Value to be used for equal width and height */
    size: Scalars["Float"]["input"];
    /** Absolute x coordinates */
    x: Scalars["Float"]["input"];
    /** Absolute y coordinates */
    y: Scalars["Float"]["input"];
};

export type CreateGiftCardInput = {
    cardLast4: Scalars["String"]["input"];
    cardTokenId: Scalars["String"]["input"];
    customerId?: InputMaybe<Scalars["String"]["input"]>;
    fromEmail: Scalars["String"]["input"];
    fromName: Scalars["String"]["input"];
    isManuallyEntered?: InputMaybe<Scalars["Boolean"]["input"]>;
    maxCreditCents: Scalars["Int"]["input"];
    note?: InputMaybe<Scalars["String"]["input"]>;
    storeId: Scalars["String"]["input"];
    toEmail?: InputMaybe<Scalars["String"]["input"]>;
    toName: Scalars["String"]["input"];
    toPhone: Scalars["String"]["input"];
    transactionSource?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateInvoiceInput = {
    amount: Scalars["Int"]["input"];
    customerEmail: Scalars["String"]["input"];
    customerName: Scalars["String"]["input"];
    description: Scalars["String"]["input"];
    salesTaxPercentage: Scalars["Float"]["input"];
};

export type CreateItemCountCondition = {
    count: Scalars["Int"]["input"];
    productId: Scalars["String"]["input"];
    scope: ConditionScope;
};

export type CreateLabelElement = {
    /** Display label for element */
    label: Scalars["String"]["input"];
    /** Labels can only be a rect shape */
    rectGeometry: CreateRectGeometry;
};

export type CreateMaxItemsCondition = {
    maxItems: Scalars["Int"]["input"];
    scope: ConditionScope;
};

export type CreatePromoCodeTrigger = {
    promoCode: Scalars["String"]["input"];
};

export type CreatePromotion = {
    adjustments: Array<CreatePromotionAdjustment>;
    channels: Array<ChannelType>;
    conditions: Array<CreatePromotionCondition>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    fulfillments: Array<FulfillmentType>;
    imageUrl?: InputMaybe<Scalars["String"]["input"]>;
    isRunning: Scalars["Boolean"]["input"];
    name: Scalars["String"]["input"];
    trigger: CreatePromotionTrigger;
};

export type CreatePromotionAdjustment = {
    adjustment: CreatePromotionPriceAdjustment;
    scope: CreatePromotionAdjustmentScope;
};

export type CreatePromotionAdjustmentScope = {
    categories?: InputMaybe<Array<Scalars["String"]["input"]>>;
    menu?: InputMaybe<Scalars["Boolean"]["input"]>;
    products?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type CreatePromotionCondition = {
    categoryCountCondition?: InputMaybe<CreateCategoryCountCondition>;
    itemCountCondition?: InputMaybe<CreateItemCountCondition>;
    maxItemsCondition?: InputMaybe<CreateMaxItemsCondition>;
};

export type CreatePromotionPriceAdjustment = {
    discountFlat?: InputMaybe<Scalars["Int"]["input"]>;
    discountPercent?: InputMaybe<Scalars["Int"]["input"]>;
    price?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CreatePromotionTrigger = {
    automaticTrigger?: InputMaybe<CreateAutomaticTrigger>;
    birthdayTrigger?: InputMaybe<CreateBirthdayTrigger>;
    cartItemTrigger?: InputMaybe<CreateCartItemTrigger>;
    promoCodeTrigger?: InputMaybe<CreatePromoCodeTrigger>;
};

export type CreateRectGeometry = {
    /** Determines whether the shape should have a fill */
    fill: Scalars["Boolean"]["input"];
    /** Absolute height of element */
    height: Scalars["Float"]["input"];
    /** Determines what stroke a rect shape should be rendered */
    stroke?: InputMaybe<ShapeStroke>;
    /** Absolute width of element */
    width: Scalars["Float"]["input"];
    /** Absolute x coordinates */
    x: Scalars["Float"]["input"];
    /** Absolute y coordinates */
    y: Scalars["Float"]["input"];
};

/** Create Service Area Definitions */
export type CreateServiceArea = {
    /** Every service area must have at least 1 element */
    layout: CreateServiceAreaLayout;
    /** The name of this service area */
    name: Scalars["String"]["input"];
};

export type CreateServiceAreaLayout = {
    /** Barriers found in this layout */
    barriers?: InputMaybe<Array<CreateBarrierElement>>;
    /** Labels found in this layout */
    labels?: InputMaybe<Array<CreateLabelElement>>;
    /** Tables found in this layout */
    tables?: InputMaybe<Array<CreateTableElement>>;
};

export type CreateTableElement = {
    /** Circle shape definition for table */
    circleGeometry?: InputMaybe<CreateCircleGeometry>;
    /** Diamond shape definition for table */
    diamondGeometry?: InputMaybe<CreateDiamondGeometry>;
    /** Number of seats this table can hold */
    numSeats: Scalars["Int"]["input"];
    /** Rect shape definition for table */
    rectGeometry?: InputMaybe<CreateRectGeometry>;
    /** Attached table id if exists */
    tableId?: InputMaybe<Scalars["ID"]["input"]>;
    /** Name assigned to table */
    tableName: Scalars["String"]["input"];
};

export type CreateTableInput = {
    name: Scalars["String"]["input"];
    numSeats: Scalars["Int"]["input"];
};

export type DateRange = {
    __typename?: "DateRange";
    end?: Maybe<Scalars["Date"]["output"]>;
    start?: Maybe<Scalars["Date"]["output"]>;
};

export type DateRangeInput = {
    end?: InputMaybe<Scalars["Date"]["input"]>;
    start?: InputMaybe<Scalars["Date"]["input"]>;
};

export type DealItem = {
    _id?: InputMaybe<Scalars["String"]["input"]>;
    categories?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    discount?: InputMaybe<Discount>;
    name: Scalars["String"]["input"];
    productIds: Array<Scalars["String"]["input"]>;
};

export enum DeliveryMethod {
    Legacy = "LEGACY",
    Push = "PUSH",
    Sms = "SMS",
}

export type DiamondGeometry = Geometry & {
    __typename?: "DiamondGeometry";
    /** Value to be used for equal width and height */
    size: Scalars["Float"]["output"];
    /** The type of geometry */
    type: Shape;
    /** Absolute x coordinates */
    x: Scalars["Float"]["output"];
    /** Absolute y coordinates */
    y: Scalars["Float"]["output"];
};

export type Discount = {
    dollarsOff?: InputMaybe<Scalars["Float"]["input"]>;
    isDoublePoints?: InputMaybe<Scalars["Boolean"]["input"]>;
    newPrice?: InputMaybe<Scalars["Float"]["input"]>;
    percentOff?: InputMaybe<Scalars["Float"]["input"]>;
};

export enum DiscountType {
    DollarsOff = "DOLLARS_OFF",
    NewPrice = "NEW_PRICE",
    Percent = "PERCENT",
}

export type DiscountedProductRewardData = {
    __typename?: "DiscountedProductRewardData";
    imageUrl?: Maybe<Scalars["String"]["output"]>;
    name: Scalars["String"]["output"];
    productId: Scalars["String"]["output"];
};

export enum Element {
    Barrier = "Barrier",
    Label = "Label",
    Table = "Table",
}

export type EmployeeShiftDetails = {
    __typename?: "EmployeeShiftDetails";
    blendedWage: Scalars["Float"]["output"];
    doubleOvertimeHours: Scalars["Float"]["output"];
    employee: PartialEmployee;
    estWages: Scalars["Float"]["output"];
    overtimeHours: Scalars["Float"]["output"];
    regularHours: Scalars["Float"]["output"];
    shifts: Array<ShiftSummary>;
    totalHours: Scalars["Float"]["output"];
    weeklyOvertimeHours?: Maybe<Scalars["Float"]["output"]>;
};

export type EmployeeShiftInput = {
    breakDuration?: InputMaybe<Scalars["Int"]["input"]>;
    employeeId: Scalars["ID"]["input"];
    eventType: ShiftEventType;
    payPeriodId: Scalars["ID"]["input"];
};

export type Feature = {
    __typename?: "Feature";
    displayName: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    imageUrl?: Maybe<Scalars["String"]["output"]>;
    insertionId?: Maybe<Scalars["String"]["output"]>;
    promotion?: Maybe<Promotion>;
    source: FeatureSource;
    store?: Maybe<Store>;
    style: FeatureStyle;
    type: FeatureType;
};

export enum FeatureSource {
    Manual = "MANUAL",
    Promotion = "PROMOTION",
}

export type FeatureStyle = {
    __typename?: "FeatureStyle";
    textColor: Scalars["String"]["output"];
};

export enum FeatureType {
    Promotion = "PROMOTION",
    Store = "STORE",
}

export type FeedCampaignMessageMetadata = {
    __typename?: "FeedCampaignMessageMetadata";
    campaignId: Scalars["String"]["output"];
    campaignMessageId: Scalars["String"]["output"];
    expiresAt?: Maybe<Scalars["Date"]["output"]>;
    products?: Maybe<Array<ProductMessageData>>;
    rewards?: Maybe<Array<RewardMessageData>>;
    status?: Maybe<CampaignMessageStatus>;
    template: Template;
    type: FeedMetadataType;
};

export type FeedElement = {
    __typename?: "FeedElement";
    createdAt: Scalars["Date"]["output"];
    creator: FeedElementCreator;
    description: Scalars["String"]["output"];
    imageUrl?: Maybe<Scalars["String"]["output"]>;
    insertionId?: Maybe<Scalars["String"]["output"]>;
    metadata?: Maybe<FeedElementMetadata>;
    title: Scalars["String"]["output"];
};

export type FeedElementCreator = {
    __typename?: "FeedElementCreator";
    emoji?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["String"]["output"];
    logoUrl?: Maybe<Scalars["String"]["output"]>;
    name: Scalars["String"]["output"];
    thumbnailImageUrl?: Maybe<Scalars["String"]["output"]>;
    type: FeedElementCreatorType;
};

export enum FeedElementCreatorType {
    Store = "Store",
}

export type FeedElementMetadata =
    | FeedCampaignMessageMetadata
    | FeedFeatureMetadata;

export type FeedFeatureMetadata = {
    __typename?: "FeedFeatureMetadata";
    feature: Feature;
    type: FeedMetadataType;
};

export enum FeedMetadataType {
    CampaignMessage = "CampaignMessage",
    Feature = "Feature",
}

export type FriendStats = {
    __typename?: "FriendStats";
    /** Number of chickens hatched with friend */
    chickens: Scalars["Int"]["output"];
    /** Number of gifts user has sent to this friend */
    giftsSentToFriend: Scalars["Int"]["output"];
    /** Number of gifts friend has sent to user */
    giftsSentToMe: Scalars["Int"]["output"];
};

export type FriendStatsChain = {
    __typename?: "FriendStatsChain";
    /** Number of chickens hatched with friend */
    chickens: Scalars["Int"]["output"];
    /** Number of gifts user has sent to this friend */
    giftsSentToFriend: Scalars["Int"]["output"];
    /** Number of gifts friend has sent to user */
    giftsSentToMe: Scalars["Int"]["output"];
};

export type FulfillmentMethods = {
    __typename?: "FulfillmentMethods";
    isDelivery: Scalars["Boolean"]["output"];
    isDineIn: Scalars["Boolean"]["output"];
    isPickup: Scalars["Boolean"]["output"];
};

export enum FulfillmentType {
    Delivery = "delivery",
    DineIn = "dine_in",
    Pickup = "pickup",
}

export type Geometry = {
    /** The type of geometry */
    type: Shape;
    /** Absolute x coordinates */
    x: Scalars["Float"]["output"];
    /** Absolute y coordinates */
    y: Scalars["Float"]["output"];
};

export type GiftCard = {
    __typename?: "GiftCard";
    activationDate?: Maybe<Scalars["DateTime"]["output"]>;
    chainId?: Maybe<Scalars["String"]["output"]>;
    code: Scalars["String"]["output"];
    createdAt: Scalars["DateTime"]["output"];
    designURL?: Maybe<Scalars["String"]["output"]>;
    encryptedPin?: Maybe<Scalars["String"]["output"]>;
    expirationDate?: Maybe<Scalars["DateTime"]["output"]>;
    firstUseDate?: Maybe<Scalars["DateTime"]["output"]>;
    fromEmail: Scalars["String"]["output"];
    fromName: Scalars["String"]["output"];
    fromUserId?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    lastUseDate?: Maybe<Scalars["DateTime"]["output"]>;
    maxCreditCents: Scalars["Int"]["output"];
    note?: Maybe<Scalars["String"]["output"]>;
    purchaseId?: Maybe<Scalars["String"]["output"]>;
    remainingCreditCents: Scalars["Int"]["output"];
    status: GiftCardStatus;
    storeId: Scalars["String"]["output"];
    stripeChargeId: Scalars["String"]["output"];
    toEmail?: Maybe<Scalars["String"]["output"]>;
    toName: Scalars["String"]["output"];
    toPhone: Scalars["String"]["output"];
    toUserId?: Maybe<Scalars["String"]["output"]>;
    type?: Maybe<GiftCardDiscriminatorGraph>;
    updatedAt: Scalars["DateTime"]["output"];
};

export enum GiftCardDiscriminatorGraph {
    DigitalGiftCard = "DIGITAL_GIFT_CARD",
    PhysicalGiftCard = "PHYSICAL_GIFT_CARD",
}

export enum GiftCardStatus {
    Active = "ACTIVE",
    Inactive = "INACTIVE",
    OutOfCredit = "OUT_OF_CREDIT",
    Redeemed = "REDEEMED",
    Refunded = "REFUNDED",
    Sent = "SENT",
}

export type HourMinute = {
    __typename?: "HourMinute";
    hour: Scalars["Int"]["output"];
    minute: Scalars["Int"]["output"];
};

export type HourMinuteInput = {
    hour: Scalars["Int"]["input"];
    minute: Scalars["Int"]["input"];
};

export type IndividualMarketingConsent = {
    __typename?: "IndividualMarketingConsent";
    /** mongo id */
    id: Scalars["ID"]["output"];
    /**
     * whether a user consented to receiving SMS messages
     * from the store
     */
    marketingConsent: Scalars["Boolean"]["output"];
    name?: Maybe<Scalars["String"]["output"]>;
    referredFromId?: Maybe<Scalars["String"]["output"]>;
    referredFromType?: Maybe<Scalars["String"]["output"]>;
};

export type IntegrationIds = {
    __typename?: "IntegrationIds";
    chowly?: Maybe<Scalars["String"]["output"]>;
};

export type InventoryQuantityObject = {
    __typename?: "InventoryQuantityObject";
    amount: Scalars["Int"]["output"];
    precision: Scalars["Int"]["output"];
    unit: Scalars["String"]["output"];
};

export type Invoice = {
    __typename?: "Invoice";
    /** Amount in cents entered by restaurant partner. Customer's invoice will have this and sales tax line item. */
    amount: Scalars["Int"]["output"];
    /** Can be used to determine when Invoice was sent since creation and email sending happens simultaneously. */
    createdAt: Scalars["DateTime"]["output"];
    /** Email specified at invoice creation which is used to create Stripe Customer. Invoice sends to this email. */
    customerEmail: Scalars["String"]["output"];
    /** Name specified at invoice creation which is used to create Stripe Customer */
    customerName: Scalars["String"]["output"];
    /** Timestamp when invoice.paid webhook is received. */
    customerPaidAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** Used to set description of Stripe Invoice Line Item that shows up on invoice */
    description: Scalars["String"]["output"];
    /** ID of invoice document in database */
    id: Scalars["ID"]["output"];
    /** Timestamp when Snackpass pays out the restaurant partner. Payout is amount minus agreed upon value. */
    payoutProcessedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** Sales tax percentage (i.e. 7.25) provided by the restaurant partner at time of invoice creation */
    salesTaxPercentage: Scalars["Float"]["output"];
    /** ID of the corresponding invoice in Stripe Invoice API */
    stripeInvoiceId: Scalars["String"]["output"];
    /** Invoice number generated by Stripe that's present in PDF, email and payment page (i.e. EE744C66-0001) */
    stripeInvoiceNumber: Scalars["String"]["output"];
    /** Link to the PDF of the invoice */
    stripeInvoicePdf?: Maybe<Scalars["String"]["output"]>;
    /** URL of the stripe invoice */
    stripeInvoiceUrl?: Maybe<Scalars["String"]["output"]>;
    /** Total amount in cents used to create Stripe Invoice. Total amount customer sees on invoice. */
    totalAmount: Scalars["Int"]["output"];
};

export type Item = {
    __typename?: "Item";
    /**
     * An item's product can be null since products are hard-deleted.
     * When they are changed to be archived, and the products are
     * backfilled, this can be changed to a required field.
     */
    product?: Maybe<Product>;
};

export type ItemCountCondition = PromotionCondition & {
    __typename?: "ItemCountCondition";
    count: Scalars["Int"]["output"];
    productId: Scalars["String"]["output"];
    scope: ConditionScope;
};

export type LabelProperties = Properties & {
    __typename?: "LabelProperties";
    /** Display label for element */
    label: Scalars["String"]["output"];
    /** Discriminator for validation and serialization */
    type: Element;
};

export type LayoutElement = {
    __typename?: "LayoutElement";
    /** Graphical definition on how this element should be rendered */
    geometry: Geometry;
    id: Scalars["ID"]["output"];
    /**
     * Properties assigned to this element define its purpose and includes
     * any associated data to show within clients
     */
    properties: Properties;
};

export type Machine = {
    __typename?: "Machine";
    allowProductList?: Maybe<Array<Scalars["String"]["output"]>>;
    id: Scalars["ID"]["output"];
    modifierGroupOrdering?: Maybe<Array<Scalars["String"]["output"]>>;
    name: Scalars["String"]["output"];
    store: Scalars["String"]["output"];
};

export type MaxItemsCondition = PromotionCondition & {
    __typename?: "MaxItemsCondition";
    maxItems: Scalars["Int"]["output"];
    scope: ConditionScope;
};

export type MenuAdjustmentScope = {
    __typename?: "MenuAdjustmentScope";
    menu: Scalars["Boolean"]["output"];
};

export type MenuContext = {
    __typename?: "MenuContext";
    channel?: Maybe<Array<Maybe<ChannelType>>>;
    dateRange?: Maybe<DateRange>;
    fulfillment?: Maybe<Array<Maybe<FulfillmentType>>>;
    schedule?: Maybe<Schedule>;
};

export type MenuContextInput = {
    channel?: InputMaybe<Array<InputMaybe<ChannelType>>>;
    dateRange?: InputMaybe<DateRangeInput>;
    fulfillment?: InputMaybe<Array<InputMaybe<FulfillmentType>>>;
    schedule?: InputMaybe<ScheduleInput>;
};

export type MenuItem = {
    __typename?: "MenuItem";
    /** Information pertaining to the availability of the menu item, and any overrides */
    availabilityInfo: MenuItemAvailabilityInfo;
    /** Creation timestamp of the menu item */
    createdAt: Scalars["DateTime"]["output"];
    /** Unique identifier for the menu item */
    id: Scalars["ID"]["output"];
    /** Indicates if the menu item is a modifier */
    isModifier: Scalars["Boolean"]["output"];
    /** Kitchen facing name of the menu item */
    kitchenName?: Maybe<Scalars["String"]["output"]>;
    /** Modifier groups that are descendant to this item */
    modifierGroups: Array<Maybe<Scalars["String"]["output"]>>;
    /** Name of the menu item */
    name: Scalars["String"]["output"];
    /** Information pertaining to the price of the menu item, and any overrides */
    priceInfo: MenuItemPriceInfo;
    /** ID of the store this menu item belongs to */
    store: Scalars["String"]["output"];
    /** Last updated timestamp of the menu item */
    updatedAt: Scalars["DateTime"]["output"];
};

export type MenuItemAvailabilityInfo = {
    __typename?: "MenuItemAvailabilityInfo";
    /** Contextual availability overrides */
    overrides: Array<Maybe<MenuItemAvailabilityOverride>>;
    /** Original sold out status */
    soldOut: Scalars["Boolean"]["output"];
    /** Original sold out dates */
    soldOutDates?: Maybe<SoldOutDates>;
};

export type MenuItemAvailabilityInfoInput = {
    /** Contextual availability overrides */
    overrides?: InputMaybe<
        Array<InputMaybe<MenuItemAvailabilityOverrideInput>>
    >;
    /** Original sold out status */
    soldOut?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Original sold out dates */
    soldOutDates?: InputMaybe<SoldOutDatesInput>;
};

export type MenuItemAvailabilityOverride = {
    __typename?: "MenuItemAvailabilityOverride";
    /** What type of context the override applies to */
    contextType: OverrideContext;
    /** Identifier of specific context where override should be applied */
    contextValue: Scalars["String"]["output"];
    /** Overridden sold out status */
    soldOut: Scalars["Boolean"]["output"];
    /** Overridden sold out dates */
    soldOutDates?: Maybe<SoldOutDates>;
};

export type MenuItemAvailabilityOverrideInput = {
    /** What type of context the override applies to */
    contextType: OverrideContext;
    /** Identifier of specific context where override should be applied */
    contextValue: Scalars["String"]["input"];
    /** Overridden sold out status */
    soldOut: Scalars["Boolean"]["input"];
    /** Overridden sold out dates */
    soldOutDates: SoldOutDatesInput;
};

export type MenuItemInput = {
    /** Information pertaining to the availability of the menu item, and any overrides */
    availabilityInfo?: InputMaybe<MenuItemAvailabilityInfoInput>;
    /** Indicates if the menu item is a modifier */
    isModifier?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Kitchen facing name of the menu item */
    kitchenName?: InputMaybe<Scalars["String"]["input"]>;
    /** Modifier groups that are descendant to this item */
    modifierGroups?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    /** Name of the menu item */
    name?: InputMaybe<Scalars["String"]["input"]>;
    /** Information pertaining to the price of the menu item, and any overrides */
    priceInfo?: InputMaybe<MenuItemPriceInfoInput>;
    /** ID of the store this menu item belongs to */
    store?: InputMaybe<Scalars["String"]["input"]>;
};

export type MenuItemPriceInfo = {
    __typename?: "MenuItemPriceInfo";
    /** Contextual price overrides */
    overrides: Array<Maybe<MenuItemPriceOverride>>;
    /** Original price of the mnenu item */
    price: Scalars["Float"]["output"];
};

export type MenuItemPriceInfoInput = {
    /** Contextual price overrides */
    overrides?: InputMaybe<Array<InputMaybe<MenuItemPriceOverrideInput>>>;
    /** Original price of the mnenu item */
    price?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MenuItemPriceOverride = {
    __typename?: "MenuItemPriceOverride";
    /** What type of context the override applies to */
    contextType: OverrideContext;
    /** Identifier of specific context where override should be applied */
    contextValue: Scalars["String"]["output"];
    /** Price override */
    price: Scalars["Float"]["output"];
};

export type MenuItemPriceOverrideInput = {
    /** What type of context the override applies to */
    contextType: OverrideContext;
    /** Identifier of specific context where override should be applied */
    contextValue: Scalars["String"]["input"];
    /** Price override */
    price: Scalars["Float"]["input"];
};

export type MessageData =
    | ProductMessageData
    | RewardMessageData
    | TextMessageData;

export type MessageVariable = {
    __typename?: "MessageVariable";
    placeholder: Scalars["String"]["output"];
    type: VariableType;
    variableData: Variable;
    variableId: Scalars["String"]["output"];
};

export type MessageVariableInput = {
    placeholder: Scalars["String"]["input"];
    type: VariableType;
    variable: VariableInput;
    variableId: Scalars["String"]["input"];
};

export type ModifierGroup = {
    __typename?: "ModifierGroup";
    /** Creation timestamp of the modifier group */
    createdAt: Scalars["DateTime"]["output"];
    /** When true, each item may only be selected once; When false, multiples of the same item may be selected */
    distinct: Scalars["Boolean"]["output"];
    /** Unique identifier for the modifier group */
    id: Scalars["ID"]["output"];
    /** Ordered list of item IDs in the group */
    items: Array<Maybe<Scalars["String"]["output"]>>;
    /** Kitchen facing name of the modifier group */
    kitchenName?: Maybe<Scalars["String"]["output"]>;
    /** Maximum number of selections allowed, set to 1 for single select behavior, set to null if no maximum is enforced */
    maximum?: Maybe<Scalars["Int"]["output"]>;
    /** Minimum number of selections required, set to 0 if selection is not required */
    minimum?: Maybe<Scalars["Int"]["output"]>;
    /** Name of the modifier group */
    name: Scalars["String"]["output"];
    /** ID of the store this modifier group belongs to */
    store: Scalars["String"]["output"];
    /** Last updated timestamp of the modifier group */
    updatedAt: Scalars["DateTime"]["output"];
};

export type ModifierGroupInput = {
    /** When true, each item may only be selected once; When false, multiples of the same item may be selected */
    distinct?: InputMaybe<Scalars["Boolean"]["input"]>;
    /** Ordered list of item IDs in the group */
    items?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    /** Kitchen facing name of the modifier group */
    kitchenName?: InputMaybe<Scalars["String"]["input"]>;
    /** Maximum number of selections allowed, set to 1 for single select behavior, set to null if no maximum is enforced */
    maximum?: InputMaybe<Scalars["Int"]["input"]>;
    /** Minimum number of selections required, set to 0 if selection is not required */
    minimum?: InputMaybe<Scalars["Int"]["input"]>;
    /** Name of the modifier group */
    name?: InputMaybe<Scalars["String"]["input"]>;
    /** ID of the store this modifier group belongs to */
    store?: InputMaybe<Scalars["String"]["input"]>;
};

export type MultiplePayPeriodSummaries = {
    __typename?: "MultiplePayPeriodSummaries";
    summaries: Array<PayPeriodSummary>;
};

export type Mutation = {
    __typename?: "Mutation";
    _empty?: Maybe<Scalars["String"]["output"]>;
    claimReward: ClaimCampaignRewardResponse;
    createCampaign: Campaign;
    /** Create a new category */
    createCategory?: Maybe<Category>;
    createGiftCard: GiftCard;
    createInvoice: Invoice;
    createMachine?: Maybe<Machine>;
    /** Create a new menu item */
    createMenuItem?: Maybe<MenuItem>;
    /** Create a new modifier group */
    createModifierGroup?: Maybe<ModifierGroup>;
    /** Create a new promotion */
    createPromotion: SpecialPromotion;
    /**
     * Create service areas along with any tables attached to it.
     * Resolves missing tables and removes unused tables.
     */
    createServiceArea: StoreServiceArea;
    createShiftByAdmin: Shift;
    createStoreMenu: StoreMenu;
    createStoreTable: Table;
    /** Delete a category */
    deleteCategory?: Maybe<Scalars["ID"]["output"]>;
    deleteMachine?: Maybe<Machine>;
    /** Delete a menu item */
    deleteMenuItem?: Maybe<Scalars["ID"]["output"]>;
    /** Delete a modifier group */
    deleteModifierGroup?: Maybe<Scalars["ID"]["output"]>;
    /** Delete a promotion */
    deletePromotion: Scalars["ID"]["output"];
    /** Deletes a service area and its associated tables. */
    deleteServiceArea: Scalars["ID"]["output"];
    deleteShiftByAdmin?: Maybe<Shift>;
    deleteStoreMenu?: Maybe<Scalars["ID"]["output"]>;
    deleteStoreTable: Scalars["ID"]["output"];
    employeeShift: Shift;
    /**
     * Set user's consent status to IN. PurchaseId and auth token provide an
     * alternative login mechanism for users on smart receipt
     */
    optInToMarketing: Scalars["Boolean"]["output"];
    /**
     * Set user's consent status to OUT. PurchaseId and auth token provide an
     * alternative login mechanism for users on smart receipt
     */
    optOutOfMarketing: Scalars["Boolean"]["output"];
    redeemGiftCard: GiftCard;
    refundGiftCard: GiftCard;
    /** Update an existing category */
    updateCategory?: Maybe<Category>;
    updateMachine?: Maybe<Machine>;
    /** Update an existing menu item */
    updateMenuItem?: Maybe<MenuItem>;
    /** Update an existing modifier group */
    updateModifierGroup?: Maybe<ModifierGroup>;
    /** Update a promotion */
    updatePromotion: SpecialPromotion;
    /**
     * Updates service areas along with tables attached to it.
     * Resolves missing tables and removes unused tables.
     */
    updateServiceArea: StoreServiceArea;
    updateShiftByAdmin?: Maybe<Shift>;
    updateStoreMenu: StoreMenu;
    updateStoreTable: Table;
    verifyGiftCard: GiftCard;
    viewCampaignMessages: Array<CampaignEvent>;
};

export type MutationClaimRewardArgs = {
    campaignId: Scalars["ID"]["input"];
    campaignMessageId: Scalars["ID"]["input"];
};

export type MutationCreateCampaignArgs = {
    data: CreateCampaignInput;
    storeId: Scalars["ID"]["input"];
};

export type MutationCreateCategoryArgs = {
    input: CategoryInput;
};

export type MutationCreateGiftCardArgs = {
    data: CreateGiftCardInput;
};

export type MutationCreateInvoiceArgs = {
    input: CreateInvoiceInput;
    storeId: Scalars["ID"]["input"];
};

export type MutationCreateMachineArgs = {
    allowProductList?: InputMaybe<Array<Scalars["String"]["input"]>>;
    modifierGroupOrdering?: InputMaybe<Array<Scalars["String"]["input"]>>;
    name: Scalars["String"]["input"];
    store: Scalars["String"]["input"];
};

export type MutationCreateMenuItemArgs = {
    input: MenuItemInput;
};

export type MutationCreateModifierGroupArgs = {
    input: ModifierGroupInput;
};

export type MutationCreatePromotionArgs = {
    input: CreatePromotion;
    storeId: Scalars["ID"]["input"];
};

export type MutationCreateServiceAreaArgs = {
    input: CreateServiceArea;
    storeId: Scalars["ID"]["input"];
};

export type MutationCreateShiftByAdminArgs = {
    breaks?: InputMaybe<Array<BreakInput>>;
    cashTips?: InputMaybe<Scalars["Float"]["input"]>;
    clockedInAt: Scalars["Date"]["input"];
    clockedOutAt?: InputMaybe<Scalars["Date"]["input"]>;
    employeeId: Scalars["ID"]["input"];
    note?: InputMaybe<Scalars["String"]["input"]>;
    payPeriodId: Scalars["ID"]["input"];
    storeId: Scalars["ID"]["input"];
};

export type MutationCreateStoreMenuArgs = {
    input: StoreMenuInput;
    storeId: Scalars["ID"]["input"];
};

export type MutationCreateStoreTableArgs = {
    input: CreateTableInput;
    storeId: Scalars["ID"]["input"];
};

export type MutationDeleteCategoryArgs = {
    id: Scalars["ID"]["input"];
};

export type MutationDeleteMachineArgs = {
    id: Scalars["ID"]["input"];
};

export type MutationDeleteMenuItemArgs = {
    id: Scalars["ID"]["input"];
};

export type MutationDeleteModifierGroupArgs = {
    id: Scalars["ID"]["input"];
};

export type MutationDeletePromotionArgs = {
    id: Scalars["ID"]["input"];
};

export type MutationDeleteServiceAreaArgs = {
    serviceAreaId: Scalars["ID"]["input"];
    storeId: Scalars["ID"]["input"];
};

export type MutationDeleteShiftByAdminArgs = {
    shiftId: Scalars["ID"]["input"];
    storeId: Scalars["ID"]["input"];
};

export type MutationDeleteStoreMenuArgs = {
    id: Scalars["ID"]["input"];
};

export type MutationDeleteStoreTableArgs = {
    storeId: Scalars["ID"]["input"];
    tableId: Scalars["ID"]["input"];
};

export type MutationEmployeeShiftArgs = {
    data: EmployeeShiftInput;
    shiftId: Scalars["ID"]["input"];
    storeId: Scalars["ID"]["input"];
};

export type MutationOptInToMarketingArgs = {
    authToken?: InputMaybe<Scalars["String"]["input"]>;
    purchaseId?: InputMaybe<Scalars["String"]["input"]>;
    storeId: Scalars["ID"]["input"];
};

export type MutationOptOutOfMarketingArgs = {
    authToken?: InputMaybe<Scalars["String"]["input"]>;
    purchaseId?: InputMaybe<Scalars["String"]["input"]>;
    storeId: Scalars["ID"]["input"];
};

export type MutationRedeemGiftCardArgs = {
    code: Scalars["String"]["input"];
};

export type MutationRefundGiftCardArgs = {
    id: Scalars["ID"]["input"];
};

export type MutationUpdateCategoryArgs = {
    id: Scalars["ID"]["input"];
    input: CategoryInput;
};

export type MutationUpdateMachineArgs = {
    allowProductList?: InputMaybe<Array<Scalars["String"]["input"]>>;
    id: Scalars["ID"]["input"];
    modifierGroupOrdering?: InputMaybe<Array<Scalars["String"]["input"]>>;
    name: Scalars["String"]["input"];
    store: Scalars["String"]["input"];
};

export type MutationUpdateMenuItemArgs = {
    id: Scalars["ID"]["input"];
    input: MenuItemInput;
};

export type MutationUpdateModifierGroupArgs = {
    id: Scalars["ID"]["input"];
    input: ModifierGroupInput;
};

export type MutationUpdatePromotionArgs = {
    id: Scalars["ID"]["input"];
    input: UpdatePromotion;
};

export type MutationUpdateServiceAreaArgs = {
    input: UpdateServiceArea;
    storeId: Scalars["ID"]["input"];
};

export type MutationUpdateShiftByAdminArgs = {
    breaks?: InputMaybe<Array<BreakInput>>;
    cashTips?: InputMaybe<Scalars["Float"]["input"]>;
    clockedInAt?: InputMaybe<Scalars["Date"]["input"]>;
    clockedOutAt?: InputMaybe<Scalars["Date"]["input"]>;
    note?: InputMaybe<Scalars["String"]["input"]>;
    shiftId: Scalars["ID"]["input"];
    storeId: Scalars["ID"]["input"];
};

export type MutationUpdateStoreMenuArgs = {
    id: Scalars["ID"]["input"];
    input: StoreMenuInput;
};

export type MutationUpdateStoreTableArgs = {
    input: UpdateTableInput;
    tableId: Scalars["ID"]["input"];
};

export type MutationVerifyGiftCardArgs = {
    code: Scalars["String"]["input"];
    pin: Scalars["String"]["input"];
    storeId: Scalars["ID"]["input"];
};

export type MutationViewCampaignMessagesArgs = {
    campaignMessageIds: Array<Scalars["ID"]["input"]>;
};

export enum OrderReportTransactionSource {
    App = "app",
    Kiosk = "kiosk",
    Online = "online",
    ThirdParty = "thirdParty",
}

export enum OverrideContext {
    Item = "ITEM",
    Menu = "MENU",
    ModifierGroup = "MODIFIER_GROUP",
    Store = "STORE",
}

export type PartialEmployee = {
    __typename?: "PartialEmployee";
    firstName?: Maybe<Scalars["String"]["output"]>;
    id?: Maybe<Scalars["ID"]["output"]>;
    identifier?: Maybe<Scalars["String"]["output"]>;
    lastName?: Maybe<Scalars["String"]["output"]>;
    name?: Maybe<Scalars["String"]["output"]>;
    role?: Maybe<Scalars["String"]["output"]>;
    wage?: Maybe<Scalars["Float"]["output"]>;
};

export type PayPeriod = {
    __typename?: "PayPeriod";
    dailyDoubleOvertimeMultiplier?: Maybe<Scalars["Float"]["output"]>;
    dailyDoubleOvertimeThreshold?: Maybe<Scalars["Float"]["output"]>;
    dailyOvertimeMultiplier?: Maybe<Scalars["Float"]["output"]>;
    dailyOvertimeThreshold?: Maybe<Scalars["Float"]["output"]>;
    endDate: Scalars["Date"]["output"];
    id: Scalars["ID"]["output"];
    startDate: Scalars["Date"]["output"];
    storeId: Scalars["String"]["output"];
    weeklyOvertimeMultiplier?: Maybe<Scalars["Float"]["output"]>;
    weeklyOvertimeThreshold?: Maybe<Scalars["Float"]["output"]>;
};

export type PayPeriodSummary = {
    __typename?: "PayPeriodSummary";
    doubleOvertimeHours: Scalars["Float"]["output"];
    employeeShiftDetails: Array<EmployeeShiftDetails>;
    employeesWithOngoingShifts: Array<PartialEmployee>;
    endDate: Scalars["Date"]["output"];
    estWages: Scalars["Float"]["output"];
    netSales: Scalars["Float"]["output"];
    overtimeHours: Scalars["Float"]["output"];
    payPeriodId: Scalars["ID"]["output"];
    regularHours: Scalars["Float"]["output"];
    startDate: Scalars["Date"]["output"];
    timeCards: Scalars["Float"]["output"];
    totalHours: Scalars["Float"]["output"];
    weeklyOvertimeHours?: Maybe<Scalars["Float"]["output"]>;
};

export type PaySummaryByDateRange = {
    __typename?: "PaySummaryByDateRange";
    doubleOvertimeHours: Scalars["Float"]["output"];
    employeeShiftDetails: Array<EmployeeShiftDetails>;
    employeesWithOngoingShifts: Array<PartialEmployee>;
    endDate: Scalars["Date"]["output"];
    estWages: Scalars["Float"]["output"];
    netSales: Scalars["Float"]["output"];
    overtimeHours: Scalars["Float"]["output"];
    payPeriodId: Scalars["ID"]["output"];
    regularHours: Scalars["Float"]["output"];
    startDate: Scalars["Date"]["output"];
    timeCards: Scalars["Float"]["output"];
    totalHours: Scalars["Float"]["output"];
    weeklyOvertimeHours?: Maybe<Scalars["Float"]["output"]>;
};

export type PriceAdjustment = {
    __typename?: "PriceAdjustment";
    /** Whether the price adjustment should apply to modifiers */
    adjustModifiers: Scalars["Boolean"]["output"];
    /** Whether the value should be interpreted as a percent or flat cents */
    adjustmentType: PriceAdjustmentType;
    /** Nearest cents amount to round to, e.g. roundTo=50, round to nearest 50 cents */
    roundTo?: Maybe<Scalars["Int"]["output"]>;
    /** Signed integer representing cents or percent value of the current price adjustment */
    value: Scalars["Int"]["output"];
};

export type PriceAdjustmentInput = {
    /** Whether the price adjustment should apply to modifiers */
    adjustModifiers: Scalars["Boolean"]["input"];
    /** Whether the value should be interpreted as a percent or flat cents */
    adjustmentType: PriceAdjustmentType;
    /** Nearest cents amount to round to, e.g. roundTo=50, round to nearest 50 cents */
    roundTo?: InputMaybe<Scalars["Int"]["input"]>;
    /** Signed integer representing cents or percent value of the current price adjustment */
    value: Scalars["Int"]["input"];
};

export enum PriceAdjustmentType {
    Flat = "flat",
    Percentage = "percentage",
}

export type PriceByWeight = {
    __typename?: "PriceByWeight";
    perUnit: Scalars["Float"]["output"];
    unit: Scalars["String"]["output"];
};

export type PriceOverride = {
    __typename?: "PriceOverride";
    id: Scalars["String"]["output"];
    value: Scalars["Int"]["output"];
};

export type PriceOverrideInput = {
    id: Scalars["String"]["input"];
    value: Scalars["Int"]["input"];
};

export type Product = {
    __typename?: "Product";
    addonGroups: Array<Maybe<AddonGroup>>;
    category: Scalars["String"]["output"];
    description?: Maybe<Scalars["String"]["output"]>;
    fulfillmentMethods?: Maybe<FulfillmentMethods>;
    hiddenForThirdParty?: Maybe<Scalars["Boolean"]["output"]>;
    hours?: Maybe<SnackpassHours>;
    id: Scalars["ID"]["output"];
    image?: Maybe<Scalars["String"]["output"]>;
    integrationIds?: Maybe<IntegrationIds>;
    inventoryItems?: Maybe<Array<Maybe<ProductIngredientT>>>;
    isArchived?: Maybe<Scalars["Date"]["output"]>;
    isCatering: Scalars["Boolean"]["output"];
    isTemplate: Scalars["Boolean"]["output"];
    minimumQuantity: Scalars["Int"]["output"];
    name: Scalars["String"]["output"];
    numberFeeds: Scalars["Int"]["output"];
    points?: Maybe<Scalars["Int"]["output"]>;
    pointsAfterPromotion?: Maybe<Scalars["Int"]["output"]>;
    price?: Maybe<Scalars["Float"]["output"]>;
    priceByWeight?: Maybe<PriceByWeight>;
    pun?: Maybe<Scalars["String"]["output"]>;
    purchaseCount: Scalars["Float"]["output"];
    soldOut: Scalars["Boolean"]["output"];
    soldOutDates: SoldOutRange;
    soldOutToday: Scalars["Boolean"]["output"];
    store: StoreEmbedded;
    tags: Array<Maybe<Tag>>;
    taxInfo?: Maybe<SalesTax>;
    thirdPartyPrice?: Maybe<Scalars["Float"]["output"]>;
    upsell: Scalars["Boolean"]["output"];
    viewCount: Scalars["Int"]["output"];
};

export type ProductIngredientT = {
    __typename?: "ProductIngredientT";
    inventoryItemId: Scalars["String"]["output"];
    unitsConsumed?: Maybe<InventoryQuantityObject>;
};

export type ProductMessageData = {
    __typename?: "ProductMessageData";
    imageUrl?: Maybe<Scalars["String"]["output"]>;
    name: Scalars["String"]["output"];
    productId: Scalars["String"]["output"];
};

export type ProductTemplateInput = {
    imageUrl?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    productId: Scalars["String"]["input"];
};

export type ProductsAdjustmentScope = {
    __typename?: "ProductsAdjustmentScope";
    products: Array<Scalars["String"]["output"]>;
};

export type PromoCodeTrigger = {
    __typename?: "PromoCodeTrigger";
    promoCode: Scalars["String"]["output"];
};

export type Promotion = {
    __typename?: "Promotion";
    activeTimePeriod?: Maybe<PromotionActiveTimePeriod>;
    clientShowsRemainingUses: Scalars["Boolean"]["output"];
    createdAt: Scalars["Date"]["output"];
    hours?: Maybe<PromotionHours>;
    id: Scalars["ID"]["output"];
    imageUrl?: Maybe<Scalars["String"]["output"]>;
    inWaitlistMode: Scalars["Boolean"]["output"];
    lastUsedAt?: Maybe<Scalars["Date"]["output"]>;
    maximumUses?: Maybe<Scalars["Int"]["output"]>;
    targets: Array<Maybe<PromotionTarget>>;
    totalPurchases: Scalars["Int"]["output"];
    totalUses: Scalars["Int"]["output"];
    type: PromotionType;
};

export type PromotionActiveTimePeriod = {
    __typename?: "PromotionActiveTimePeriod";
    endTime?: Maybe<Scalars["Date"]["output"]>;
    startTime?: Maybe<Scalars["Date"]["output"]>;
};

export type PromotionAdjustment = {
    __typename?: "PromotionAdjustment";
    adjustment: PromotionPriceAdjustment;
    scope: AdjustmentScope;
};

export type PromotionCondition = {
    scope: ConditionScope;
};

export type PromotionDiscountFlat = {
    __typename?: "PromotionDiscountFlat";
    discountFlat: Scalars["Int"]["output"];
};

export type PromotionDiscountPercent = {
    __typename?: "PromotionDiscountPercent";
    discountPercent: Scalars["Int"]["output"];
};

export type PromotionHours = {
    __typename?: "PromotionHours";
    eastern?: Maybe<Array<Maybe<PromotionTimeRange>>>;
    local: Array<Maybe<PromotionTimeRange>>;
    offset?: Maybe<Scalars["Int"]["output"]>;
    zone: Scalars["String"]["output"];
};

export type PromotionPrice = {
    __typename?: "PromotionPrice";
    price: Scalars["Int"]["output"];
};

export type PromotionPriceAdjustment =
    | PromotionDiscountFlat
    | PromotionDiscountPercent
    | PromotionPrice;

export enum PromotionTarget {
    All = "ALL",
    Campaign = "CAMPAIGN",
    FirstTime = "FIRST_TIME",
    SecondTime = "SECOND_TIME",
    Students = "STUDENTS",
    Subscribers = "SUBSCRIBERS",
}

export type PromotionTimeRange = {
    __typename?: "PromotionTimeRange";
    end?: Maybe<Scalars["Int"]["output"]>;
    start?: Maybe<Scalars["Int"]["output"]>;
};

export type PromotionTrigger =
    | AutomaticTrigger
    | BirthdayTrigger
    | CartItemTrigger
    | PromoCodeTrigger;

export enum PromotionType {
    Birthday = "BIRTHDAY",
    Deal = "DEAL",
    Discount = "DISCOUNT",
    GiftCard = "GIFT_CARD",
    Party = "PARTY",
    PromoCode = "PROMO_CODE",
    Referral = "REFERRAL",
    Retargeting = "RETARGETING",
    Reward = "REWARD",
    SpecialPromotion = "SpecialPromotion",
    TimeBomb = "TIME_BOMB",
}

export type Properties = {
    /** Discriminator for validation and serialization */
    type: Element;
};

export type Punchcard = {
    __typename?: "Punchcard";
    id: Scalars["ID"]["output"];
    pointsTotal: Scalars["Int"]["output"];
    store: Store;
};

export type Purchase = {
    __typename?: "Purchase";
    createdAt: Scalars["Date"]["output"];
    id: Scalars["ID"]["output"];
    items: Array<Maybe<Item>>;
    user: User;
};

export type Query = {
    __typename?: "Query";
    _empty?: Maybe<Scalars["String"]["output"]>;
    campaignById: CampaignWithReward;
    campaignMetrics: CampaignMetrics;
    campaignUserBreakdown: CampaignUserBreakdown;
    campaigns: Array<Campaign>;
    /** Get all categories for a store */
    categories?: Maybe<Array<Maybe<Category>>>;
    /** Get a category by ID */
    category?: Maybe<Category>;
    featureById?: Maybe<Feature>;
    featureFeed: Array<Maybe<Feature>>;
    featureFeedOLD: Array<Maybe<Feature>>;
    getMyFeed: Array<FeedElement>;
    giftCardById: GiftCard;
    giftCardByToken: GiftCard;
    giftCardsActiveByPhoneAndStore: Array<GiftCard>;
    giftCardsActiveByUser: Array<GiftCard>;
    giftCardsActiveByUserAndStore: Array<GiftCard>;
    giftCardsByStore: Array<GiftCard>;
    giftCardsByUser: Array<GiftCard>;
    giftCardsByUserAndStore: Array<GiftCard>;
    individualMarketingConsentInfo?: Maybe<IndividualMarketingConsent>;
    invoices: Array<Maybe<Invoice>>;
    machinesByStoreId?: Maybe<Array<Maybe<Machine>>>;
    me?: Maybe<User>;
    /** Get a menu item by ID */
    menuItem?: Maybe<MenuItem>;
    /** Get all menu items for a store */
    menuItems?: Maybe<Array<Maybe<MenuItem>>>;
    /** Get a modifier group by ID */
    modifierGroup?: Maybe<ModifierGroup>;
    /** Get all modifier groups for a store */
    modifierGroups?: Maybe<Array<Maybe<ModifierGroup>>>;
    multiplePayPeriodSummaries: MultiplePayPeriodSummaries;
    payPeriodSummary: PayPeriodSummary;
    payPeriods: Array<PayPeriod>;
    paySummaryByDateRange: PaySummaryByDateRange;
    promotionById?: Maybe<Promotion>;
    /** Fetch all specials for the store */
    promotions: Array<SpecialPromotion>;
    /** Fetch a service area for the store */
    serviceArea: StoreServiceArea;
    /** Fetch all service areas for the store */
    serviceAreas: Array<StoreServiceArea>;
    storeAudience: StoreAudience;
    storeById?: Maybe<Store>;
    storeCampaigns: Array<Campaign>;
    storeMenus: Array<Maybe<StoreMenu>>;
    storeOrderReports: Array<Maybe<StoreOrderReport>>;
    storeOrderReportsDashboard: StoreOrderReportDashboard;
    storeOrderReportsExport: StoreOrderReportExport;
    storeTable?: Maybe<Table>;
    storeTables?: Maybe<Array<Table>>;
    topFriendsAtChain?: Maybe<Array<Maybe<User>>>;
    topFriendsAtStore?: Maybe<Array<Maybe<User>>>;
    userById?: Maybe<User>;
    warmFeatureFeedCache?: Maybe<Scalars["String"]["output"]>;
};

export type QueryCampaignByIdArgs = {
    campaignId: Scalars["ID"]["input"];
};

export type QueryCampaignMetricsArgs = {
    campaignId: Scalars["ID"]["input"];
};

export type QueryCampaignUserBreakdownArgs = {
    campaignId: Scalars["ID"]["input"];
};

export type QueryCampaignsArgs = {
    filters: CampaignsFilters;
};

export type QueryCategoriesArgs = {
    store: Scalars["String"]["input"];
};

export type QueryCategoryArgs = {
    id: Scalars["ID"]["input"];
};

export type QueryFeatureByIdArgs = {
    id: Scalars["ID"]["input"];
};

export type QueryFeatureFeedArgs = {
    lat?: InputMaybe<Scalars["Float"]["input"]>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    lng?: InputMaybe<Scalars["Float"]["input"]>;
    logUserId?: InputMaybe<Scalars["String"]["input"]>;
    sessionId?: InputMaybe<Scalars["String"]["input"]>;
    viewId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryFeatureFeedOldArgs = {
    lat?: InputMaybe<Scalars["Float"]["input"]>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    lng?: InputMaybe<Scalars["Float"]["input"]>;
    logUserId?: InputMaybe<Scalars["String"]["input"]>;
    sessionId?: InputMaybe<Scalars["String"]["input"]>;
    viewId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetMyFeedArgs = {
    before?: InputMaybe<Scalars["Date"]["input"]>;
    lat?: InputMaybe<Scalars["Float"]["input"]>;
    limit: Scalars["Int"]["input"];
    lng?: InputMaybe<Scalars["Float"]["input"]>;
    logUserId?: InputMaybe<Scalars["String"]["input"]>;
    sessionId?: InputMaybe<Scalars["String"]["input"]>;
    since?: InputMaybe<Scalars["Date"]["input"]>;
    viewId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGiftCardByIdArgs = {
    id: Scalars["ID"]["input"];
};

export type QueryGiftCardByTokenArgs = {
    id: Scalars["ID"]["input"];
    token: Scalars["String"]["input"];
};

export type QueryGiftCardsActiveByPhoneAndStoreArgs = {
    phone: Scalars["String"]["input"];
    storeId: Scalars["ID"]["input"];
};

export type QueryGiftCardsActiveByUserAndStoreArgs = {
    storeId: Scalars["ID"]["input"];
};

export type QueryGiftCardsByStoreArgs = {
    storeId: Scalars["ID"]["input"];
};

export type QueryGiftCardsByUserAndStoreArgs = {
    storeId: Scalars["ID"]["input"];
};

export type QueryIndividualMarketingConsentInfoArgs = {
    authToken?: InputMaybe<Scalars["String"]["input"]>;
    purchaseId?: InputMaybe<Scalars["String"]["input"]>;
    storeId: Scalars["ID"]["input"];
};

export type QueryInvoicesArgs = {
    storeId: Scalars["ID"]["input"];
};

export type QueryMachinesByStoreIdArgs = {
    store: Scalars["ID"]["input"];
};

export type QueryMenuItemArgs = {
    id: Scalars["ID"]["input"];
};

export type QueryMenuItemsArgs = {
    store: Scalars["String"]["input"];
};

export type QueryModifierGroupArgs = {
    id: Scalars["ID"]["input"];
};

export type QueryModifierGroupsArgs = {
    store: Scalars["String"]["input"];
};

export type QueryMultiplePayPeriodSummariesArgs = {
    endDate: Scalars["Date"]["input"];
    startDate: Scalars["Date"]["input"];
    storeId: Scalars["ID"]["input"];
};

export type QueryPayPeriodSummaryArgs = {
    payPeriodId: Scalars["ID"]["input"];
    storeId: Scalars["ID"]["input"];
};

export type QueryPayPeriodsArgs = {
    storeId: Scalars["ID"]["input"];
};

export type QueryPaySummaryByDateRangeArgs = {
    endDate: Scalars["Date"]["input"];
    startDate: Scalars["Date"]["input"];
    storeId: Scalars["ID"]["input"];
};

export type QueryPromotionByIdArgs = {
    id: Scalars["ID"]["input"];
};

export type QueryPromotionsArgs = {
    storeId: Scalars["ID"]["input"];
};

export type QueryServiceAreaArgs = {
    id: Scalars["ID"]["input"];
};

export type QueryServiceAreasArgs = {
    storeId: Scalars["ID"]["input"];
};

export type QueryStoreAudienceArgs = {
    storeId: Scalars["ID"]["input"];
};

export type QueryStoreByIdArgs = {
    id: Scalars["ID"]["input"];
};

export type QueryStoreCampaignsArgs = {
    storeId: Scalars["ID"]["input"];
};

export type QueryStoreMenusArgs = {
    storeId: Scalars["ID"]["input"];
};

export type QueryStoreOrderReportsArgs = {
    endDate: Scalars["Date"]["input"];
    granularity: ReportGranularity;
    startDate: Scalars["Date"]["input"];
    storeId: Scalars["ID"]["input"];
    transactionSources?: InputMaybe<Array<OrderReportTransactionSource>>;
};

export type QueryStoreOrderReportsDashboardArgs = {
    endDate: Scalars["Date"]["input"];
    granularity: ReportGranularity;
    prevEndDate: Scalars["Date"]["input"];
    prevStartDate: Scalars["Date"]["input"];
    startDate: Scalars["Date"]["input"];
    storeId: Scalars["ID"]["input"];
    transactionSources?: InputMaybe<Array<OrderReportTransactionSource>>;
};

export type QueryStoreOrderReportsExportArgs = {
    endDate: Scalars["Date"]["input"];
    exportType: ReportExportType;
    startDate: Scalars["Date"]["input"];
    storeId: Scalars["ID"]["input"];
};

export type QueryStoreTableArgs = {
    id: Scalars["ID"]["input"];
};

export type QueryStoreTablesArgs = {
    storeId: Scalars["ID"]["input"];
};

export type QueryTopFriendsAtChainArgs = {
    id: Scalars["ID"]["input"];
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    storeIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type QueryTopFriendsAtStoreArgs = {
    id: Scalars["ID"]["input"];
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    storeId: Scalars["ID"]["input"];
};

export type QueryUserByIdArgs = {
    id: Scalars["ID"]["input"];
};

export type RectGeometry = Geometry & {
    __typename?: "RectGeometry";
    /** Determines the fill a rect shape should be rendered */
    fill: Scalars["Boolean"]["output"];
    /** Absolute height of element */
    height: Scalars["Float"]["output"];
    /** Determines what stroke a rect shape should be rendered */
    stroke?: Maybe<ShapeStroke>;
    /** The type of geometry */
    type: Shape;
    /** Absolute width of element */
    width: Scalars["Float"]["output"];
    /** Absolute x coordinates */
    x: Scalars["Float"]["output"];
    /** Absolute y coordinates */
    y: Scalars["Float"]["output"];
};

export enum ReportExportType {
    Csv = "csv",
    Xlsx = "xlsx",
}

export enum ReportGranularity {
    Daily = "daily",
    Hourly = "hourly",
    Monthly = "monthly",
    Weekly = "weekly",
    Yearly = "yearly",
}

export type RewardDiscountInput = {
    dollarsOff?: InputMaybe<Scalars["Float"]["input"]>;
    newPrice?: InputMaybe<Scalars["Float"]["input"]>;
    percentOff?: InputMaybe<Scalars["Float"]["input"]>;
    type: DiscountType;
};

export type RewardMessageData = {
    __typename?: "RewardMessageData";
    discount: RewardMessageDiscountData;
    products: Array<DiscountedProductRewardData>;
    storewide: Scalars["Boolean"]["output"];
};

export type RewardMessageDiscountData = {
    __typename?: "RewardMessageDiscountData";
    dollarsOff?: Maybe<Scalars["Float"]["output"]>;
    newPrice?: Maybe<Scalars["Float"]["output"]>;
    percentOff?: Maybe<Scalars["Float"]["output"]>;
    type: DiscountType;
};

export type RewardTemplateDiscountedProductInput = {
    imageUrl?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    productId: Scalars["String"]["input"];
};

export type RewardTemplateInput = {
    discount: RewardDiscountInput;
    products: Array<RewardTemplateDiscountedProductInput>;
    storewide: Scalars["Boolean"]["input"];
};

export type SalesData = {
    __typename?: "SalesData";
    interval: Scalars["String"]["output"];
    ordersCount: Scalars["Int"]["output"];
    totalSales: Scalars["Float"]["output"];
};

export type SalesTax = {
    __typename?: "SalesTax";
    rate: Scalars["Float"]["output"];
};

export type Schedule = {
    __typename?: "Schedule";
    friday?: Maybe<Array<Maybe<ScheduleBlock>>>;
    monday?: Maybe<Array<Maybe<ScheduleBlock>>>;
    saturday?: Maybe<Array<Maybe<ScheduleBlock>>>;
    sunday?: Maybe<Array<Maybe<ScheduleBlock>>>;
    thursday?: Maybe<Array<Maybe<ScheduleBlock>>>;
    tuesday?: Maybe<Array<Maybe<ScheduleBlock>>>;
    wednesday?: Maybe<Array<Maybe<ScheduleBlock>>>;
};

export type ScheduleBlock = {
    __typename?: "ScheduleBlock";
    end?: Maybe<HourMinute>;
    start?: Maybe<HourMinute>;
};

export type ScheduleBlockInput = {
    end?: InputMaybe<HourMinuteInput>;
    start?: InputMaybe<HourMinuteInput>;
};

export type ScheduleInput = {
    friday?: InputMaybe<Array<InputMaybe<ScheduleBlockInput>>>;
    monday?: InputMaybe<Array<InputMaybe<ScheduleBlockInput>>>;
    saturday?: InputMaybe<Array<InputMaybe<ScheduleBlockInput>>>;
    sunday?: InputMaybe<Array<InputMaybe<ScheduleBlockInput>>>;
    thursday?: InputMaybe<Array<InputMaybe<ScheduleBlockInput>>>;
    tuesday?: InputMaybe<Array<InputMaybe<ScheduleBlockInput>>>;
    wednesday?: InputMaybe<Array<InputMaybe<ScheduleBlockInput>>>;
};

export enum Shape {
    Circle = "Circle",
    Diamond = "Diamond",
    Rect = "Rect",
}

export enum ShapeStroke {
    Dash = "Dash",
    Solid = "Solid",
}

export type Shift = {
    __typename?: "Shift";
    breaks: Array<Break>;
    cashTips?: Maybe<Scalars["Float"]["output"]>;
    clockedInAt: Scalars["Date"]["output"];
    clockedOutAt?: Maybe<Scalars["Date"]["output"]>;
    employeeId: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    isDeleted: Scalars["Boolean"]["output"];
    note?: Maybe<Scalars["String"]["output"]>;
    payPeriodId: Scalars["String"]["output"];
    wage: Scalars["Float"]["output"];
};

export enum ShiftEventType {
    ClockIn = "CLOCK_IN",
    ClockOut = "CLOCK_OUT",
    PaidBreak = "PAID_BREAK",
    UnpaidBreak = "UNPAID_BREAK",
}

export type ShiftSummary = {
    __typename?: "ShiftSummary";
    breaks: Array<Break>;
    cashTips?: Maybe<Scalars["Float"]["output"]>;
    clockedInAt: Scalars["Date"]["output"];
    clockedOutAt?: Maybe<Scalars["Date"]["output"]>;
    doubleOvertimeHours: Scalars["Float"]["output"];
    employeeId: Scalars["String"]["output"];
    estWages: Scalars["Float"]["output"];
    id: Scalars["ID"]["output"];
    isDeleted: Scalars["Boolean"]["output"];
    note?: Maybe<Scalars["String"]["output"]>;
    overtimeHours: Scalars["Float"]["output"];
    payPeriodId: Scalars["String"]["output"];
    regularHours: Scalars["Float"]["output"];
    totalHours: Scalars["Float"]["output"];
    wage: Scalars["Float"]["output"];
    weeklyOvertimeHours?: Maybe<Scalars["Float"]["output"]>;
};

export type SnackpassHours = {
    __typename?: "SnackpassHours";
    eastern?: Maybe<Array<SnackpassTimeRange>>;
    local: Array<SnackpassTimeRange>;
    offset?: Maybe<Scalars["Int"]["output"]>;
    zone: Scalars["String"]["output"];
};

export type SnackpassTimeRange = {
    __typename?: "SnackpassTimeRange";
    end: Scalars["Int"]["output"];
    start: Scalars["Int"]["output"];
};

export type SoldOutDates = {
    __typename?: "SoldOutDates";
    /** When to cease availability */
    from?: Maybe<Scalars["DateTime"]["output"]>;
    /** When to resume availability */
    until?: Maybe<Scalars["DateTime"]["output"]>;
};

export type SoldOutDatesInput = {
    /** When to cease availability */
    from?: InputMaybe<Scalars["DateTime"]["input"]>;
    /** When to resume availability */
    until?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type SoldOutRange = {
    __typename?: "SoldOutRange";
    from?: Maybe<Scalars["Date"]["output"]>;
    until?: Maybe<Scalars["Date"]["output"]>;
};

export type SpecialPromotion = {
    __typename?: "SpecialPromotion";
    adjustments: Array<PromotionAdjustment>;
    channels: Array<ChannelType>;
    conditions: Array<PromotionCondition>;
    createdAt: Scalars["DateTime"]["output"];
    description?: Maybe<Scalars["String"]["output"]>;
    endDate?: Maybe<Scalars["DateTime"]["output"]>;
    fulfillments: Array<FulfillmentType>;
    id: Scalars["ID"]["output"];
    imageUrl?: Maybe<Scalars["String"]["output"]>;
    isRunning: Scalars["Boolean"]["output"];
    name: Scalars["String"]["output"];
    startDate?: Maybe<Scalars["DateTime"]["output"]>;
    trigger: PromotionTrigger;
    updatedAt: Scalars["DateTime"]["output"];
};

export type Store = {
    __typename?: "Store";
    address: Scalars["String"]["output"];
    delivery: Scalars["Boolean"]["output"];
    id: Scalars["ID"]["output"];
    isLive: Scalars["Boolean"]["output"];
    name: Scalars["String"]["output"];
    regionSlug: Scalars["String"]["output"];
    stats?: Maybe<StoreStats>;
    timeZone?: Maybe<Scalars["String"]["output"]>;
};

export type StoreAudience = {
    __typename?: "StoreAudience";
    followers: Scalars["Int"]["output"];
    followersWithIndividualConsent: Scalars["Int"]["output"];
    needAttentionCustomers: Scalars["Int"]["output"];
    needAttentionCustomersWithIndividualConsent: Scalars["Int"]["output"];
    newCustomers: Scalars["Int"]["output"];
    newCustomersWithIndividualConsent: Scalars["Int"]["output"];
    recentCustomers: Scalars["Int"]["output"];
    recentCustomersWithIndividualConsent: Scalars["Int"]["output"];
    testCustomers?: Maybe<Scalars["Int"]["output"]>;
    testCustomersWithIndividualConsent?: Maybe<Scalars["Int"]["output"]>;
    vipCustomers: Scalars["Int"]["output"];
};

export type StoreEmbedded = {
    __typename?: "StoreEmbedded";
    _id: Scalars["ID"]["output"];
    color: Scalars["String"]["output"];
    emoji: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    pod?: Maybe<Scalars["String"]["output"]>;
    region?: Maybe<Scalars["String"]["output"]>;
};

export type StoreMenu = {
    __typename?: "StoreMenu";
    /** Ordered list of category IDs that will appear under this menu */
    categories: Array<Maybe<Scalars["String"]["output"]>>;
    /** Contexts which this menu will apply under, e.g. only show on register */
    context?: Maybe<MenuContext>;
    /** Creation timestamp of store menu */
    createdAt: Scalars["DateTime"]["output"];
    /** ID of store menu in database */
    id: Scalars["ID"]["output"];
    /** Name of store menu */
    name: Scalars["String"]["output"];
    /** Price adjustment configuration */
    priceAdjustment?: Maybe<PriceAdjustment>;
    /** List of product IDs that will be excluded from this menu */
    productExclusions: Array<Maybe<Scalars["String"]["output"]>>;
    /** List of overrides that will apply to this menu, e.g. override price of specific item */
    productPriceOverrides?: Maybe<Array<Maybe<PriceOverride>>>;
    /** ID of corresponding store */
    store: Scalars["String"]["output"];
    /** Last updated timestamp of store menu */
    updatedAt: Scalars["DateTime"]["output"];
};

export type StoreMenuInput = {
    /** Ordered list of category IDs that will appear under this menu */
    categories: Array<Scalars["String"]["input"]>;
    /** Contexts which this menu will apply under, e.g. only show on register */
    context?: InputMaybe<MenuContextInput>;
    /** Name of store menu */
    name: Scalars["String"]["input"];
    /** Price adjustment configuration */
    priceAdjustment?: InputMaybe<PriceAdjustmentInput>;
    /** List of product IDs that will be excluded from this menu */
    productExclusions: Array<Scalars["String"]["input"]>;
    /** List of product price overrides that will apply to this menu */
    productPriceOverrides?: InputMaybe<Array<InputMaybe<PriceOverrideInput>>>;
};

export type StoreOrderReport = {
    __typename?: "StoreOrderReport";
    endDate: Scalars["Date"]["output"];
    numberOfOrders: Scalars["Int"]["output"];
    snackpassCommission: Scalars["Int"]["output"];
    snackpassCreditUsed: Scalars["Int"]["output"];
    snackpassDeliveryCommission: Scalars["Int"]["output"];
    snackpassFaxFee: Scalars["Int"]["output"];
    snackpassFeesBreakdown: Array<Maybe<StoreOrderReportFee>>;
    snackpassOtherFees: Scalars["Int"]["output"];
    snackpassProcessingFee: Scalars["Int"]["output"];
    startDate: Scalars["Date"]["output"];
    storeConvenienceFee: Scalars["Int"]["output"];
    storeCreditUsed: Scalars["Int"]["output"];
    storeDeliveryFee: Scalars["Int"]["output"];
    storeDiscount: Scalars["Int"]["output"];
    storeEarnings: Scalars["Int"]["output"];
    storeFeesBreakdown: Array<Maybe<StoreOrderReportFee>>;
    storeOtherFees: Scalars["Int"]["output"];
    storePayout: Scalars["Int"]["output"];
    storeRefundAmount: Scalars["Int"]["output"];
    storeSubtotal: Scalars["Int"]["output"];
    storeTaxCollected: Scalars["Int"]["output"];
    storeTaxRemitted: Scalars["Int"]["output"];
    storeTaxWithheld: Scalars["Int"]["output"];
    storeTip: Scalars["Int"]["output"];
    storeTotal: Scalars["Int"]["output"];
    storeUpchargeAmount: Scalars["Int"]["output"];
};

export type StoreOrderReportDashboard = {
    __typename?: "StoreOrderReportDashboard";
    averageTicketPrice: Scalars["Float"]["output"];
    currSales: Scalars["Float"]["output"];
    granularData: Array<Maybe<SalesData>>;
    orders: Scalars["Int"]["output"];
    peakSalesHour: Scalars["Int"]["output"];
    percentDiffPeakSales?: Maybe<Scalars["Int"]["output"]>;
    percentDiffSales?: Maybe<Scalars["Int"]["output"]>;
    prevPeakSalesHour: Scalars["Int"]["output"];
    prevSales: Scalars["Float"]["output"];
    prevSalesDataByHour: Array<Maybe<AverageSalesForHour>>;
    salesDataByHour: Array<Maybe<AverageSalesForHour>>;
};

export type StoreOrderReportExport = {
    __typename?: "StoreOrderReportExport";
    url: Scalars["String"]["output"];
};

export type StoreOrderReportFee = {
    __typename?: "StoreOrderReportFee";
    amount: Scalars["Int"]["output"];
    name: Scalars["String"]["output"];
    quantity: Scalars["Int"]["output"];
    type: Scalars["String"]["output"];
};

export type StoreServiceArea = {
    __typename?: "StoreServiceArea";
    id: Scalars["ID"]["output"];
    /** The layout of this service area. A layout is made up of elements. */
    layout: Array<LayoutElement>;
    /** Name of this service area */
    name: Scalars["String"]["output"];
    /** Tables found in this service area */
    tables: Array<Table>;
};

export type StoreStats = {
    __typename?: "StoreStats";
    totalFollowers: Scalars["Int"]["output"];
    totalOrders: Scalars["Int"]["output"];
    totalReachableSMSCustomers: Scalars["Int"]["output"];
    totalVIPs: Scalars["Int"]["output"];
};

export type Table = {
    __typename?: "Table";
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    numSeats: Scalars["Int"]["output"];
    storeId: Scalars["ID"]["output"];
};

export type TableProperties = Properties & {
    __typename?: "TableProperties";
    /** Attached tableId for lookup */
    tableId: Scalars["ID"]["output"];
    /** Discriminator for validation and serialization */
    type: Element;
};

export type Tag = {
    __typename?: "Tag";
    _id: Scalars["ID"]["output"];
    description?: Maybe<Scalars["String"]["output"]>;
    emoji: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    scopes?: Maybe<Array<Maybe<TagScope>>>;
};

export enum TagScope {
    Products = "products",
    Stores = "stores",
}

export type Template = {
    __typename?: "Template";
    sections: Array<TemplateSection>;
    variables: Array<MessageVariable>;
};

export type TemplateDataInput = {
    body?: InputMaybe<Scalars["String"]["input"]>;
    title?: InputMaybe<Scalars["String"]["input"]>;
};

export type TemplateInput = {
    sections: Array<TemplateSectionInput>;
    variables: Array<MessageVariableInput>;
};

export type TemplateSection = {
    __typename?: "TemplateSection";
    text: Scalars["String"]["output"];
    type: TemplateSectionType;
};

export type TemplateSectionInput = {
    text: Scalars["String"]["input"];
    type: TemplateSectionType;
};

export enum TemplateSectionType {
    Body = "BODY",
    Title = "TITLE",
}

export type TextMessageData = {
    __typename?: "TextMessageData";
    message: Scalars["String"]["output"];
};

export type TextMessageInput = {
    message: Scalars["String"]["input"];
};

export type TopProduct = {
    __typename?: "TopProduct";
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
};

export type UpdatePromotion = {
    adjustments?: InputMaybe<Array<CreatePromotionAdjustment>>;
    channels?: InputMaybe<Array<ChannelType>>;
    conditions?: InputMaybe<Array<CreatePromotionCondition>>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    fulfillments?: InputMaybe<Array<FulfillmentType>>;
    imageUrl?: InputMaybe<Scalars["String"]["input"]>;
    isRunning?: InputMaybe<Scalars["Boolean"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    trigger?: InputMaybe<CreatePromotionTrigger>;
};

/** Update Service Area Definitions */
export type UpdateServiceArea = {
    /** ID of service area */
    id: Scalars["ID"]["input"];
    /** Layout to be shown with this service area */
    layout?: InputMaybe<UpdateServiceAreaLayout>;
    /** The name of this service area */
    name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateServiceAreaLayout = {
    /** All barriers must be included otherwise they will be removed */
    barriers?: InputMaybe<Array<CreateBarrierElement>>;
    /** All labels must be included otherwise they will be removed */
    labels?: InputMaybe<Array<CreateLabelElement>>;
    /** All tables must be included otherwise they will be removed */
    tables?: InputMaybe<Array<CreateTableElement>>;
};

export type UpdateTableInput = {
    name?: InputMaybe<Scalars["String"]["input"]>;
    numSeats?: InputMaybe<Scalars["Int"]["input"]>;
};

export type User = {
    __typename?: "User";
    /** stores where this user is an admin */
    adminStores?: Maybe<Array<Maybe<Store>>>;
    email?: Maybe<Scalars["String"]["output"]>;
    firstName?: Maybe<Scalars["String"]["output"]>;
    /** Data for a friend */
    friendStats?: Maybe<FriendStats>;
    /** Data for a friend at chain */
    friendStatsChain?: Maybe<FriendStatsChain>;
    /** mongo id */
    id?: Maybe<Scalars["ID"]["output"]>;
    lastName?: Maybe<Scalars["String"]["output"]>;
    /** user's last purchase made at a store */
    lastPurchase?: Maybe<Purchase>;
    /** user's last purchase made at a chain */
    lastPurchaseChain?: Maybe<Purchase>;
    name?: Maybe<Scalars["String"]["output"]>;
    number?: Maybe<Scalars["String"]["output"]>;
    profilePicUrl?: Maybe<Scalars["String"]["output"]>;
    /** user's punchcard for a store */
    punchcard?: Maybe<Punchcard>;
    /** user's top products at a given store */
    topProducts?: Maybe<Array<Maybe<TopProduct>>>;
    /** user's top products at a given chain */
    topProductsChain?: Maybe<Array<Maybe<TopProduct>>>;
    /** firebase id */
    uid?: Maybe<Scalars["ID"]["output"]>;
};

export type UserFriendStatsArgs = {
    friendId: Scalars["ID"]["input"];
    storeId: Scalars["ID"]["input"];
};

export type UserFriendStatsChainArgs = {
    friendId: Scalars["ID"]["input"];
    storeIds: Array<Scalars["ID"]["input"]>;
};

export type UserLastPurchaseArgs = {
    storeId: Scalars["ID"]["input"];
};

export type UserLastPurchaseChainArgs = {
    storeIds: Array<Scalars["ID"]["input"]>;
};

export type UserPunchcardArgs = {
    storeId: Scalars["ID"]["input"];
};

export type UserTopProductsArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    storeId: Scalars["ID"]["input"];
};

export type UserTopProductsChainArgs = {
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    storeIds: Array<Scalars["ID"]["input"]>;
};

export type Variable = {
    __typename?: "Variable";
    data: MessageData;
    kind: VariableKind;
    label: Scalars["String"]["output"];
};

export type VariableDataInput = {
    product?: InputMaybe<ProductTemplateInput>;
    reward?: InputMaybe<RewardTemplateInput>;
    text?: InputMaybe<TextMessageInput>;
};

export type VariableInput = {
    data: VariableDataInput;
    kind: VariableKind;
    label: Scalars["String"]["input"];
};

export enum VariableKind {
    Product = "PRODUCT",
    Reward = "REWARD",
    Text = "TEXT",
}

export enum VariableType {
    Announcement = "ANNOUNCEMENT",
    Discount = "DISCOUNT",
    Product = "PRODUCT",
}

export type BaseCampaignFieldsFragment = {
    __typename?: "Campaign";
    id: string;
    status: CampaignStatus;
    deliveryStatus?: CampaignDeliveryStatus | null;
    createdAt: string;
    audienceType: CampaignAudienceType;
    numberOfUsers: number;
    message: {
        __typename?: "CampaignMessageData";
        title?: string | null;
        body?: string | null;
    };
    template: {
        __typename?: "Template";
        variables: Array<{
            __typename?: "MessageVariable";
            type: VariableType;
        }>;
    };
    metrics: { __typename?: "CampaignMetrics"; attributedSales: number };
};

export type BaseCampaignMetricsClusterFieldsFragment = {
    __typename?: "CampaignMetricsChannelCluster";
    none: number;
    push: number;
    sms: number;
};

export type BaseCampaignMetricsFieldsFragment = {
    __typename?: "CampaignMetrics";
    numberOfUsers: number;
    sendCount: number;
    viewCount: number;
    claimCount: number;
    usedCount: number;
    numberOf: {
        __typename?: "CampaignMetricsEventTypeCluster";
        claimed: {
            __typename?: "CampaignMetricsChannelCluster";
            none: number;
            push: number;
            sms: number;
        };
        delivered: {
            __typename?: "CampaignMetricsChannelCluster";
            none: number;
            push: number;
            sms: number;
        };
        failed: {
            __typename?: "CampaignMetricsChannelCluster";
            none: number;
            push: number;
            sms: number;
        };
        notified: {
            __typename?: "CampaignMetricsChannelCluster";
            none: number;
            push: number;
            sms: number;
        };
        optout: {
            __typename?: "CampaignMetricsChannelCluster";
            none: number;
            push: number;
            sms: number;
        };
        sent: {
            __typename?: "CampaignMetricsChannelCluster";
            none: number;
            push: number;
            sms: number;
        };
        used: {
            __typename?: "CampaignMetricsChannelCluster";
            none: number;
            push: number;
            sms: number;
        };
        viewed: {
            __typename?: "CampaignMetricsChannelCluster";
            none: number;
            push: number;
            sms: number;
        };
    };
};

export type BaseCampaignUserBreakdownFieldsFragment = {
    __typename?: "CampaignUserBreakdown";
    users: Array<{
        __typename?: "CampaignUserMetric";
        name?: string | null;
        events: Array<{
            __typename?: "CampaignUserMetricEvent";
            type: CampaignEventType;
            date: string;
            errorMessage?: string | null;
        }>;
    }>;
};

export type BaseCampaignRewardFieldsFragment = {
    __typename?: "CampaignRewardData";
    title: string;
    description: string;
    discount?: string | null;
    expiresAt?: string | null;
    imageUrl?: string | null;
};

export type CampaignWithMetricsFieldsFragment = {
    __typename?: "Campaign";
    id: string;
    status: CampaignStatus;
    deliveryStatus?: CampaignDeliveryStatus | null;
    createdAt: string;
    audienceType: CampaignAudienceType;
    numberOfUsers: number;
    metrics: {
        __typename?: "CampaignMetrics";
        attributedSales: number;
        numberOfUsers: number;
        sendCount: number;
        viewCount: number;
        claimCount: number;
        usedCount: number;
        numberOf: {
            __typename?: "CampaignMetricsEventTypeCluster";
            claimed: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            delivered: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            failed: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            notified: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            optout: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            sent: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            used: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            viewed: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
        };
    };
    message: {
        __typename?: "CampaignMessageData";
        title?: string | null;
        body?: string | null;
    };
    template: {
        __typename?: "Template";
        variables: Array<{
            __typename?: "MessageVariable";
            type: VariableType;
        }>;
    };
};

export type CampaignWithMetricsAndUserBreakdownFieldsFragment = {
    __typename?: "Campaign";
    segmentSizeAtCreation: number;
    reachableSizeAtCreation: number;
    id: string;
    status: CampaignStatus;
    deliveryStatus?: CampaignDeliveryStatus | null;
    createdAt: string;
    audienceType: CampaignAudienceType;
    numberOfUsers: number;
    metrics: {
        __typename?: "CampaignMetrics";
        attributedOrders: number;
        attributedSales: number;
        numberOfUsers: number;
        sendCount: number;
        viewCount: number;
        claimCount: number;
        usedCount: number;
        numberOf: {
            __typename?: "CampaignMetricsEventTypeCluster";
            claimed: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            delivered: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            failed: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            notified: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            optout: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            sent: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            used: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
            viewed: {
                __typename?: "CampaignMetricsChannelCluster";
                none: number;
                push: number;
                sms: number;
            };
        };
    };
    userBreakdown: {
        __typename?: "CampaignUserBreakdown";
        users: Array<{
            __typename?: "CampaignUserMetric";
            name?: string | null;
            events: Array<{
                __typename?: "CampaignUserMetricEvent";
                type: CampaignEventType;
                date: string;
                errorMessage?: string | null;
            }>;
        }>;
    };
    store: { __typename?: "Store"; id: string; name: string };
    message: {
        __typename?: "CampaignMessageData";
        title?: string | null;
        body?: string | null;
    };
    template: {
        __typename?: "Template";
        variables: Array<{
            __typename?: "MessageVariable";
            type: VariableType;
        }>;
    };
};

export type GetStoreCampaignsWithMetricsQueryVariables = Exact<{
    storeId: Scalars["ID"]["input"];
    newStyle?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GetStoreCampaignsWithMetricsQuery = {
    __typename?: "Query";
    campaigns: Array<{
        __typename?: "Campaign";
        id: string;
        status: CampaignStatus;
        deliveryStatus?: CampaignDeliveryStatus | null;
        createdAt: string;
        audienceType: CampaignAudienceType;
        numberOfUsers: number;
        metrics: {
            __typename?: "CampaignMetrics";
            attributedSales: number;
            numberOfUsers: number;
            sendCount: number;
            viewCount: number;
            claimCount: number;
            usedCount: number;
            numberOf: {
                __typename?: "CampaignMetricsEventTypeCluster";
                claimed: {
                    __typename?: "CampaignMetricsChannelCluster";
                    none: number;
                    push: number;
                    sms: number;
                };
                delivered: {
                    __typename?: "CampaignMetricsChannelCluster";
                    none: number;
                    push: number;
                    sms: number;
                };
                failed: {
                    __typename?: "CampaignMetricsChannelCluster";
                    none: number;
                    push: number;
                    sms: number;
                };
                notified: {
                    __typename?: "CampaignMetricsChannelCluster";
                    none: number;
                    push: number;
                    sms: number;
                };
                optout: {
                    __typename?: "CampaignMetricsChannelCluster";
                    none: number;
                    push: number;
                    sms: number;
                };
                sent: {
                    __typename?: "CampaignMetricsChannelCluster";
                    none: number;
                    push: number;
                    sms: number;
                };
                used: {
                    __typename?: "CampaignMetricsChannelCluster";
                    none: number;
                    push: number;
                    sms: number;
                };
                viewed: {
                    __typename?: "CampaignMetricsChannelCluster";
                    none: number;
                    push: number;
                    sms: number;
                };
            };
        };
        message: {
            __typename?: "CampaignMessageData";
            title?: string | null;
            body?: string | null;
        };
        template: {
            __typename?: "Template";
            variables: Array<{
                __typename?: "MessageVariable";
                type: VariableType;
            }>;
        };
    }>;
};

export type GetCampaignWithRewardAndMetricsQueryVariables = Exact<{
    campaignId: Scalars["ID"]["input"];
}>;

export type GetCampaignWithRewardAndMetricsQuery = {
    __typename?: "Query";
    campaignById: {
        __typename?: "CampaignWithReward";
        campaign: {
            __typename?: "Campaign";
            segmentSizeAtCreation: number;
            reachableSizeAtCreation: number;
            id: string;
            status: CampaignStatus;
            deliveryStatus?: CampaignDeliveryStatus | null;
            createdAt: string;
            audienceType: CampaignAudienceType;
            numberOfUsers: number;
            metrics: {
                __typename?: "CampaignMetrics";
                attributedOrders: number;
                attributedSales: number;
                numberOfUsers: number;
                sendCount: number;
                viewCount: number;
                claimCount: number;
                usedCount: number;
                numberOf: {
                    __typename?: "CampaignMetricsEventTypeCluster";
                    claimed: {
                        __typename?: "CampaignMetricsChannelCluster";
                        none: number;
                        push: number;
                        sms: number;
                    };
                    delivered: {
                        __typename?: "CampaignMetricsChannelCluster";
                        none: number;
                        push: number;
                        sms: number;
                    };
                    failed: {
                        __typename?: "CampaignMetricsChannelCluster";
                        none: number;
                        push: number;
                        sms: number;
                    };
                    notified: {
                        __typename?: "CampaignMetricsChannelCluster";
                        none: number;
                        push: number;
                        sms: number;
                    };
                    optout: {
                        __typename?: "CampaignMetricsChannelCluster";
                        none: number;
                        push: number;
                        sms: number;
                    };
                    sent: {
                        __typename?: "CampaignMetricsChannelCluster";
                        none: number;
                        push: number;
                        sms: number;
                    };
                    used: {
                        __typename?: "CampaignMetricsChannelCluster";
                        none: number;
                        push: number;
                        sms: number;
                    };
                    viewed: {
                        __typename?: "CampaignMetricsChannelCluster";
                        none: number;
                        push: number;
                        sms: number;
                    };
                };
            };
            userBreakdown: {
                __typename?: "CampaignUserBreakdown";
                users: Array<{
                    __typename?: "CampaignUserMetric";
                    name?: string | null;
                    events: Array<{
                        __typename?: "CampaignUserMetricEvent";
                        type: CampaignEventType;
                        date: string;
                        errorMessage?: string | null;
                    }>;
                }>;
            };
            store: { __typename?: "Store"; id: string; name: string };
            message: {
                __typename?: "CampaignMessageData";
                title?: string | null;
                body?: string | null;
            };
            template: {
                __typename?: "Template";
                variables: Array<{
                    __typename?: "MessageVariable";
                    type: VariableType;
                }>;
            };
        };
        reward?: {
            __typename?: "CampaignRewardData";
            title: string;
            description: string;
            discount?: string | null;
            expiresAt?: string | null;
            imageUrl?: string | null;
        } | null;
    };
};

export type GetStoreStatsQueryVariables = Exact<{
    storeId: Scalars["ID"]["input"];
}>;

export type GetStoreStatsQuery = {
    __typename?: "Query";
    storeById?: {
        __typename?: "Store";
        stats?: {
            __typename?: "StoreStats";
            totalReachableSMSCustomers: number;
        } | null;
    } | null;
};

export type PaySummaryByDateRangeQueryVariables = Exact<{
    storeId: Scalars["ID"]["input"];
    startDate: Scalars["Date"]["input"];
    endDate: Scalars["Date"]["input"];
}>;

export type PaySummaryByDateRangeQuery = {
    __typename?: "Query";
    paySummaryByDateRange: {
        __typename?: "PaySummaryByDateRange";
        payPeriodId: string;
        regularHours: number;
        overtimeHours: number;
        doubleOvertimeHours: number;
        totalHours: number;
        estWages: number;
        timeCards: number;
        netSales: number;
        startDate: string;
        endDate: string;
        employeeShiftDetails: Array<{
            __typename?: "EmployeeShiftDetails";
            regularHours: number;
            overtimeHours: number;
            doubleOvertimeHours: number;
            totalHours: number;
            estWages: number;
            blendedWage: number;
            employee: {
                __typename?: "PartialEmployee";
                id?: string | null;
                name?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                role?: string | null;
                wage?: number | null;
                identifier?: string | null;
            };
            shifts: Array<{
                __typename?: "ShiftSummary";
                id: string;
                cashTips?: number | null;
                employeeId: string;
                clockedInAt: string;
                clockedOutAt?: string | null;
                overtimeHours: number;
                regularHours: number;
                doubleOvertimeHours: number;
                totalHours: number;
                estWages: number;
                wage: number;
                note?: string | null;
                breaks: Array<{
                    __typename?: "Break";
                    id: string;
                    type: BreakType;
                    start: string;
                    end?: string | null;
                }>;
            }>;
        }>;
        employeesWithOngoingShifts: Array<{
            __typename?: "PartialEmployee";
            name?: string | null;
        }>;
    };
};

export type GetInvoicesQueryVariables = Exact<{
    storeId: Scalars["ID"]["input"];
}>;

export type GetInvoicesQuery = {
    __typename?: "Query";
    invoices: Array<{
        __typename?: "Invoice";
        id: string;
        stripeInvoiceNumber: string;
        createdAt: any;
        totalAmount: number;
        customerName: string;
        customerEmail: string;
        customerPaidAt?: any | null;
        payoutProcessedAt?: any | null;
        description: string;
        amount: number;
        salesTaxPercentage: number;
        stripeInvoiceUrl?: string | null;
        stripeInvoicePdf?: string | null;
    } | null>;
};

export type CreateInvoiceMutationVariables = Exact<{
    storeId: Scalars["ID"]["input"];
    input: CreateInvoiceInput;
}>;

export type CreateInvoiceMutation = {
    __typename?: "Mutation";
    createInvoice: { __typename?: "Invoice"; id: string };
};

export type MachinesByStoreIdQueryVariables = Exact<{
    store: Scalars["ID"]["input"];
}>;

export type MachinesByStoreIdQuery = {
    __typename?: "Query";
    machinesByStoreId?: Array<{
        __typename?: "Machine";
        id: string;
        name: string;
        store: string;
        allowProductList?: Array<string> | null;
        modifierGroupOrdering?: Array<string> | null;
    } | null> | null;
};

export type CreateMachineMutationVariables = Exact<{
    name: Scalars["String"]["input"];
    store: Scalars["String"]["input"];
    allowProductList?: InputMaybe<
        Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
    >;
    modifierGroupOrdering?: InputMaybe<
        Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
    >;
}>;

export type CreateMachineMutation = {
    __typename?: "Mutation";
    createMachine?: {
        __typename?: "Machine";
        id: string;
        name: string;
        store: string;
        allowProductList?: Array<string> | null;
        modifierGroupOrdering?: Array<string> | null;
    } | null;
};

export type UpdateMachineMutationVariables = Exact<{
    id: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
    store: Scalars["String"]["input"];
    allowProductList?: InputMaybe<
        Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
    >;
    modifierGroupOrdering?: InputMaybe<
        Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
    >;
}>;

export type UpdateMachineMutation = {
    __typename?: "Mutation";
    updateMachine?: {
        __typename?: "Machine";
        id: string;
        name: string;
        store: string;
        allowProductList?: Array<string> | null;
        modifierGroupOrdering?: Array<string> | null;
    } | null;
};

export type DeleteMachineMutationVariables = Exact<{
    id: Scalars["ID"]["input"];
}>;

export type DeleteMachineMutation = {
    __typename?: "Mutation";
    deleteMachine?: {
        __typename?: "Machine";
        id: string;
        name: string;
        store: string;
        allowProductList?: Array<string> | null;
        modifierGroupOrdering?: Array<string> | null;
    } | null;
};

export type CreateStoreCampaignMutationVariables = Exact<{
    storeId: Scalars["ID"]["input"];
    data: CreateCampaignInput;
}>;

export type CreateStoreCampaignMutation = {
    __typename?: "Mutation";
    createCampaign: { __typename?: "Campaign"; id: string };
};

export type CreateShiftByAdminMutationVariables = Exact<{
    storeId: Scalars["ID"]["input"];
    employeeId: Scalars["ID"]["input"];
    payPeriodId: Scalars["ID"]["input"];
    clockedInAt: Scalars["Date"]["input"];
    clockedOutAt?: InputMaybe<Scalars["Date"]["input"]>;
    breaks?: InputMaybe<Array<BreakInput> | BreakInput>;
    cashTips?: InputMaybe<Scalars["Float"]["input"]>;
    note?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CreateShiftByAdminMutation = {
    __typename?: "Mutation";
    createShiftByAdmin: {
        __typename?: "Shift";
        id: string;
        employeeId: string;
        payPeriodId: string;
        clockedInAt: string;
        clockedOutAt?: string | null;
        cashTips?: number | null;
        note?: string | null;
        breaks: Array<{
            __typename?: "Break";
            id: string;
            type: BreakType;
            start: string;
            end?: string | null;
            expectedDuration: number;
        }>;
    };
};

export type UpdateShiftByAdminMutationVariables = Exact<{
    storeId: Scalars["ID"]["input"];
    shiftId: Scalars["ID"]["input"];
    clockedInAt: Scalars["Date"]["input"];
    clockedOutAt?: InputMaybe<Scalars["Date"]["input"]>;
    breaks?: InputMaybe<Array<BreakInput> | BreakInput>;
    cashTips?: InputMaybe<Scalars["Float"]["input"]>;
    note?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateShiftByAdminMutation = {
    __typename?: "Mutation";
    updateShiftByAdmin?: {
        __typename?: "Shift";
        id: string;
        employeeId: string;
        payPeriodId: string;
        clockedInAt: string;
        clockedOutAt?: string | null;
        cashTips?: number | null;
        note?: string | null;
        breaks: Array<{
            __typename?: "Break";
            id: string;
            type: BreakType;
            start: string;
            end?: string | null;
            expectedDuration: number;
        }>;
    } | null;
};

export type DeleteShiftByAdminMutationVariables = Exact<{
    storeId: Scalars["ID"]["input"];
    shiftId: Scalars["ID"]["input"];
}>;

export type DeleteShiftByAdminMutation = {
    __typename?: "Mutation";
    deleteShiftByAdmin?: { __typename?: "Shift"; id: string } | null;
};

export type PromotionFieldsFragment = {
    __typename?: "SpecialPromotion";
    id: string;
    name: string;
    description?: string | null;
    imageUrl?: string | null;
    isRunning: boolean;
    startDate?: any | null;
    endDate?: any | null;
    createdAt: any;
    adjustments: Array<{
        __typename?: "PromotionAdjustment";
        scope:
            | {
                  __typename?: "CategoriesAdjustmentScope";
                  categories: Array<string>;
              }
            | { __typename?: "MenuAdjustmentScope"; menu: boolean }
            | {
                  __typename?: "ProductsAdjustmentScope";
                  products: Array<string>;
              };
        adjustment:
            | { __typename?: "PromotionDiscountFlat"; discountFlat: number }
            | {
                  __typename?: "PromotionDiscountPercent";
                  discountPercent: number;
              }
            | { __typename?: "PromotionPrice"; price: number };
    }>;
    conditions: Array<
        | {
              __typename?: "CategoryCountCondition";
              category: string;
              count: number;
              scope: ConditionScope;
          }
        | {
              __typename?: "ItemCountCondition";
              productId: string;
              count: number;
              scope: ConditionScope;
          }
        | {
              __typename?: "MaxItemsCondition";
              maxItems: number;
              scope: ConditionScope;
          }
    >;
    trigger:
        | { __typename?: "AutomaticTrigger" }
        | { __typename?: "BirthdayTrigger" }
        | { __typename?: "CartItemTrigger"; productId: string }
        | { __typename?: "PromoCodeTrigger" };
};

export type GetPromotionsQueryVariables = Exact<{
    storeId: Scalars["ID"]["input"];
}>;

export type GetPromotionsQuery = {
    __typename?: "Query";
    promotions: Array<{
        __typename?: "SpecialPromotion";
        id: string;
        name: string;
        description?: string | null;
        imageUrl?: string | null;
        isRunning: boolean;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        adjustments: Array<{
            __typename?: "PromotionAdjustment";
            scope:
                | {
                      __typename?: "CategoriesAdjustmentScope";
                      categories: Array<string>;
                  }
                | { __typename?: "MenuAdjustmentScope"; menu: boolean }
                | {
                      __typename?: "ProductsAdjustmentScope";
                      products: Array<string>;
                  };
            adjustment:
                | { __typename?: "PromotionDiscountFlat"; discountFlat: number }
                | {
                      __typename?: "PromotionDiscountPercent";
                      discountPercent: number;
                  }
                | { __typename?: "PromotionPrice"; price: number };
        }>;
        conditions: Array<
            | {
                  __typename?: "CategoryCountCondition";
                  category: string;
                  count: number;
                  scope: ConditionScope;
              }
            | {
                  __typename?: "ItemCountCondition";
                  productId: string;
                  count: number;
                  scope: ConditionScope;
              }
            | {
                  __typename?: "MaxItemsCondition";
                  maxItems: number;
                  scope: ConditionScope;
              }
        >;
        trigger:
            | { __typename?: "AutomaticTrigger" }
            | { __typename?: "BirthdayTrigger" }
            | { __typename?: "CartItemTrigger"; productId: string }
            | { __typename?: "PromoCodeTrigger" };
    }>;
};

export type CreatePromotionMutationVariables = Exact<{
    storeId: Scalars["ID"]["input"];
    input: CreatePromotion;
}>;

export type CreatePromotionMutation = {
    __typename?: "Mutation";
    createPromotion: {
        __typename?: "SpecialPromotion";
        id: string;
        name: string;
        description?: string | null;
        imageUrl?: string | null;
        isRunning: boolean;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        adjustments: Array<{
            __typename?: "PromotionAdjustment";
            scope:
                | {
                      __typename?: "CategoriesAdjustmentScope";
                      categories: Array<string>;
                  }
                | { __typename?: "MenuAdjustmentScope"; menu: boolean }
                | {
                      __typename?: "ProductsAdjustmentScope";
                      products: Array<string>;
                  };
            adjustment:
                | { __typename?: "PromotionDiscountFlat"; discountFlat: number }
                | {
                      __typename?: "PromotionDiscountPercent";
                      discountPercent: number;
                  }
                | { __typename?: "PromotionPrice"; price: number };
        }>;
        conditions: Array<
            | {
                  __typename?: "CategoryCountCondition";
                  category: string;
                  count: number;
                  scope: ConditionScope;
              }
            | {
                  __typename?: "ItemCountCondition";
                  productId: string;
                  count: number;
                  scope: ConditionScope;
              }
            | {
                  __typename?: "MaxItemsCondition";
                  maxItems: number;
                  scope: ConditionScope;
              }
        >;
        trigger:
            | { __typename?: "AutomaticTrigger" }
            | { __typename?: "BirthdayTrigger" }
            | { __typename?: "CartItemTrigger"; productId: string }
            | { __typename?: "PromoCodeTrigger" };
    };
};

export type UpdatePromotionMutationVariables = Exact<{
    id: Scalars["ID"]["input"];
    input: UpdatePromotion;
}>;

export type UpdatePromotionMutation = {
    __typename?: "Mutation";
    updatePromotion: {
        __typename?: "SpecialPromotion";
        id: string;
        name: string;
        description?: string | null;
        imageUrl?: string | null;
        isRunning: boolean;
        startDate?: any | null;
        endDate?: any | null;
        createdAt: any;
        adjustments: Array<{
            __typename?: "PromotionAdjustment";
            scope:
                | {
                      __typename?: "CategoriesAdjustmentScope";
                      categories: Array<string>;
                  }
                | { __typename?: "MenuAdjustmentScope"; menu: boolean }
                | {
                      __typename?: "ProductsAdjustmentScope";
                      products: Array<string>;
                  };
            adjustment:
                | { __typename?: "PromotionDiscountFlat"; discountFlat: number }
                | {
                      __typename?: "PromotionDiscountPercent";
                      discountPercent: number;
                  }
                | { __typename?: "PromotionPrice"; price: number };
        }>;
        conditions: Array<
            | {
                  __typename?: "CategoryCountCondition";
                  category: string;
                  count: number;
                  scope: ConditionScope;
              }
            | {
                  __typename?: "ItemCountCondition";
                  productId: string;
                  count: number;
                  scope: ConditionScope;
              }
            | {
                  __typename?: "MaxItemsCondition";
                  maxItems: number;
                  scope: ConditionScope;
              }
        >;
        trigger:
            | { __typename?: "AutomaticTrigger" }
            | { __typename?: "BirthdayTrigger" }
            | { __typename?: "CartItemTrigger"; productId: string }
            | { __typename?: "PromoCodeTrigger" };
    };
};

export type DeletePromotionMutationVariables = Exact<{
    id: Scalars["ID"]["input"];
}>;

export type DeletePromotionMutation = {
    __typename?: "Mutation";
    deletePromotion: string;
};

export type GetStoreAudienceQueryVariables = Exact<{
    storeId: Scalars["ID"]["input"];
}>;

export type GetStoreAudienceQuery = {
    __typename?: "Query";
    storeAudience: {
        __typename?: "StoreAudience";
        followers: number;
        recentCustomers: number;
        needAttentionCustomers: number;
        newCustomers: number;
        testCustomers?: number | null;
        followersWithIndividualConsent: number;
        recentCustomersWithIndividualConsent: number;
        newCustomersWithIndividualConsent: number;
        needAttentionCustomersWithIndividualConsent: number;
        testCustomersWithIndividualConsent?: number | null;
    };
};

export type ServiceAreaFieldsFragment = {
    __typename?: "StoreServiceArea";
    id: string;
    name: string;
    layout: Array<{
        __typename?: "LayoutElement";
        id: string;
        properties:
            | { __typename?: "BarrierProperties"; type: Element }
            | { __typename?: "LabelProperties"; label: string; type: Element }
            | {
                  __typename?: "TableProperties";
                  tableId: string;
                  type: Element;
              };
        geometry:
            | {
                  __typename?: "CircleGeometry";
                  radius: number;
                  type: Shape;
                  x: number;
                  y: number;
              }
            | {
                  __typename?: "DiamondGeometry";
                  size: number;
                  type: Shape;
                  x: number;
                  y: number;
              }
            | {
                  __typename?: "RectGeometry";
                  width: number;
                  height: number;
                  fill: boolean;
                  stroke?: ShapeStroke | null;
                  type: Shape;
                  x: number;
                  y: number;
              };
    }>;
    tables: Array<{
        __typename?: "Table";
        id: string;
        name: string;
        numSeats: number;
    }>;
};

export type GetServiceAreasQueryVariables = Exact<{
    storeId: Scalars["ID"]["input"];
}>;

export type GetServiceAreasQuery = {
    __typename?: "Query";
    serviceAreas: Array<{
        __typename?: "StoreServiceArea";
        id: string;
        name: string;
        layout: Array<{
            __typename?: "LayoutElement";
            id: string;
            properties:
                | { __typename?: "BarrierProperties"; type: Element }
                | {
                      __typename?: "LabelProperties";
                      label: string;
                      type: Element;
                  }
                | {
                      __typename?: "TableProperties";
                      tableId: string;
                      type: Element;
                  };
            geometry:
                | {
                      __typename?: "CircleGeometry";
                      radius: number;
                      type: Shape;
                      x: number;
                      y: number;
                  }
                | {
                      __typename?: "DiamondGeometry";
                      size: number;
                      type: Shape;
                      x: number;
                      y: number;
                  }
                | {
                      __typename?: "RectGeometry";
                      width: number;
                      height: number;
                      fill: boolean;
                      stroke?: ShapeStroke | null;
                      type: Shape;
                      x: number;
                      y: number;
                  };
        }>;
        tables: Array<{
            __typename?: "Table";
            id: string;
            name: string;
            numSeats: number;
        }>;
    }>;
};

export type CreateServiceAreaMutationVariables = Exact<{
    storeId: Scalars["ID"]["input"];
    input: CreateServiceArea;
}>;

export type CreateServiceAreaMutation = {
    __typename?: "Mutation";
    createServiceArea: {
        __typename?: "StoreServiceArea";
        id: string;
        name: string;
        layout: Array<{
            __typename?: "LayoutElement";
            id: string;
            properties:
                | { __typename?: "BarrierProperties"; type: Element }
                | {
                      __typename?: "LabelProperties";
                      label: string;
                      type: Element;
                  }
                | {
                      __typename?: "TableProperties";
                      tableId: string;
                      type: Element;
                  };
            geometry:
                | {
                      __typename?: "CircleGeometry";
                      radius: number;
                      type: Shape;
                      x: number;
                      y: number;
                  }
                | {
                      __typename?: "DiamondGeometry";
                      size: number;
                      type: Shape;
                      x: number;
                      y: number;
                  }
                | {
                      __typename?: "RectGeometry";
                      width: number;
                      height: number;
                      fill: boolean;
                      stroke?: ShapeStroke | null;
                      type: Shape;
                      x: number;
                      y: number;
                  };
        }>;
        tables: Array<{
            __typename?: "Table";
            id: string;
            name: string;
            numSeats: number;
        }>;
    };
};

export type UpdateServiceAreaMutationVariables = Exact<{
    storeId: Scalars["ID"]["input"];
    input: UpdateServiceArea;
}>;

export type UpdateServiceAreaMutation = {
    __typename?: "Mutation";
    updateServiceArea: {
        __typename?: "StoreServiceArea";
        id: string;
        name: string;
        layout: Array<{
            __typename?: "LayoutElement";
            id: string;
            properties:
                | { __typename?: "BarrierProperties"; type: Element }
                | {
                      __typename?: "LabelProperties";
                      label: string;
                      type: Element;
                  }
                | {
                      __typename?: "TableProperties";
                      tableId: string;
                      type: Element;
                  };
            geometry:
                | {
                      __typename?: "CircleGeometry";
                      radius: number;
                      type: Shape;
                      x: number;
                      y: number;
                  }
                | {
                      __typename?: "DiamondGeometry";
                      size: number;
                      type: Shape;
                      x: number;
                      y: number;
                  }
                | {
                      __typename?: "RectGeometry";
                      width: number;
                      height: number;
                      fill: boolean;
                      stroke?: ShapeStroke | null;
                      type: Shape;
                      x: number;
                      y: number;
                  };
        }>;
        tables: Array<{
            __typename?: "Table";
            id: string;
            name: string;
            numSeats: number;
        }>;
    };
};

export type DeleteServiceAreaMutationVariables = Exact<{
    storeId: Scalars["ID"]["input"];
    id: Scalars["ID"]["input"];
}>;

export type DeleteServiceAreaMutation = {
    __typename?: "Mutation";
    deleteServiceArea: string;
};

export type ScheduleBlockFieldsFragment = {
    __typename?: "ScheduleBlock";
    start?: { __typename?: "HourMinute"; hour: number; minute: number } | null;
    end?: { __typename?: "HourMinute"; hour: number; minute: number } | null;
};

export type StoreMenuFieldsFragment = {
    __typename?: "StoreMenu";
    id: string;
    store: string;
    name: string;
    categories: Array<string | null>;
    productExclusions: Array<string | null>;
    createdAt: any;
    updatedAt: any;
    priceAdjustment?: {
        __typename?: "PriceAdjustment";
        value: number;
        adjustmentType: PriceAdjustmentType;
        roundTo?: number | null;
        adjustModifiers: boolean;
    } | null;
    productPriceOverrides?: Array<{
        __typename?: "PriceOverride";
        value: number;
        id: string;
    } | null> | null;
    context?: {
        __typename?: "MenuContext";
        channel?: Array<ChannelType | null> | null;
        fulfillment?: Array<FulfillmentType | null> | null;
        dateRange?: {
            __typename?: "DateRange";
            start?: string | null;
            end?: string | null;
        } | null;
        schedule?: {
            __typename?: "Schedule";
            monday?: Array<{
                __typename?: "ScheduleBlock";
                start?: {
                    __typename?: "HourMinute";
                    hour: number;
                    minute: number;
                } | null;
                end?: {
                    __typename?: "HourMinute";
                    hour: number;
                    minute: number;
                } | null;
            } | null> | null;
            tuesday?: Array<{
                __typename?: "ScheduleBlock";
                start?: {
                    __typename?: "HourMinute";
                    hour: number;
                    minute: number;
                } | null;
                end?: {
                    __typename?: "HourMinute";
                    hour: number;
                    minute: number;
                } | null;
            } | null> | null;
            wednesday?: Array<{
                __typename?: "ScheduleBlock";
                start?: {
                    __typename?: "HourMinute";
                    hour: number;
                    minute: number;
                } | null;
                end?: {
                    __typename?: "HourMinute";
                    hour: number;
                    minute: number;
                } | null;
            } | null> | null;
            thursday?: Array<{
                __typename?: "ScheduleBlock";
                start?: {
                    __typename?: "HourMinute";
                    hour: number;
                    minute: number;
                } | null;
                end?: {
                    __typename?: "HourMinute";
                    hour: number;
                    minute: number;
                } | null;
            } | null> | null;
            friday?: Array<{
                __typename?: "ScheduleBlock";
                start?: {
                    __typename?: "HourMinute";
                    hour: number;
                    minute: number;
                } | null;
                end?: {
                    __typename?: "HourMinute";
                    hour: number;
                    minute: number;
                } | null;
            } | null> | null;
            saturday?: Array<{
                __typename?: "ScheduleBlock";
                start?: {
                    __typename?: "HourMinute";
                    hour: number;
                    minute: number;
                } | null;
                end?: {
                    __typename?: "HourMinute";
                    hour: number;
                    minute: number;
                } | null;
            } | null> | null;
            sunday?: Array<{
                __typename?: "ScheduleBlock";
                start?: {
                    __typename?: "HourMinute";
                    hour: number;
                    minute: number;
                } | null;
                end?: {
                    __typename?: "HourMinute";
                    hour: number;
                    minute: number;
                } | null;
            } | null> | null;
        } | null;
    } | null;
};

export type GetMenusQueryVariables = Exact<{
    storeId: Scalars["ID"]["input"];
}>;

export type GetMenusQuery = {
    __typename?: "Query";
    storeMenus: Array<{
        __typename?: "StoreMenu";
        id: string;
        store: string;
        name: string;
        categories: Array<string | null>;
        productExclusions: Array<string | null>;
        createdAt: any;
        updatedAt: any;
        priceAdjustment?: {
            __typename?: "PriceAdjustment";
            value: number;
            adjustmentType: PriceAdjustmentType;
            roundTo?: number | null;
            adjustModifiers: boolean;
        } | null;
        productPriceOverrides?: Array<{
            __typename?: "PriceOverride";
            value: number;
            id: string;
        } | null> | null;
        context?: {
            __typename?: "MenuContext";
            channel?: Array<ChannelType | null> | null;
            fulfillment?: Array<FulfillmentType | null> | null;
            dateRange?: {
                __typename?: "DateRange";
                start?: string | null;
                end?: string | null;
            } | null;
            schedule?: {
                __typename?: "Schedule";
                monday?: Array<{
                    __typename?: "ScheduleBlock";
                    start?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                    end?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                } | null> | null;
                tuesday?: Array<{
                    __typename?: "ScheduleBlock";
                    start?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                    end?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                } | null> | null;
                wednesday?: Array<{
                    __typename?: "ScheduleBlock";
                    start?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                    end?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                } | null> | null;
                thursday?: Array<{
                    __typename?: "ScheduleBlock";
                    start?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                    end?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                } | null> | null;
                friday?: Array<{
                    __typename?: "ScheduleBlock";
                    start?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                    end?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                } | null> | null;
                saturday?: Array<{
                    __typename?: "ScheduleBlock";
                    start?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                    end?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                } | null> | null;
                sunday?: Array<{
                    __typename?: "ScheduleBlock";
                    start?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                    end?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                } | null> | null;
            } | null;
        } | null;
    } | null>;
};

export type CreateMenuMutationVariables = Exact<{
    storeId: Scalars["ID"]["input"];
    input: StoreMenuInput;
}>;

export type CreateMenuMutation = {
    __typename?: "Mutation";
    createStoreMenu: {
        __typename?: "StoreMenu";
        id: string;
        store: string;
        name: string;
        categories: Array<string | null>;
        productExclusions: Array<string | null>;
        createdAt: any;
        updatedAt: any;
        priceAdjustment?: {
            __typename?: "PriceAdjustment";
            value: number;
            adjustmentType: PriceAdjustmentType;
            roundTo?: number | null;
            adjustModifiers: boolean;
        } | null;
        productPriceOverrides?: Array<{
            __typename?: "PriceOverride";
            value: number;
            id: string;
        } | null> | null;
        context?: {
            __typename?: "MenuContext";
            channel?: Array<ChannelType | null> | null;
            fulfillment?: Array<FulfillmentType | null> | null;
            dateRange?: {
                __typename?: "DateRange";
                start?: string | null;
                end?: string | null;
            } | null;
            schedule?: {
                __typename?: "Schedule";
                monday?: Array<{
                    __typename?: "ScheduleBlock";
                    start?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                    end?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                } | null> | null;
                tuesday?: Array<{
                    __typename?: "ScheduleBlock";
                    start?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                    end?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                } | null> | null;
                wednesday?: Array<{
                    __typename?: "ScheduleBlock";
                    start?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                    end?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                } | null> | null;
                thursday?: Array<{
                    __typename?: "ScheduleBlock";
                    start?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                    end?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                } | null> | null;
                friday?: Array<{
                    __typename?: "ScheduleBlock";
                    start?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                    end?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                } | null> | null;
                saturday?: Array<{
                    __typename?: "ScheduleBlock";
                    start?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                    end?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                } | null> | null;
                sunday?: Array<{
                    __typename?: "ScheduleBlock";
                    start?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                    end?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                } | null> | null;
            } | null;
        } | null;
    };
};

export type UpdateMenuMutationVariables = Exact<{
    id: Scalars["ID"]["input"];
    input: StoreMenuInput;
}>;

export type UpdateMenuMutation = {
    __typename?: "Mutation";
    updateStoreMenu: {
        __typename?: "StoreMenu";
        id: string;
        store: string;
        name: string;
        categories: Array<string | null>;
        productExclusions: Array<string | null>;
        createdAt: any;
        updatedAt: any;
        priceAdjustment?: {
            __typename?: "PriceAdjustment";
            value: number;
            adjustmentType: PriceAdjustmentType;
            roundTo?: number | null;
            adjustModifiers: boolean;
        } | null;
        productPriceOverrides?: Array<{
            __typename?: "PriceOverride";
            value: number;
            id: string;
        } | null> | null;
        context?: {
            __typename?: "MenuContext";
            channel?: Array<ChannelType | null> | null;
            fulfillment?: Array<FulfillmentType | null> | null;
            dateRange?: {
                __typename?: "DateRange";
                start?: string | null;
                end?: string | null;
            } | null;
            schedule?: {
                __typename?: "Schedule";
                monday?: Array<{
                    __typename?: "ScheduleBlock";
                    start?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                    end?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                } | null> | null;
                tuesday?: Array<{
                    __typename?: "ScheduleBlock";
                    start?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                    end?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                } | null> | null;
                wednesday?: Array<{
                    __typename?: "ScheduleBlock";
                    start?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                    end?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                } | null> | null;
                thursday?: Array<{
                    __typename?: "ScheduleBlock";
                    start?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                    end?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                } | null> | null;
                friday?: Array<{
                    __typename?: "ScheduleBlock";
                    start?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                    end?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                } | null> | null;
                saturday?: Array<{
                    __typename?: "ScheduleBlock";
                    start?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                    end?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                } | null> | null;
                sunday?: Array<{
                    __typename?: "ScheduleBlock";
                    start?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                    end?: {
                        __typename?: "HourMinute";
                        hour: number;
                        minute: number;
                    } | null;
                } | null> | null;
            } | null;
        } | null;
    };
};

export type DeleteMenuMutationVariables = Exact<{
    id: Scalars["ID"]["input"];
}>;

export type DeleteMenuMutation = {
    __typename?: "Mutation";
    deleteStoreMenu?: string | null;
};

export type GetTablesQueryVariables = Exact<{
    storeId: Scalars["ID"]["input"];
}>;

export type GetTablesQuery = {
    __typename?: "Query";
    storeTables?: Array<{
        __typename?: "Table";
        id: string;
        name: string;
        numSeats: number;
    }> | null;
};

export type CreateTableMutationVariables = Exact<{
    storeId: Scalars["ID"]["input"];
    input: CreateTableInput;
}>;

export type CreateTableMutation = {
    __typename?: "Mutation";
    createStoreTable: { __typename?: "Table"; id: string };
};

export type UpdateTableMutationVariables = Exact<{
    tableId: Scalars["ID"]["input"];
    input: UpdateTableInput;
}>;

export type UpdateTableMutation = {
    __typename?: "Mutation";
    updateStoreTable: { __typename?: "Table"; id: string };
};

export type DeleteTableMutationVariables = Exact<{
    storeId: Scalars["ID"]["input"];
    tableId: Scalars["ID"]["input"];
}>;

export type DeleteTableMutation = {
    __typename?: "Mutation";
    deleteStoreTable: string;
};

export type NewMachineFragment = {
    __typename?: "Machine";
    id: string;
    name: string;
    store: string;
    allowProductList?: Array<string> | null;
    modifierGroupOrdering?: Array<string> | null;
};

export const BaseCampaignRewardFieldsFragmentDoc = gql`
    fragment BaseCampaignRewardFields on CampaignRewardData {
        title
        description
        discount
        expiresAt
        imageUrl
    }
`;
export const BaseCampaignFieldsFragmentDoc = gql`
    fragment BaseCampaignFields on Campaign {
        id
        status
        deliveryStatus
        createdAt
        audienceType
        numberOfUsers
        message {
            title
            body
        }
        template {
            variables {
                type
            }
        }
        metrics {
            attributedSales
        }
    }
`;
export const BaseCampaignMetricsClusterFieldsFragmentDoc = gql`
    fragment BaseCampaignMetricsClusterFields on CampaignMetricsChannelCluster {
        none
        push
        sms
    }
`;
export const BaseCampaignMetricsFieldsFragmentDoc = gql`
    fragment BaseCampaignMetricsFields on CampaignMetrics {
        numberOfUsers
        numberOf {
            claimed {
                ...BaseCampaignMetricsClusterFields
            }
            delivered {
                ...BaseCampaignMetricsClusterFields
            }
            failed {
                ...BaseCampaignMetricsClusterFields
            }
            notified {
                ...BaseCampaignMetricsClusterFields
            }
            optout {
                ...BaseCampaignMetricsClusterFields
            }
            sent {
                ...BaseCampaignMetricsClusterFields
            }
            used {
                ...BaseCampaignMetricsClusterFields
            }
            viewed {
                ...BaseCampaignMetricsClusterFields
            }
        }
        sendCount
        viewCount
        claimCount
        usedCount
    }
    ${BaseCampaignMetricsClusterFieldsFragmentDoc}
`;
export const CampaignWithMetricsFieldsFragmentDoc = gql`
    fragment CampaignWithMetricsFields on Campaign {
        ...BaseCampaignFields
        metrics {
            ...BaseCampaignMetricsFields
        }
    }
    ${BaseCampaignFieldsFragmentDoc}
    ${BaseCampaignMetricsFieldsFragmentDoc}
`;
export const BaseCampaignUserBreakdownFieldsFragmentDoc = gql`
    fragment BaseCampaignUserBreakdownFields on CampaignUserBreakdown {
        users {
            name
            events {
                type
                date
                errorMessage
            }
        }
    }
`;
export const CampaignWithMetricsAndUserBreakdownFieldsFragmentDoc = gql`
    fragment CampaignWithMetricsAndUserBreakdownFields on Campaign {
        ...CampaignWithMetricsFields
        metrics {
            attributedOrders
            attributedSales
        }
        userBreakdown {
            ...BaseCampaignUserBreakdownFields
        }
        store {
            id
            name
        }
        segmentSizeAtCreation
        reachableSizeAtCreation
    }
    ${CampaignWithMetricsFieldsFragmentDoc}
    ${BaseCampaignUserBreakdownFieldsFragmentDoc}
`;
export const PromotionFieldsFragmentDoc = gql`
    fragment PromotionFields on SpecialPromotion {
        id
        name
        description
        imageUrl
        adjustments {
            scope {
                ... on ProductsAdjustmentScope {
                    products
                }
                ... on CategoriesAdjustmentScope {
                    categories
                }
                ... on MenuAdjustmentScope {
                    menu
                }
            }
            adjustment {
                ... on PromotionDiscountPercent {
                    discountPercent
                }
                ... on PromotionDiscountFlat {
                    discountFlat
                }
                ... on PromotionPrice {
                    price
                }
            }
        }
        conditions {
            scope
            ... on MaxItemsCondition {
                maxItems
            }
            ... on ItemCountCondition {
                productId
                count
            }
            ... on CategoryCountCondition {
                category
                count
            }
        }
        trigger {
            ... on CartItemTrigger {
                productId
            }
        }
        isRunning
        startDate
        endDate
        createdAt
    }
`;
export const ServiceAreaFieldsFragmentDoc = gql`
    fragment ServiceAreaFields on StoreServiceArea {
        id
        name
        layout {
            id
            properties {
                type
                ... on TableProperties {
                    tableId
                }
                ... on LabelProperties {
                    label
                }
            }
            geometry {
                type
                x
                y
                ... on RectGeometry {
                    width
                    height
                    fill
                    stroke
                }
                ... on DiamondGeometry {
                    size
                }
                ... on CircleGeometry {
                    radius
                }
            }
        }
        tables {
            id
            name
            numSeats
        }
    }
`;
export const ScheduleBlockFieldsFragmentDoc = gql`
    fragment ScheduleBlockFields on ScheduleBlock {
        start {
            hour
            minute
        }
        end {
            hour
            minute
        }
    }
`;
export const StoreMenuFieldsFragmentDoc = gql`
    fragment StoreMenuFields on StoreMenu {
        id
        store
        name
        categories
        priceAdjustment {
            value
            adjustmentType
            roundTo
            adjustModifiers
        }
        productPriceOverrides {
            value
            id
        }
        context {
            channel
            fulfillment
            dateRange {
                start
                end
            }
            schedule {
                monday {
                    ...ScheduleBlockFields
                }
                tuesday {
                    ...ScheduleBlockFields
                }
                wednesday {
                    ...ScheduleBlockFields
                }
                thursday {
                    ...ScheduleBlockFields
                }
                friday {
                    ...ScheduleBlockFields
                }
                saturday {
                    ...ScheduleBlockFields
                }
                sunday {
                    ...ScheduleBlockFields
                }
            }
        }
        productExclusions
        createdAt
        updatedAt
    }
    ${ScheduleBlockFieldsFragmentDoc}
`;
export const NewMachineFragmentDoc = gql`
    fragment NewMachine on Machine {
        id
        name
        store
        allowProductList
        modifierGroupOrdering
    }
`;
export const GetStoreCampaignsWithMetricsDocument = gql`
    query GetStoreCampaignsWithMetrics($storeId: ID!, $newStyle: Boolean) {
        campaigns(filters: { storeId: $storeId, newStyle: $newStyle }) {
            ...CampaignWithMetricsFields
        }
    }
    ${CampaignWithMetricsFieldsFragmentDoc}
`;

/**
 * __useGetStoreCampaignsWithMetricsQuery__
 *
 * To run a query within a React component, call `useGetStoreCampaignsWithMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreCampaignsWithMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreCampaignsWithMetricsQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      newStyle: // value for 'newStyle'
 *   },
 * });
 */
export function useGetStoreCampaignsWithMetricsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetStoreCampaignsWithMetricsQuery,
        GetStoreCampaignsWithMetricsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetStoreCampaignsWithMetricsQuery,
        GetStoreCampaignsWithMetricsQueryVariables
    >(GetStoreCampaignsWithMetricsDocument, options);
}
export function useGetStoreCampaignsWithMetricsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetStoreCampaignsWithMetricsQuery,
        GetStoreCampaignsWithMetricsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetStoreCampaignsWithMetricsQuery,
        GetStoreCampaignsWithMetricsQueryVariables
    >(GetStoreCampaignsWithMetricsDocument, options);
}
export type GetStoreCampaignsWithMetricsQueryHookResult = ReturnType<
    typeof useGetStoreCampaignsWithMetricsQuery
>;
export type GetStoreCampaignsWithMetricsLazyQueryHookResult = ReturnType<
    typeof useGetStoreCampaignsWithMetricsLazyQuery
>;
export type GetStoreCampaignsWithMetricsQueryResult = Apollo.QueryResult<
    GetStoreCampaignsWithMetricsQuery,
    GetStoreCampaignsWithMetricsQueryVariables
>;
export const GetCampaignWithRewardAndMetricsDocument = gql`
    query GetCampaignWithRewardAndMetrics($campaignId: ID!) {
        campaignById(campaignId: $campaignId) {
            campaign {
                ...CampaignWithMetricsAndUserBreakdownFields
            }
            reward {
                ...BaseCampaignRewardFields
            }
        }
    }
    ${CampaignWithMetricsAndUserBreakdownFieldsFragmentDoc}
    ${BaseCampaignRewardFieldsFragmentDoc}
`;

/**
 * __useGetCampaignWithRewardAndMetricsQuery__
 *
 * To run a query within a React component, call `useGetCampaignWithRewardAndMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignWithRewardAndMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignWithRewardAndMetricsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetCampaignWithRewardAndMetricsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetCampaignWithRewardAndMetricsQuery,
        GetCampaignWithRewardAndMetricsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetCampaignWithRewardAndMetricsQuery,
        GetCampaignWithRewardAndMetricsQueryVariables
    >(GetCampaignWithRewardAndMetricsDocument, options);
}
export function useGetCampaignWithRewardAndMetricsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetCampaignWithRewardAndMetricsQuery,
        GetCampaignWithRewardAndMetricsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetCampaignWithRewardAndMetricsQuery,
        GetCampaignWithRewardAndMetricsQueryVariables
    >(GetCampaignWithRewardAndMetricsDocument, options);
}
export type GetCampaignWithRewardAndMetricsQueryHookResult = ReturnType<
    typeof useGetCampaignWithRewardAndMetricsQuery
>;
export type GetCampaignWithRewardAndMetricsLazyQueryHookResult = ReturnType<
    typeof useGetCampaignWithRewardAndMetricsLazyQuery
>;
export type GetCampaignWithRewardAndMetricsQueryResult = Apollo.QueryResult<
    GetCampaignWithRewardAndMetricsQuery,
    GetCampaignWithRewardAndMetricsQueryVariables
>;
export const GetStoreStatsDocument = gql`
    query GetStoreStats($storeId: ID!) {
        storeById(id: $storeId) {
            stats {
                totalReachableSMSCustomers
            }
        }
    }
`;

/**
 * __useGetStoreStatsQuery__
 *
 * To run a query within a React component, call `useGetStoreStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreStatsQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetStoreStatsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetStoreStatsQuery,
        GetStoreStatsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetStoreStatsQuery, GetStoreStatsQueryVariables>(
        GetStoreStatsDocument,
        options,
    );
}
export function useGetStoreStatsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetStoreStatsQuery,
        GetStoreStatsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetStoreStatsQuery, GetStoreStatsQueryVariables>(
        GetStoreStatsDocument,
        options,
    );
}
export type GetStoreStatsQueryHookResult = ReturnType<
    typeof useGetStoreStatsQuery
>;
export type GetStoreStatsLazyQueryHookResult = ReturnType<
    typeof useGetStoreStatsLazyQuery
>;
export type GetStoreStatsQueryResult = Apollo.QueryResult<
    GetStoreStatsQuery,
    GetStoreStatsQueryVariables
>;
export const PaySummaryByDateRangeDocument = gql`
    query paySummaryByDateRange(
        $storeId: ID!
        $startDate: Date!
        $endDate: Date!
    ) {
        paySummaryByDateRange(
            storeId: $storeId
            startDate: $startDate
            endDate: $endDate
        ) {
            payPeriodId
            employeeShiftDetails {
                employee {
                    id
                    name
                    firstName
                    lastName
                    role
                    wage
                    identifier
                }
                shifts {
                    id
                    cashTips
                    employeeId
                    clockedInAt
                    clockedOutAt
                    overtimeHours
                    regularHours
                    doubleOvertimeHours
                    totalHours
                    estWages
                    wage
                    note
                    breaks {
                        id
                        type
                        start
                        end
                    }
                }
                regularHours
                overtimeHours
                doubleOvertimeHours
                totalHours
                estWages
                blendedWage
            }
            employeesWithOngoingShifts {
                name
            }
            regularHours
            overtimeHours
            doubleOvertimeHours
            totalHours
            estWages
            totalHours
            regularHours
            timeCards
            netSales
            startDate
            endDate
        }
    }
`;

/**
 * __usePaySummaryByDateRangeQuery__
 *
 * To run a query within a React component, call `usePaySummaryByDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaySummaryByDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaySummaryByDateRangeQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function usePaySummaryByDateRangeQuery(
    baseOptions: Apollo.QueryHookOptions<
        PaySummaryByDateRangeQuery,
        PaySummaryByDateRangeQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        PaySummaryByDateRangeQuery,
        PaySummaryByDateRangeQueryVariables
    >(PaySummaryByDateRangeDocument, options);
}
export function usePaySummaryByDateRangeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PaySummaryByDateRangeQuery,
        PaySummaryByDateRangeQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        PaySummaryByDateRangeQuery,
        PaySummaryByDateRangeQueryVariables
    >(PaySummaryByDateRangeDocument, options);
}
export type PaySummaryByDateRangeQueryHookResult = ReturnType<
    typeof usePaySummaryByDateRangeQuery
>;
export type PaySummaryByDateRangeLazyQueryHookResult = ReturnType<
    typeof usePaySummaryByDateRangeLazyQuery
>;
export type PaySummaryByDateRangeQueryResult = Apollo.QueryResult<
    PaySummaryByDateRangeQuery,
    PaySummaryByDateRangeQueryVariables
>;
export const GetInvoicesDocument = gql`
    query GetInvoices($storeId: ID!) {
        invoices(storeId: $storeId) {
            id
            stripeInvoiceNumber
            createdAt
            totalAmount
            customerName
            customerEmail
            customerPaidAt
            payoutProcessedAt
            description
            amount
            salesTaxPercentage
            stripeInvoiceUrl
            stripeInvoicePdf
        }
    }
`;

/**
 * __useGetInvoicesQuery__
 *
 * To run a query within a React component, call `useGetInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetInvoicesQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetInvoicesQuery,
        GetInvoicesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(
        GetInvoicesDocument,
        options,
    );
}
export function useGetInvoicesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetInvoicesQuery,
        GetInvoicesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(
        GetInvoicesDocument,
        options,
    );
}
export type GetInvoicesQueryHookResult = ReturnType<typeof useGetInvoicesQuery>;
export type GetInvoicesLazyQueryHookResult = ReturnType<
    typeof useGetInvoicesLazyQuery
>;
export type GetInvoicesQueryResult = Apollo.QueryResult<
    GetInvoicesQuery,
    GetInvoicesQueryVariables
>;
export const CreateInvoiceDocument = gql`
    mutation CreateInvoice($storeId: ID!, $input: CreateInvoiceInput!) {
        createInvoice(storeId: $storeId, input: $input) {
            id
        }
    }
`;
export type CreateInvoiceMutationFn = Apollo.MutationFunction<
    CreateInvoiceMutation,
    CreateInvoiceMutationVariables
>;

/**
 * __useCreateInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceMutation, { data, loading, error }] = useCreateInvoiceMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvoiceMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateInvoiceMutation,
        CreateInvoiceMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateInvoiceMutation,
        CreateInvoiceMutationVariables
    >(CreateInvoiceDocument, options);
}
export type CreateInvoiceMutationHookResult = ReturnType<
    typeof useCreateInvoiceMutation
>;
export type CreateInvoiceMutationResult =
    Apollo.MutationResult<CreateInvoiceMutation>;
export type CreateInvoiceMutationOptions = Apollo.BaseMutationOptions<
    CreateInvoiceMutation,
    CreateInvoiceMutationVariables
>;
export const MachinesByStoreIdDocument = gql`
    query MachinesByStoreId($store: ID!) {
        machinesByStoreId(store: $store) {
            id
            name
            store
            allowProductList
            modifierGroupOrdering
        }
    }
`;

/**
 * __useMachinesByStoreIdQuery__
 *
 * To run a query within a React component, call `useMachinesByStoreIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMachinesByStoreIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMachinesByStoreIdQuery({
 *   variables: {
 *      store: // value for 'store'
 *   },
 * });
 */
export function useMachinesByStoreIdQuery(
    baseOptions: Apollo.QueryHookOptions<
        MachinesByStoreIdQuery,
        MachinesByStoreIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        MachinesByStoreIdQuery,
        MachinesByStoreIdQueryVariables
    >(MachinesByStoreIdDocument, options);
}
export function useMachinesByStoreIdLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        MachinesByStoreIdQuery,
        MachinesByStoreIdQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        MachinesByStoreIdQuery,
        MachinesByStoreIdQueryVariables
    >(MachinesByStoreIdDocument, options);
}
export type MachinesByStoreIdQueryHookResult = ReturnType<
    typeof useMachinesByStoreIdQuery
>;
export type MachinesByStoreIdLazyQueryHookResult = ReturnType<
    typeof useMachinesByStoreIdLazyQuery
>;
export type MachinesByStoreIdQueryResult = Apollo.QueryResult<
    MachinesByStoreIdQuery,
    MachinesByStoreIdQueryVariables
>;
export const CreateMachineDocument = gql`
    mutation CreateMachine(
        $name: String!
        $store: String!
        $allowProductList: [String!]
        $modifierGroupOrdering: [String!]
    ) {
        createMachine(
            name: $name
            store: $store
            allowProductList: $allowProductList
            modifierGroupOrdering: $modifierGroupOrdering
        ) {
            id
            name
            store
            allowProductList
            modifierGroupOrdering
        }
    }
`;
export type CreateMachineMutationFn = Apollo.MutationFunction<
    CreateMachineMutation,
    CreateMachineMutationVariables
>;

/**
 * __useCreateMachineMutation__
 *
 * To run a mutation, you first call `useCreateMachineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMachineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMachineMutation, { data, loading, error }] = useCreateMachineMutation({
 *   variables: {
 *      name: // value for 'name'
 *      store: // value for 'store'
 *      allowProductList: // value for 'allowProductList'
 *      modifierGroupOrdering: // value for 'modifierGroupOrdering'
 *   },
 * });
 */
export function useCreateMachineMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateMachineMutation,
        CreateMachineMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateMachineMutation,
        CreateMachineMutationVariables
    >(CreateMachineDocument, options);
}
export type CreateMachineMutationHookResult = ReturnType<
    typeof useCreateMachineMutation
>;
export type CreateMachineMutationResult =
    Apollo.MutationResult<CreateMachineMutation>;
export type CreateMachineMutationOptions = Apollo.BaseMutationOptions<
    CreateMachineMutation,
    CreateMachineMutationVariables
>;
export const UpdateMachineDocument = gql`
    mutation UpdateMachine(
        $id: ID!
        $name: String!
        $store: String!
        $allowProductList: [String!]
        $modifierGroupOrdering: [String!]
    ) {
        updateMachine(
            id: $id
            name: $name
            store: $store
            allowProductList: $allowProductList
            modifierGroupOrdering: $modifierGroupOrdering
        ) {
            id
            name
            store
            allowProductList
            modifierGroupOrdering
        }
    }
`;
export type UpdateMachineMutationFn = Apollo.MutationFunction<
    UpdateMachineMutation,
    UpdateMachineMutationVariables
>;

/**
 * __useUpdateMachineMutation__
 *
 * To run a mutation, you first call `useUpdateMachineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMachineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMachineMutation, { data, loading, error }] = useUpdateMachineMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      store: // value for 'store'
 *      allowProductList: // value for 'allowProductList'
 *      modifierGroupOrdering: // value for 'modifierGroupOrdering'
 *   },
 * });
 */
export function useUpdateMachineMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateMachineMutation,
        UpdateMachineMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateMachineMutation,
        UpdateMachineMutationVariables
    >(UpdateMachineDocument, options);
}
export type UpdateMachineMutationHookResult = ReturnType<
    typeof useUpdateMachineMutation
>;
export type UpdateMachineMutationResult =
    Apollo.MutationResult<UpdateMachineMutation>;
export type UpdateMachineMutationOptions = Apollo.BaseMutationOptions<
    UpdateMachineMutation,
    UpdateMachineMutationVariables
>;
export const DeleteMachineDocument = gql`
    mutation DeleteMachine($id: ID!) {
        deleteMachine(id: $id) {
            id
            name
            store
            allowProductList
            modifierGroupOrdering
        }
    }
`;
export type DeleteMachineMutationFn = Apollo.MutationFunction<
    DeleteMachineMutation,
    DeleteMachineMutationVariables
>;

/**
 * __useDeleteMachineMutation__
 *
 * To run a mutation, you first call `useDeleteMachineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMachineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMachineMutation, { data, loading, error }] = useDeleteMachineMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMachineMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteMachineMutation,
        DeleteMachineMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteMachineMutation,
        DeleteMachineMutationVariables
    >(DeleteMachineDocument, options);
}
export type DeleteMachineMutationHookResult = ReturnType<
    typeof useDeleteMachineMutation
>;
export type DeleteMachineMutationResult =
    Apollo.MutationResult<DeleteMachineMutation>;
export type DeleteMachineMutationOptions = Apollo.BaseMutationOptions<
    DeleteMachineMutation,
    DeleteMachineMutationVariables
>;
export const CreateStoreCampaignDocument = gql`
    mutation CreateStoreCampaign($storeId: ID!, $data: CreateCampaignInput!) {
        createCampaign(storeId: $storeId, data: $data) {
            id
        }
    }
`;
export type CreateStoreCampaignMutationFn = Apollo.MutationFunction<
    CreateStoreCampaignMutation,
    CreateStoreCampaignMutationVariables
>;

/**
 * __useCreateStoreCampaignMutation__
 *
 * To run a mutation, you first call `useCreateStoreCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoreCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoreCampaignMutation, { data, loading, error }] = useCreateStoreCampaignMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateStoreCampaignMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateStoreCampaignMutation,
        CreateStoreCampaignMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateStoreCampaignMutation,
        CreateStoreCampaignMutationVariables
    >(CreateStoreCampaignDocument, options);
}
export type CreateStoreCampaignMutationHookResult = ReturnType<
    typeof useCreateStoreCampaignMutation
>;
export type CreateStoreCampaignMutationResult =
    Apollo.MutationResult<CreateStoreCampaignMutation>;
export type CreateStoreCampaignMutationOptions = Apollo.BaseMutationOptions<
    CreateStoreCampaignMutation,
    CreateStoreCampaignMutationVariables
>;
export const CreateShiftByAdminDocument = gql`
    mutation CreateShiftByAdmin(
        $storeId: ID!
        $employeeId: ID!
        $payPeriodId: ID!
        $clockedInAt: Date!
        $clockedOutAt: Date
        $breaks: [BreakInput!]
        $cashTips: Float
        $note: String
    ) {
        createShiftByAdmin(
            storeId: $storeId
            employeeId: $employeeId
            payPeriodId: $payPeriodId
            clockedInAt: $clockedInAt
            clockedOutAt: $clockedOutAt
            breaks: $breaks
            cashTips: $cashTips
            note: $note
        ) {
            id
            employeeId
            payPeriodId
            clockedInAt
            breaks {
                id
                type
                start
                end
                expectedDuration
            }
            clockedOutAt
            cashTips
            note
        }
    }
`;
export type CreateShiftByAdminMutationFn = Apollo.MutationFunction<
    CreateShiftByAdminMutation,
    CreateShiftByAdminMutationVariables
>;

/**
 * __useCreateShiftByAdminMutation__
 *
 * To run a mutation, you first call `useCreateShiftByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShiftByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShiftByAdminMutation, { data, loading, error }] = useCreateShiftByAdminMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      employeeId: // value for 'employeeId'
 *      payPeriodId: // value for 'payPeriodId'
 *      clockedInAt: // value for 'clockedInAt'
 *      clockedOutAt: // value for 'clockedOutAt'
 *      breaks: // value for 'breaks'
 *      cashTips: // value for 'cashTips'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useCreateShiftByAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateShiftByAdminMutation,
        CreateShiftByAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateShiftByAdminMutation,
        CreateShiftByAdminMutationVariables
    >(CreateShiftByAdminDocument, options);
}
export type CreateShiftByAdminMutationHookResult = ReturnType<
    typeof useCreateShiftByAdminMutation
>;
export type CreateShiftByAdminMutationResult =
    Apollo.MutationResult<CreateShiftByAdminMutation>;
export type CreateShiftByAdminMutationOptions = Apollo.BaseMutationOptions<
    CreateShiftByAdminMutation,
    CreateShiftByAdminMutationVariables
>;
export const UpdateShiftByAdminDocument = gql`
    mutation UpdateShiftByAdmin(
        $storeId: ID!
        $shiftId: ID!
        $clockedInAt: Date!
        $clockedOutAt: Date
        $breaks: [BreakInput!]
        $cashTips: Float
        $note: String
    ) {
        updateShiftByAdmin(
            storeId: $storeId
            shiftId: $shiftId
            clockedInAt: $clockedInAt
            clockedOutAt: $clockedOutAt
            breaks: $breaks
            cashTips: $cashTips
            note: $note
        ) {
            id
            employeeId
            payPeriodId
            clockedInAt
            breaks {
                id
                type
                start
                end
                expectedDuration
            }
            clockedOutAt
            cashTips
            note
        }
    }
`;
export type UpdateShiftByAdminMutationFn = Apollo.MutationFunction<
    UpdateShiftByAdminMutation,
    UpdateShiftByAdminMutationVariables
>;

/**
 * __useUpdateShiftByAdminMutation__
 *
 * To run a mutation, you first call `useUpdateShiftByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShiftByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShiftByAdminMutation, { data, loading, error }] = useUpdateShiftByAdminMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      shiftId: // value for 'shiftId'
 *      clockedInAt: // value for 'clockedInAt'
 *      clockedOutAt: // value for 'clockedOutAt'
 *      breaks: // value for 'breaks'
 *      cashTips: // value for 'cashTips'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useUpdateShiftByAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateShiftByAdminMutation,
        UpdateShiftByAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateShiftByAdminMutation,
        UpdateShiftByAdminMutationVariables
    >(UpdateShiftByAdminDocument, options);
}
export type UpdateShiftByAdminMutationHookResult = ReturnType<
    typeof useUpdateShiftByAdminMutation
>;
export type UpdateShiftByAdminMutationResult =
    Apollo.MutationResult<UpdateShiftByAdminMutation>;
export type UpdateShiftByAdminMutationOptions = Apollo.BaseMutationOptions<
    UpdateShiftByAdminMutation,
    UpdateShiftByAdminMutationVariables
>;
export const DeleteShiftByAdminDocument = gql`
    mutation DeleteShiftByAdmin($storeId: ID!, $shiftId: ID!) {
        deleteShiftByAdmin(storeId: $storeId, shiftId: $shiftId) {
            id
        }
    }
`;
export type DeleteShiftByAdminMutationFn = Apollo.MutationFunction<
    DeleteShiftByAdminMutation,
    DeleteShiftByAdminMutationVariables
>;

/**
 * __useDeleteShiftByAdminMutation__
 *
 * To run a mutation, you first call `useDeleteShiftByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShiftByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShiftByAdminMutation, { data, loading, error }] = useDeleteShiftByAdminMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      shiftId: // value for 'shiftId'
 *   },
 * });
 */
export function useDeleteShiftByAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteShiftByAdminMutation,
        DeleteShiftByAdminMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteShiftByAdminMutation,
        DeleteShiftByAdminMutationVariables
    >(DeleteShiftByAdminDocument, options);
}
export type DeleteShiftByAdminMutationHookResult = ReturnType<
    typeof useDeleteShiftByAdminMutation
>;
export type DeleteShiftByAdminMutationResult =
    Apollo.MutationResult<DeleteShiftByAdminMutation>;
export type DeleteShiftByAdminMutationOptions = Apollo.BaseMutationOptions<
    DeleteShiftByAdminMutation,
    DeleteShiftByAdminMutationVariables
>;
export const GetPromotionsDocument = gql`
    query GetPromotions($storeId: ID!) {
        promotions(storeId: $storeId) {
            ...PromotionFields
        }
    }
    ${PromotionFieldsFragmentDoc}
`;

/**
 * __useGetPromotionsQuery__
 *
 * To run a query within a React component, call `useGetPromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromotionsQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetPromotionsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetPromotionsQuery,
        GetPromotionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetPromotionsQuery, GetPromotionsQueryVariables>(
        GetPromotionsDocument,
        options,
    );
}
export function useGetPromotionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetPromotionsQuery,
        GetPromotionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetPromotionsQuery, GetPromotionsQueryVariables>(
        GetPromotionsDocument,
        options,
    );
}
export type GetPromotionsQueryHookResult = ReturnType<
    typeof useGetPromotionsQuery
>;
export type GetPromotionsLazyQueryHookResult = ReturnType<
    typeof useGetPromotionsLazyQuery
>;
export type GetPromotionsQueryResult = Apollo.QueryResult<
    GetPromotionsQuery,
    GetPromotionsQueryVariables
>;
export const CreatePromotionDocument = gql`
    mutation CreatePromotion($storeId: ID!, $input: CreatePromotion!) {
        createPromotion(storeId: $storeId, input: $input) {
            ...PromotionFields
        }
    }
    ${PromotionFieldsFragmentDoc}
`;
export type CreatePromotionMutationFn = Apollo.MutationFunction<
    CreatePromotionMutation,
    CreatePromotionMutationVariables
>;

/**
 * __useCreatePromotionMutation__
 *
 * To run a mutation, you first call `useCreatePromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPromotionMutation, { data, loading, error }] = useCreatePromotionMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePromotionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreatePromotionMutation,
        CreatePromotionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreatePromotionMutation,
        CreatePromotionMutationVariables
    >(CreatePromotionDocument, options);
}
export type CreatePromotionMutationHookResult = ReturnType<
    typeof useCreatePromotionMutation
>;
export type CreatePromotionMutationResult =
    Apollo.MutationResult<CreatePromotionMutation>;
export type CreatePromotionMutationOptions = Apollo.BaseMutationOptions<
    CreatePromotionMutation,
    CreatePromotionMutationVariables
>;
export const UpdatePromotionDocument = gql`
    mutation UpdatePromotion($id: ID!, $input: UpdatePromotion!) {
        updatePromotion(id: $id, input: $input) {
            ...PromotionFields
        }
    }
    ${PromotionFieldsFragmentDoc}
`;
export type UpdatePromotionMutationFn = Apollo.MutationFunction<
    UpdatePromotionMutation,
    UpdatePromotionMutationVariables
>;

/**
 * __useUpdatePromotionMutation__
 *
 * To run a mutation, you first call `useUpdatePromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePromotionMutation, { data, loading, error }] = useUpdatePromotionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePromotionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdatePromotionMutation,
        UpdatePromotionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdatePromotionMutation,
        UpdatePromotionMutationVariables
    >(UpdatePromotionDocument, options);
}
export type UpdatePromotionMutationHookResult = ReturnType<
    typeof useUpdatePromotionMutation
>;
export type UpdatePromotionMutationResult =
    Apollo.MutationResult<UpdatePromotionMutation>;
export type UpdatePromotionMutationOptions = Apollo.BaseMutationOptions<
    UpdatePromotionMutation,
    UpdatePromotionMutationVariables
>;
export const DeletePromotionDocument = gql`
    mutation DeletePromotion($id: ID!) {
        deletePromotion(id: $id)
    }
`;
export type DeletePromotionMutationFn = Apollo.MutationFunction<
    DeletePromotionMutation,
    DeletePromotionMutationVariables
>;

/**
 * __useDeletePromotionMutation__
 *
 * To run a mutation, you first call `useDeletePromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePromotionMutation, { data, loading, error }] = useDeletePromotionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePromotionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeletePromotionMutation,
        DeletePromotionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeletePromotionMutation,
        DeletePromotionMutationVariables
    >(DeletePromotionDocument, options);
}
export type DeletePromotionMutationHookResult = ReturnType<
    typeof useDeletePromotionMutation
>;
export type DeletePromotionMutationResult =
    Apollo.MutationResult<DeletePromotionMutation>;
export type DeletePromotionMutationOptions = Apollo.BaseMutationOptions<
    DeletePromotionMutation,
    DeletePromotionMutationVariables
>;
export const GetStoreAudienceDocument = gql`
    query GetStoreAudience($storeId: ID!) {
        storeAudience(storeId: $storeId) {
            followers
            recentCustomers
            needAttentionCustomers
            newCustomers
            testCustomers
            followersWithIndividualConsent
            recentCustomersWithIndividualConsent
            newCustomersWithIndividualConsent
            needAttentionCustomersWithIndividualConsent
            testCustomersWithIndividualConsent
        }
    }
`;

/**
 * __useGetStoreAudienceQuery__
 *
 * To run a query within a React component, call `useGetStoreAudienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreAudienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreAudienceQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetStoreAudienceQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetStoreAudienceQuery,
        GetStoreAudienceQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetStoreAudienceQuery,
        GetStoreAudienceQueryVariables
    >(GetStoreAudienceDocument, options);
}
export function useGetStoreAudienceLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetStoreAudienceQuery,
        GetStoreAudienceQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetStoreAudienceQuery,
        GetStoreAudienceQueryVariables
    >(GetStoreAudienceDocument, options);
}
export type GetStoreAudienceQueryHookResult = ReturnType<
    typeof useGetStoreAudienceQuery
>;
export type GetStoreAudienceLazyQueryHookResult = ReturnType<
    typeof useGetStoreAudienceLazyQuery
>;
export type GetStoreAudienceQueryResult = Apollo.QueryResult<
    GetStoreAudienceQuery,
    GetStoreAudienceQueryVariables
>;
export const GetServiceAreasDocument = gql`
    query GetServiceAreas($storeId: ID!) {
        serviceAreas(storeId: $storeId) {
            ...ServiceAreaFields
        }
    }
    ${ServiceAreaFieldsFragmentDoc}
`;

/**
 * __useGetServiceAreasQuery__
 *
 * To run a query within a React component, call `useGetServiceAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceAreasQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetServiceAreasQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetServiceAreasQuery,
        GetServiceAreasQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetServiceAreasQuery, GetServiceAreasQueryVariables>(
        GetServiceAreasDocument,
        options,
    );
}
export function useGetServiceAreasLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetServiceAreasQuery,
        GetServiceAreasQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetServiceAreasQuery,
        GetServiceAreasQueryVariables
    >(GetServiceAreasDocument, options);
}
export type GetServiceAreasQueryHookResult = ReturnType<
    typeof useGetServiceAreasQuery
>;
export type GetServiceAreasLazyQueryHookResult = ReturnType<
    typeof useGetServiceAreasLazyQuery
>;
export type GetServiceAreasQueryResult = Apollo.QueryResult<
    GetServiceAreasQuery,
    GetServiceAreasQueryVariables
>;
export const CreateServiceAreaDocument = gql`
    mutation CreateServiceArea($storeId: ID!, $input: CreateServiceArea!) {
        createServiceArea(storeId: $storeId, input: $input) {
            ...ServiceAreaFields
        }
    }
    ${ServiceAreaFieldsFragmentDoc}
`;
export type CreateServiceAreaMutationFn = Apollo.MutationFunction<
    CreateServiceAreaMutation,
    CreateServiceAreaMutationVariables
>;

/**
 * __useCreateServiceAreaMutation__
 *
 * To run a mutation, you first call `useCreateServiceAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceAreaMutation, { data, loading, error }] = useCreateServiceAreaMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateServiceAreaMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateServiceAreaMutation,
        CreateServiceAreaMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateServiceAreaMutation,
        CreateServiceAreaMutationVariables
    >(CreateServiceAreaDocument, options);
}
export type CreateServiceAreaMutationHookResult = ReturnType<
    typeof useCreateServiceAreaMutation
>;
export type CreateServiceAreaMutationResult =
    Apollo.MutationResult<CreateServiceAreaMutation>;
export type CreateServiceAreaMutationOptions = Apollo.BaseMutationOptions<
    CreateServiceAreaMutation,
    CreateServiceAreaMutationVariables
>;
export const UpdateServiceAreaDocument = gql`
    mutation UpdateServiceArea($storeId: ID!, $input: UpdateServiceArea!) {
        updateServiceArea(storeId: $storeId, input: $input) {
            ...ServiceAreaFields
        }
    }
    ${ServiceAreaFieldsFragmentDoc}
`;
export type UpdateServiceAreaMutationFn = Apollo.MutationFunction<
    UpdateServiceAreaMutation,
    UpdateServiceAreaMutationVariables
>;

/**
 * __useUpdateServiceAreaMutation__
 *
 * To run a mutation, you first call `useUpdateServiceAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceAreaMutation, { data, loading, error }] = useUpdateServiceAreaMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateServiceAreaMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateServiceAreaMutation,
        UpdateServiceAreaMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateServiceAreaMutation,
        UpdateServiceAreaMutationVariables
    >(UpdateServiceAreaDocument, options);
}
export type UpdateServiceAreaMutationHookResult = ReturnType<
    typeof useUpdateServiceAreaMutation
>;
export type UpdateServiceAreaMutationResult =
    Apollo.MutationResult<UpdateServiceAreaMutation>;
export type UpdateServiceAreaMutationOptions = Apollo.BaseMutationOptions<
    UpdateServiceAreaMutation,
    UpdateServiceAreaMutationVariables
>;
export const DeleteServiceAreaDocument = gql`
    mutation DeleteServiceArea($storeId: ID!, $id: ID!) {
        deleteServiceArea(storeId: $storeId, serviceAreaId: $id)
    }
`;
export type DeleteServiceAreaMutationFn = Apollo.MutationFunction<
    DeleteServiceAreaMutation,
    DeleteServiceAreaMutationVariables
>;

/**
 * __useDeleteServiceAreaMutation__
 *
 * To run a mutation, you first call `useDeleteServiceAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServiceAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServiceAreaMutation, { data, loading, error }] = useDeleteServiceAreaMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteServiceAreaMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteServiceAreaMutation,
        DeleteServiceAreaMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteServiceAreaMutation,
        DeleteServiceAreaMutationVariables
    >(DeleteServiceAreaDocument, options);
}
export type DeleteServiceAreaMutationHookResult = ReturnType<
    typeof useDeleteServiceAreaMutation
>;
export type DeleteServiceAreaMutationResult =
    Apollo.MutationResult<DeleteServiceAreaMutation>;
export type DeleteServiceAreaMutationOptions = Apollo.BaseMutationOptions<
    DeleteServiceAreaMutation,
    DeleteServiceAreaMutationVariables
>;
export const GetMenusDocument = gql`
    query GetMenus($storeId: ID!) {
        storeMenus(storeId: $storeId) {
            ...StoreMenuFields
        }
    }
    ${StoreMenuFieldsFragmentDoc}
`;

/**
 * __useGetMenusQuery__
 *
 * To run a query within a React component, call `useGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenusQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetMenusQuery(
    baseOptions: Apollo.QueryHookOptions<GetMenusQuery, GetMenusQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetMenusQuery, GetMenusQueryVariables>(
        GetMenusDocument,
        options,
    );
}
export function useGetMenusLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetMenusQuery,
        GetMenusQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetMenusQuery, GetMenusQueryVariables>(
        GetMenusDocument,
        options,
    );
}
export type GetMenusQueryHookResult = ReturnType<typeof useGetMenusQuery>;
export type GetMenusLazyQueryHookResult = ReturnType<
    typeof useGetMenusLazyQuery
>;
export type GetMenusQueryResult = Apollo.QueryResult<
    GetMenusQuery,
    GetMenusQueryVariables
>;
export const CreateMenuDocument = gql`
    mutation CreateMenu($storeId: ID!, $input: StoreMenuInput!) {
        createStoreMenu(storeId: $storeId, input: $input) {
            ...StoreMenuFields
        }
    }
    ${StoreMenuFieldsFragmentDoc}
`;
export type CreateMenuMutationFn = Apollo.MutationFunction<
    CreateMenuMutation,
    CreateMenuMutationVariables
>;

/**
 * __useCreateMenuMutation__
 *
 * To run a mutation, you first call `useCreateMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMenuMutation, { data, loading, error }] = useCreateMenuMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMenuMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateMenuMutation,
        CreateMenuMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateMenuMutation, CreateMenuMutationVariables>(
        CreateMenuDocument,
        options,
    );
}
export type CreateMenuMutationHookResult = ReturnType<
    typeof useCreateMenuMutation
>;
export type CreateMenuMutationResult =
    Apollo.MutationResult<CreateMenuMutation>;
export type CreateMenuMutationOptions = Apollo.BaseMutationOptions<
    CreateMenuMutation,
    CreateMenuMutationVariables
>;
export const UpdateMenuDocument = gql`
    mutation UpdateMenu($id: ID!, $input: StoreMenuInput!) {
        updateStoreMenu(id: $id, input: $input) {
            ...StoreMenuFields
        }
    }
    ${StoreMenuFieldsFragmentDoc}
`;
export type UpdateMenuMutationFn = Apollo.MutationFunction<
    UpdateMenuMutation,
    UpdateMenuMutationVariables
>;

/**
 * __useUpdateMenuMutation__
 *
 * To run a mutation, you first call `useUpdateMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMenuMutation, { data, loading, error }] = useUpdateMenuMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMenuMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateMenuMutation,
        UpdateMenuMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateMenuMutation, UpdateMenuMutationVariables>(
        UpdateMenuDocument,
        options,
    );
}
export type UpdateMenuMutationHookResult = ReturnType<
    typeof useUpdateMenuMutation
>;
export type UpdateMenuMutationResult =
    Apollo.MutationResult<UpdateMenuMutation>;
export type UpdateMenuMutationOptions = Apollo.BaseMutationOptions<
    UpdateMenuMutation,
    UpdateMenuMutationVariables
>;
export const DeleteMenuDocument = gql`
    mutation DeleteMenu($id: ID!) {
        deleteStoreMenu(id: $id)
    }
`;
export type DeleteMenuMutationFn = Apollo.MutationFunction<
    DeleteMenuMutation,
    DeleteMenuMutationVariables
>;

/**
 * __useDeleteMenuMutation__
 *
 * To run a mutation, you first call `useDeleteMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMenuMutation, { data, loading, error }] = useDeleteMenuMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMenuMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteMenuMutation,
        DeleteMenuMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteMenuMutation, DeleteMenuMutationVariables>(
        DeleteMenuDocument,
        options,
    );
}
export type DeleteMenuMutationHookResult = ReturnType<
    typeof useDeleteMenuMutation
>;
export type DeleteMenuMutationResult =
    Apollo.MutationResult<DeleteMenuMutation>;
export type DeleteMenuMutationOptions = Apollo.BaseMutationOptions<
    DeleteMenuMutation,
    DeleteMenuMutationVariables
>;
export const GetTablesDocument = gql`
    query GetTables($storeId: ID!) {
        storeTables(storeId: $storeId) {
            id
            name
            numSeats
        }
    }
`;

/**
 * __useGetTablesQuery__
 *
 * To run a query within a React component, call `useGetTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTablesQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetTablesQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetTablesQuery,
        GetTablesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetTablesQuery, GetTablesQueryVariables>(
        GetTablesDocument,
        options,
    );
}
export function useGetTablesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetTablesQuery,
        GetTablesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetTablesQuery, GetTablesQueryVariables>(
        GetTablesDocument,
        options,
    );
}
export type GetTablesQueryHookResult = ReturnType<typeof useGetTablesQuery>;
export type GetTablesLazyQueryHookResult = ReturnType<
    typeof useGetTablesLazyQuery
>;
export type GetTablesQueryResult = Apollo.QueryResult<
    GetTablesQuery,
    GetTablesQueryVariables
>;
export const CreateTableDocument = gql`
    mutation CreateTable($storeId: ID!, $input: CreateTableInput!) {
        createStoreTable(storeId: $storeId, input: $input) {
            id
        }
    }
`;
export type CreateTableMutationFn = Apollo.MutationFunction<
    CreateTableMutation,
    CreateTableMutationVariables
>;

/**
 * __useCreateTableMutation__
 *
 * To run a mutation, you first call `useCreateTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTableMutation, { data, loading, error }] = useCreateTableMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTableMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateTableMutation,
        CreateTableMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateTableMutation,
        CreateTableMutationVariables
    >(CreateTableDocument, options);
}
export type CreateTableMutationHookResult = ReturnType<
    typeof useCreateTableMutation
>;
export type CreateTableMutationResult =
    Apollo.MutationResult<CreateTableMutation>;
export type CreateTableMutationOptions = Apollo.BaseMutationOptions<
    CreateTableMutation,
    CreateTableMutationVariables
>;
export const UpdateTableDocument = gql`
    mutation UpdateTable($tableId: ID!, $input: UpdateTableInput!) {
        updateStoreTable(tableId: $tableId, input: $input) {
            id
        }
    }
`;
export type UpdateTableMutationFn = Apollo.MutationFunction<
    UpdateTableMutation,
    UpdateTableMutationVariables
>;

/**
 * __useUpdateTableMutation__
 *
 * To run a mutation, you first call `useUpdateTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTableMutation, { data, loading, error }] = useUpdateTableMutation({
 *   variables: {
 *      tableId: // value for 'tableId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTableMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateTableMutation,
        UpdateTableMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateTableMutation,
        UpdateTableMutationVariables
    >(UpdateTableDocument, options);
}
export type UpdateTableMutationHookResult = ReturnType<
    typeof useUpdateTableMutation
>;
export type UpdateTableMutationResult =
    Apollo.MutationResult<UpdateTableMutation>;
export type UpdateTableMutationOptions = Apollo.BaseMutationOptions<
    UpdateTableMutation,
    UpdateTableMutationVariables
>;
export const DeleteTableDocument = gql`
    mutation DeleteTable($storeId: ID!, $tableId: ID!) {
        deleteStoreTable(storeId: $storeId, tableId: $tableId)
    }
`;
export type DeleteTableMutationFn = Apollo.MutationFunction<
    DeleteTableMutation,
    DeleteTableMutationVariables
>;

/**
 * __useDeleteTableMutation__
 *
 * To run a mutation, you first call `useDeleteTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTableMutation, { data, loading, error }] = useDeleteTableMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      tableId: // value for 'tableId'
 *   },
 * });
 */
export function useDeleteTableMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteTableMutation,
        DeleteTableMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        DeleteTableMutation,
        DeleteTableMutationVariables
    >(DeleteTableDocument, options);
}
export type DeleteTableMutationHookResult = ReturnType<
    typeof useDeleteTableMutation
>;
export type DeleteTableMutationResult =
    Apollo.MutationResult<DeleteTableMutation>;
export type DeleteTableMutationOptions = Apollo.BaseMutationOptions<
    DeleteTableMutation,
    DeleteTableMutationVariables
>;
