import * as React from "react";
import { Widget } from "@typeform/embed-react";

import {
    Sheet,
    SheetContent,
} from "src/@/components/ui/sheet";

import { useReferral } from "./use-referral";

const TYPEFORM_FORM_ID = "NnHyTmYf"

export function Referral() {
    const { isOpen, onClose } = useReferral();

    return (
        <Sheet open={isOpen} onOpenChange={onClose}>
            <SheetContent 
                side="right" 
                className="w-full p-0 sm:max-w-[600px]"
            >
                <Widget
                    id={TYPEFORM_FORM_ID}
                    hidden={{
                        "utm_source": "dashboard"
                    }}
                    className="h-full"
                    onClose={onClose}
                />
            </SheetContent>
        </Sheet>
    );
} 