import { createContext, useState, ReactNode } from "react";

interface ReferralContextType {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
}

export const ReferralContext = createContext<ReferralContextType | undefined>(
    undefined
);

export function ReferralProvider({ children }: { children: ReactNode }) {
    const [isOpen, setIsOpen] = useState(false);

    const onOpen = () => setIsOpen(true);
    const onClose = () => setIsOpen(false);

    return (
        <ReferralContext.Provider value={{ isOpen, onOpen, onClose }}>
            {children}
        </ReferralContext.Provider>
    );
} 