import { useMemo } from "react";
import { useOrders } from "../../orders-context";
import { SalesSummary } from "src/@/components/ui/sales-summary";
import { formatPurchaseProvider } from "#utils/helpers";
import { ExperimentalSalesSummary } from "./experimental-summary";
import { EndOfDayReport, EndOfDayReportButton } from "./end-of-day-report";
import { DateTime } from "luxon";
import { calculateCompletionTimeInMinutes } from "../../lib";
import { Button } from "src/@/components/ui/button";
import { ArrowLeft } from "lucide-react";

export const OrderSalesSummary = () => {
    const {
        startDate,
        endDate,
        purchaseReportTotals: { total: report, isLoading: reportLoading },
        purchaseReportRows: { data: rows, isLoading: rowsLoading },
        laborData: { data: laborData, isLoading: laborLoading },
        showEndOfDayReport,
        setShowEndOfDayReport,
    } = useOrders();

    // Convert dates directly using Luxon
    const { startLuxon, endLuxon } = useMemo(
        () => ({
            startLuxon: DateTime.fromISO(startDate.toISOString()),
            endLuxon: DateTime.fromISO(endDate.toISOString()),
        }),
        [startDate, endDate],
    );

    // Process experimental summary data
    const experimentalData = useMemo(() => {
        if (!rows?.length) {
            return {
                voidData: { orders: 0, items: 0, totalSales: 0 },
                guestData: { totalGuests: 0, averageTicketPerGuest: 0 },
                employeeData: [],
                laborData: {
                    totalLaborCost: 0,
                    laborCostPercentage: 0,
                    salesPerLaborHour: 0,
                    totalLaborHours: 0,
                },
                completionTimeData: { averageCompletionTime: 0 },
            };
        }

        // Process void data
        const voidedOrders = rows.filter((order) =>
            order.purchase?.auditEvents?.some(
                (event) => event.action === "PURCHASE_VOIDED",
            ),
        );

        const voidData = {
            orders: voidedOrders.length,
            items: voidedOrders.reduce(
                (sum, order) => sum + (order.items?.length || 0),
                0,
            ),
            totalSales: voidedOrders.reduce(
                (sum, order) =>
                    sum +
                    (order.items?.reduce(
                        (itemSum, item) => itemSum + (item.totalPrice || 0),
                        0,
                    ) || 0),
                0,
            ),
        };

        // Process guest data
        const ordersWithGuests = rows.filter(
            (order) => (order.purchase?.numberOfGuests || 0) > 0,
        );
        const totalGuests = ordersWithGuests.reduce(
            (sum, order) => sum + (order.purchase?.numberOfGuests || 0),
            0,
        );
        const guestSales = ordersWithGuests.reduce(
            (sum, order) => sum + (order.netSales || 0),
            0,
        );

        const guestData = {
            totalGuests,
            averageTicketPerGuest:
                totalGuests > 0 ? guestSales / totalGuests : 0,
        };

        // Process employee data
        const employeeMap = new Map<
            string,
            {
                name: string;
                voids: number;
                discounts: number;
                refunds: number;
                voidSales: number;
                discountSales: number;
                refundSales: number;
                sales: number;
                tips: number;
            }
        >();

        rows.forEach((order) => {
            const employeeId = order.purchase?.employeeId;
            if (!employeeId) return;

            const employeeName = order.purchase?.employeeName || "Unknown";

            const currentEmployee = employeeMap.get(employeeId) || {
                name: employeeName,
                voids: 0,
                discounts: 0,
                refunds: 0,
                voidSales: 0,
                discountSales: 0,
                refundSales: 0,
                sales: 0,
                tips: 0,
            };

            // Check for voids
            if (
                order.purchase?.auditEvents?.some(
                    (event) => event.action === "PURCHASE_VOIDED",
                )
            ) {
                currentEmployee.voids++;
                currentEmployee.voidSales +=
                    order.items?.reduce(
                        (sum, item) => sum + (item.totalPrice || 0),
                        0,
                    ) || 0;
            }

            // Check for discounts
            // @ts-ignore TODO: fix this @steven and David
            const discountCount = order.discountsAndComps > 0 ? 1 : 0;
            // @ts-ignore
            const discountAmount = order.discountsAndComps || 0;

            if (discountCount > 0) {
                currentEmployee.discounts += discountCount;
                currentEmployee.discountSales += discountAmount;
            }

            // Check for refunds
            const refundCount = order.refundedAmount > 0 ? 1 : 0;
            const refundAmount = order.refundedAmount || 0;

            if (refundCount > 0) {
                currentEmployee.refunds += refundCount;
                currentEmployee.refundSales += refundAmount;
            }

            // Add sales and tips
            currentEmployee.sales += order.netSales || 0;
            currentEmployee.tips += order.tip || 0;

            employeeMap.set(employeeId, currentEmployee);
        });

        // Process completion time data
        // TODO: @Steven — perhpas it is best to store this in the database instead of calculation it
        // on the frontend
        const completionTimes = rows
            .filter((order) => order.purchase?.status)
            .map((order) =>
                calculateCompletionTimeInMinutes(order.purchase?.status),
            )
            .filter((time): time is number => time !== null);

        const averageTime =
            completionTimes.length > 0
                ? completionTimes.reduce((sum, time) => sum + time, 0) /
                  completionTimes.length
                : 0;

        return {
            voidData,
            guestData,
            employeeData: Array.from(employeeMap.values()),
            laborData: !laborLoading
                ? laborData
                : {
                      totalLaborCost: 0,
                      laborCostPercentage: 0,
                      salesPerLaborHour: 0,
                      totalLaborHours: 0,
                  },
            completionTimeData: { averageCompletionTime: averageTime },
        };
    }, [rows, laborData, laborLoading]);

    const { topItems, categoryData, providerData } = useMemo(() => {
        if (!rows?.length) {
            return { topItems: [], categoryData: [], providerData: [] };
        }

        const itemMap = new Map<string, { orders: number; revenue: number }>();
        const categoryMap = new Map<
            string,
            { orders: number; revenue: number }
        >();
        const providerMap = new Map<
            string,
            { orders: number; revenue: number }
        >();

        // TODO: This is a temporary implementation to get the top items and category data
        // Loop through each order and its items
        rows.forEach((order) => {
            // Add provider aggregation using source instead of provider
            const providerName = formatPurchaseProvider(order.purchase);
            const existingProvider = providerMap.get(providerName) || {
                orders: 0,
                revenue: 0,
            };

            // Calculate total order revenue
            const orderRevenue = order.items.reduce(
                (sum, item) => sum + (item.totalPrice || 0),
                0,
            );

            // Update provider data
            providerMap.set(providerName, {
                orders: existingProvider.orders + 1,
                revenue: existingProvider.revenue + orderRevenue,
            });

            // Existing item and category aggregation
            order?.items.forEach((item) => {
                // Aggregate item data
                const existingItem = itemMap.get(item.name) || {
                    orders: 0,
                    revenue: 0,
                };
                const itemRevenue = item.totalPrice || 0;

                itemMap.set(item.name, {
                    orders: existingItem.orders + 1,
                    revenue: existingItem.revenue + itemRevenue,
                });

                // Aggregate category data
                const categoryName = item.category || "Uncategorized";
                const existingCategory = categoryMap.get(categoryName) || {
                    orders: 0,
                    revenue: 0,
                };

                categoryMap.set(categoryName, {
                    orders: existingCategory.orders + 1,
                    revenue: existingCategory.revenue + itemRevenue,
                });
            });
        });

        // Convert maps to sorted arrays
        const topItems = Array.from(itemMap.entries())
            .map(([name, data]) => ({
                name,
                count: data.orders,
                value: data.revenue,
            }))
            .sort((a, b) => b.value - a.value);

        const categoryData = Array.from(categoryMap.entries())
            .map(([name, data]) => ({
                name,
                count: data.orders,
                value: data.revenue,
            }))
            .sort((a, b) => b.value - a.value);

        const providerData = Array.from(providerMap.entries())
            .map(([name, data]) => ({
                name,
                count: data.orders,
                value: data.revenue,
            }))
            .sort((a, b) => b.value - a.value);

        return { topItems, categoryData, providerData };
    }, [rows]);

    // Create enhanced report with additional calculated fields
    const enhancedReport = useMemo(() => {
        if (!report) return undefined;

        return {
            ...report,
            cardCollected: report.netCardPayments,
            expectedCashCollected: report.netCashPayments,
            refundedAmount: report.grossSalesRefunds,
            amountDiscounted: report.grossSalesDiscounts,
        };
    }, [report]);

    const loadingStates = {
        sales: reportLoading,
        items: rowsLoading,
        categories: rowsLoading,
        providers: rowsLoading,
    };

    const getDateString = () => {
        if (startLuxon.year !== endLuxon.year) {
            return `${startLuxon.toFormat("LLL d, yyyy")} – ${endLuxon.toFormat("LLL d, yyyy")}`;
        } else if (startLuxon.hasSame(endLuxon, "day")) {
            return endLuxon.toFormat("LLLL d, yyyy");
        }
        return `${startLuxon.toFormat("LLL dd")} – ${endLuxon.toFormat("LLL d, yyyy")}`;
    };

    return (
        <div className="space-y-8">
            {showEndOfDayReport ? (
                <>
                    <div className="flex items-center space-x-2">
                        <Button
                            variant="outline"
                            onClick={() => setShowEndOfDayReport(false)}
                        >
                            <ArrowLeft className="mr-2 h-4 w-4" />
                            Back to Orders
                        </Button>
                    </div>
                    <EndOfDayReport />
                </>
            ) : (
                <>
                    <SalesSummary
                        dateString={getDateString()}
                        report={enhancedReport}
                        topItems={topItems}
                        categoryData={categoryData}
                        providerData={providerData}
                        isLoading={loadingStates}
                    />
                    <ExperimentalSalesSummary {...experimentalData} />
                    <div className="flex justify-start">
                        <EndOfDayReportButton />
                    </div>
                </>
            )}
        </div>
    );
};
