import { DateTime } from "luxon";
import { useOrders } from "../../orders-context";
import { DataTable } from "src/@/components/ui/data-table";
import { createOrderColumns } from "./columns";
import { OrdersDownloadButton } from "../download-button";
import { Skeleton } from "src/@/components/ui/skeleton";
import { OrderField } from "#orders/lib";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getUserIsSnackpassEmployee } from "src/redux/selectors";

export const OrdersTable = () => {
    const {
        purchaseReportRows: { data: rows, isLoading },
    } = useOrders();
    const isSnackTeam = useSelector(getUserIsSnackpassEmployee);
    const updatedTime = DateTime.now().toFormat("h:mm a");
    const toolbarOptions = {
        showColumnFilter: true,
        search: {
            global: true as const,
            placeholder: "Search by order #, customer name",
        },
        customComponent: <OrdersDownloadButton />,
    } as const;

    const columns = useMemo(
        () => createOrderColumns(isSnackTeam),
        [isSnackTeam],
    );

    if (isLoading) {
        return (
            <>
                <Skeleton className="my-4 h-8 w-64" />
                <Skeleton className="h-52 w-full" />
            </>
        );
    }

    return (
        <div className="space-y-4">
            <div className="flex items-center justify-between">
                <h4 className="text-lg">Orders</h4>
            </div>
            <DataTable
                toolbar={toolbarOptions}
                columns={columns}
                data={rows ?? []}
                showPagination={true}
                tableId="orders-table"
                hiddenColumns={[
                    OrderField.CustomSurcharge,
                    OrderField.CustomDiscounts,
                    OrderField.TaxesRemitted,
                    OrderField.PaymentMethod,
                    OrderField.Items,
                    OrderField.UpCharge,
                    OrderField.DeliveryCharge,
                    OrderField.ThirdPartyDeliveryCharge,
                    OrderField.NetSales,
                    OrderField.GrossSales,
                    OrderField.RefundedBy,
                    OrderField.Promotions,
                    OrderField.Notes,
                    OrderField.UpChargedBy,
                    OrderField.Channel,
                    OrderField.Provider,
                    OrderField.ProcessingFee,
                    OrderField.ThirdPartyPayout,
                    OrderField.SnackpassFee,
                    OrderField.ID,
                    OrderField.PayoutID,
                    OrderField.CashAcceptedBy,
                    OrderField.ThirdPartyFees,
                    OrderField.ThirdPartyTaxes,
                    OrderField.CreatedBy,
                ]}
                footerElement={
                    <span className="text-xs text-muted-foreground">
                        Updated {updatedTime}
                    </span>
                }
            />
        </div>
    );
};
