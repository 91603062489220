import { AxiosResponse } from "axios";
import { ErrorWithCause } from "src/utils/errors";
import {
    SalesDailyAggregateReport,
    SalesHourlyAggregateReport,
    SummaryTilesData,
    TableSummaryDataRow,
} from "#reports-sales-comparison/types";
import { client } from "../client";
import { ProductReport } from "src/api/rest/api-types";
import Customers from "src/api/rest/modules/customers";
import { SalesMetric } from "#tip-reporting/types";
export const REPORTS_REQUEST_TIMEOUT = 120 * 1000;

export class Reports {
    static customers = Customers;

    static async getProductRecords(
        productId: string,
        params: {
            startDate: Date;
            endDate: Date;
            groupByDay: boolean;
        },
    ): Promise<AxiosResponse<ProductReport>> {
        return client
            .get(`/reports/product/${productId}`, { params })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getProductRecords: GET to /reports/product/${productId} failed`,
                    cause,
                );
            });
    }

    static async getEarnings(
        storeId: string,
        params: {
            startDate: Date;
        },
    ) {
        return client
            .get(`/reports/store/${storeId}/earnings`, { params })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getEarnings: GET to /reports/store/${storeId}/earnings failed`,
                    cause,
                );
            });
    }

    static async getGiftCardPurchaseStats(params: {
        storeIds: string;
        startDate?: string;
        endDate?: string;
    }): Promise<AxiosResponse> {
        return client
            .get(`/rdb-reports/gift-card/purchase-stats`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getSalesSummary: GET to /rdb-reports/gift-card/purchase-stats failed`,
                    cause,
                );
            });
    }

    static async getGiftCardBalances(params: {
        storeIds: string;
        startDate?: string;
        endDate?: string;
        cursorDate?: string;
        cursorId?: string;
        limit?: number;
    }) {
        return client
            .get(`/rdb-reports/gift-card/balances`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getGiftCardBalances: GET to /rdb-reports/gift-card/balances failed`,
                    cause,
                );
            });
    }

    static async getGiftCardTransactions(params: {
        storeIds: string;
        startDate?: string;
        endDate?: string;
        cursorDate?: string;
        cursorId?: string;
        limit?: number;
    }) {
        return client
            .get(`/rdb-reports/gift-card/transactions`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getGiftCardTransactions: GET to /rdb-reports/gift-card/transactions failed`,
                    cause,
                );
            });
    }

    static async getSalesSummary(params: {
        storeId: string;
        startDate: string;
        endDate: string;
        comparedToStartDate: string;
        comparedToEndDate: string;
        timezone: string;
        storeIds?: string;
        fulfillment?: string;
        source?: string;
        channel?: string;
    }): Promise<AxiosResponse<{ salesSummary: SummaryTilesData }>> {
        return client
            .get(`/rdb-reports/sales/summary`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getSalesSummary: GET to /rdb-reports/sales/summary failed`,
                    cause,
                );
            });
    }

    static async getSalesReport(params: {
        storeId: string;
        startDate: string;
        endDate: string;
        comparedToStartDate?: string;
        comparedToEndDate?: string;
        timezone: string;
        storeIds?: string;
        fulfillment?: string;
        source?: string;
        channel?: string;
        paymentMethods?: string;
    }): Promise<
        AxiosResponse<{
            salesReport: TableSummaryDataRow[] | undefined;
            salesReportComparedTo: TableSummaryDataRow[] | undefined;
            salesHourlyAggregateReport:
                | SalesHourlyAggregateReport[]
                | undefined;
            salesHourlyAggregateReportComparedTo:
                | SalesHourlyAggregateReport[]
                | undefined;
            salesDailyAggregateReport: SalesDailyAggregateReport[] | undefined;
            salesDailyAggregateReportComparedTo:
                | SalesDailyAggregateReport[]
                | undefined;
        }>
    > {
        return client
            .get(`/rdb-reports/sales/report`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getSalesReport: GET to /rdb-reports/sales/report failed`,
                    cause,
                );
            });
    }

    static async getSalesChannelsReport(params: {
        storeId: string;
        startDate: string;
        endDate: string;
        timezone: string;
        fulfillment?: string;
        source?: string;
        channel?: string;
    }) {
        return client
            .get(`/rdb-reports/sales/channel-report`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getSalesChannelsReport: GET to /rdb-reports/sales/channel-report failed`,
                    cause,
                );
            });
    }

    static async getMenuItemInsights(params: {
        storeId: string;
        startDate: string;
        endDate: string;
        timezone: string;
        fulfillment?: string;
        source?: string;
        channel?: string;
        paymentMethod?: string;
    }) {
        return client
            .get(`/rdb-reports/menu-insights/items`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getMenuItemInsights: GET to /rdb-reports/menu-insights/items failed`,
                    cause,
                );
            });
    }

    static async getMenuItemModifierInsights(params: {
        storeId: string;
        productId: string;
        startDate: string;
        endDate: string;
        timezone: string;
        fulfillment?: string;
        source?: string;
        channel?: string;
    }) {
        return client
            .get(`/rdb-reports/menu-insights/items/modifiers`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getMenuItemModifierInsights: GET to /rdb-reports/menu-insights/items/modifiers failed`,
                    cause,
                );
            });
    }

    static async getSalesChannels(params: {
        storeId: string;
        storeIds?: string;
    }) {
        return client
            .get(`/rdb-reports/sales/channels`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getSalesChannels: GET to /rdb-reports/sales/channels failed`,
                    cause,
                );
            });
    }

    static async getReportsFreshness(params: {
        storeId: string;
        type: string;
    }) {
        return client
            .get(`/rdb-reports/freshness`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getReportsFreshness: GET to /rdb-reports/freshness failed`,
                    cause,
                );
            });
    }

    static async getPromotionsReport(params: {
        storeId: string;
        startDate?: string;
        endDate: string;
        source: string;
    }) {
        return client
            .get(`/rdb-reports/promotion/report`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getPromotionsReport: GET to /rdb-reports/promotion/report failed`,
                    cause,
                );
            });
    }

    static async getLocationSalesReport(params: {
        storeIds: string;
        startDate: string;
        endDate: string;
        fulfillment?: string;
        source?: string;
        channel?: string;
    }) {
        return client
            .get(`/rdb-reports/location/sales/report`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getLocationSalesReport: GET to /rdb-reports/location/sales/report failed`,
                    cause,
                );
            });
    }

    static async getLocationMenuItemReport(params: {
        storeIds: string;
        startDate: string;
        endDate: string;
        fulfillment?: string;
        source?: string;
        channel?: string;
    }) {
        return client
            .get(`/rdb-reports/location/menu/report`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getLocationMenuItemReport: GET to /rdb-reports/location/menu/report failed`,
                    cause,
                );
            });
    }

    static async getSalesCohortReport(params: {
        storeId: string;
        startDate: string;
        endDate: string;
        comparedToStartDate?: string;
        comparedToEndDate?: string;
        timezone: string;
        channel: string;
        source: string;
        fulfillment: string;
    }) {
        return client
            .get(`/rdb-reports/sales/summary-by-cohort`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getSalesCohortReport: GET to /rdb-reports/sales/summary-by-cohort failed`,
                    cause,
                );
            });
    }

    static async getSalesByHour(params: {
        storeId: string;
        startDate: string;
        endDate: string;
        timezone: string;
        channel: string;
        source: string;
        fulfillment: string;
    }): Promise<
        AxiosResponse<{
            salesReport: Array<{
                actualPayout: SalesMetric;
                customerToSnackpassFees: SalesMetric;
                expectedCash: SalesMetric;
                expectedPayout: SalesMetric;
                giftCardPurchases: SalesMetric;
                items: SalesMetric;
                netSales: SalesMetric;
                orders: SalesMetric;
                processingFees: SalesMetric;
                purchaseDiscount: SalesMetric;
                refundTotal: SalesMetric;
                snackpassFees: SalesMetric;
                storeToCustomerFeeTotal: SalesMetric;
                taxesRemittedForSnackpass: SalesMetric;
                taxesRemittedForStore: SalesMetric;
                taxesSnackpassOwes: SalesMetric;
                taxesYouOwe: SalesMetric;
                tips: SalesMetric;
                date?: string;
            }>;
        }>
    > {
        return client
            .get(`/rdb-reports/sales/sales-by-hour`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getSalesByHour: GET to /rdb-reports/sales/sales-by-hour failed`,
                    cause,
                );
            });
    }

    static async getAmbassadors(params: {
        storeId: string;
        startDate?: string;
        endDate?: string;
        promotionName?: string[];
        numRows?: number;
    }): Promise<AxiosResponse> {
        return client
            .get(`/rdb-reports/customers/ambassadors`, {
                params,
                timeout: REPORTS_REQUEST_TIMEOUT,
            })
            .catch((cause) => {
                throw new ErrorWithCause(
                    `api.Reports.getAmbassadors: GET to /rdb-reports/customers/ambassadors failed`,
                    cause,
                );
            });
    }
}
