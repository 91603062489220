import * as React from "react";

import {
    Sidebar,
    SidebarContent,
    SidebarFooter,
    SidebarHeader,
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
} from "src/@/components/ui/sidebar";
import { useCreateSidebar } from "./hooks/use-create-sidebar";
import { StoreCombobox } from "#store-selector/store-combobox";
import { SearchCommandBarProvider } from "#search-command-bar/search-command-bar-provider";
import { SearchCommandBar } from "#search-command-bar";
import { Separator } from "@radix-ui/react-separator";
import { useLoadActiveStoreNumUnreads } from "#navigation/sidebar/hooks/use-load-num-reads";
import { ReferralProvider } from "#referral/referral-provider";
import { Referral } from "#referral";
import { NavUser } from "./components/nav-user";
import { Section } from "./components/section";

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
    const { sections } = useCreateSidebar();

    // todo - would like to move this to initial hooks
    // but there is conflict with pubnub
    useLoadActiveStoreNumUnreads();

    return (
        <SearchCommandBarProvider>
            <ReferralProvider>
                <SearchCommandBar />
                <Referral />
                <Sidebar variant="inset" {...props}>
                    <SidebarHeader>
                        <SidebarMenu>
                            <SidebarMenuItem>
                                <SidebarMenuButton size="lg" asChild>
                                    <div className="flex w-full cursor-pointer select-none items-center data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground">
                                        <StoreCombobox />
                                    </div>
                                </SidebarMenuButton>
                            </SidebarMenuItem>
                        </SidebarMenu>
                    </SidebarHeader>
                    <SidebarContent className="mb-2 gap-1 [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden">
                        {sections.map((section) => (
                            <Section key={section.key} section={section} />
                        ))}
                    </SidebarContent>
                    <SidebarFooter>
                        <NavUser />
                        <div className="mb-1 space-y-2 px-2">
                            <Separator
                                orientation="horizontal"
                                className="mb-3 h-[1px] w-full bg-border"
                            />
                            <img
                                className="opacity-50 dark:invert"
                                src="/snackpass-logo.svg"
                                alt="Snackpass"
                                width={100}
                                height={38}
                            />
                        </div>
                    </SidebarFooter>
                </Sidebar>
            </ReferralProvider>
        </SearchCommandBarProvider>
    );
}
