import { useMemo } from "react";
import { ChevronDown, Info } from "lucide-react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "src/@/components/ui/table";
import { formatCurrency } from "src/@/lib/format-currency";
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from "src/@/components/ui/collapsible";
import { Button } from "src/@/components/ui/button";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "src/@/components/ui/tooltip";

type ExperimentalSummaryProps = {
    voidData?: {
        orders: number;
        items: number;
        totalSales: number;
    };
    guestData?: {
        totalGuests: number;
        averageTicketPerGuest: number;
    };
    employeeData?: Array<{
        name: string;
        voids: number;
        discounts: number;
        refunds: number;
        voidSales: number;
        discountSales: number;
        refundSales: number;
        sales: number;
        tips: number;
    }>;
    laborData?: {
        totalLaborCost: number;
        laborCostPercentage: number;
        salesPerLaborHour: number;
        totalLaborHours: number;
    };
    completionTimeData?: {
        averageCompletionTime: number;
    };
};

const CollapsibleSection = ({
    title,
    children,
    tooltip,
}: {
    title: string;
    children: React.ReactNode;
    tooltip?: string;
}) => {
    return (
        <Collapsible
            className="w-full rounded-md border h-fit"
            defaultOpen={false}
        >
            <CollapsibleTrigger asChild>
                <Button
                    variant="ghost"
                    className="w-full justify-between rounded-none border-b h-10 px-3"
                >
                    <span className="text-sm font-medium text-left flex items-center gap-1">
                        {title}
                        {tooltip && (
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger>
                                        <Info className="h-3 w-3" />
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <p className="max-w-xs">{tooltip}</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        )}
                    </span>
                    <ChevronDown className="h-4 w-4 opacity-50" />
                </Button>
            </CollapsibleTrigger>
            <CollapsibleContent>
                <Table>
                    <TableBody>{children}</TableBody>
                </Table>
            </CollapsibleContent>
        </Collapsible>
    );
};

export const ExperimentalSalesSummary = ({
    voidData = { orders: 0, items: 0, totalSales: 0 },
    guestData = { totalGuests: 0, averageTicketPerGuest: 0 },
    employeeData = [],
    laborData = {
        totalLaborCost: 0,
        laborCostPercentage: 0,
        salesPerLaborHour: 0,
        totalLaborHours: 0,
    },
    completionTimeData = { averageCompletionTime: 0 },
}: ExperimentalSummaryProps) => {
    // Calculate employee totals
    const employeeTotals = useMemo(() => {
        return employeeData.reduce(
            (acc, employee) => ({
                voids: acc.voids + employee.voids,
                voidSales: acc.voidSales + employee.voidSales,
                discounts: acc.discounts + employee.discounts,
                discountSales: acc.discountSales + employee.discountSales,
                refunds: acc.refunds + employee.refunds,
                refundSales: acc.refundSales + employee.refundSales,
                sales: acc.sales + employee.sales,
                tips: acc.tips + employee.tips,
            }),
            {
                voids: 0,
                voidSales: 0,
                discounts: 0,
                discountSales: 0,
                refunds: 0,
                refundSales: 0,
                sales: 0,
                tips: 0,
            },
        );
    }, [employeeData]);

    return (
        <div className="space-y-4">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full auto-rows-min">
                {/* Completion Time Section */}
                <CollapsibleSection title="Completion Time">
                    <>
                        <TableRow>
                            <TableCell>Average Completion Time</TableCell>
                            <TableCell className="text-right">
                                {completionTimeData.averageCompletionTime.toFixed(
                                    1,
                                )}{" "}
                                min
                            </TableCell>
                        </TableRow>
                    </>
                </CollapsibleSection>

                {/* Employees Section */}
                <CollapsibleSection
                    title="Employees"
                    tooltip="This data is available if you are on register beta. Please contact support to set up."
                >
                    <>
                        <TableRow>
                            <TableHead>Name</TableHead>
                            <TableHead className="text-right">Sales</TableHead>
                            <TableHead className="text-right">Tips</TableHead>
                            <TableHead className="text-right">Voids</TableHead>
                            <TableHead className="text-right">
                                Discounts
                            </TableHead>
                            <TableHead className="text-right">
                                Refunds
                            </TableHead>
                        </TableRow>
                        {employeeData.length > 0 ? (
                            <>
                                {employeeData.map((employee) => (
                                    <TableRow key={employee.name}>
                                        <TableCell>{employee.name}</TableCell>
                                        <TableCell className="text-right">
                                            {formatCurrency(employee.sales)}
                                        </TableCell>
                                        <TableCell className="text-right">
                                            {formatCurrency(employee.tips)}
                                        </TableCell>
                                        <TableCell className="text-right">
                                            <div className="flex flex-col">
                                                <span>{employee.voids}</span>
                                                <span className="text-muted-foreground text-sm">
                                                    {formatCurrency(
                                                        employee.voidSales,
                                                    )}
                                                </span>
                                            </div>
                                        </TableCell>
                                        <TableCell className="text-right">
                                            <div className="flex flex-col">
                                                <span>
                                                    {employee.discounts}
                                                </span>
                                                <span className="text-muted-foreground text-sm">
                                                    {formatCurrency(
                                                        employee.discountSales,
                                                    )}
                                                </span>
                                            </div>
                                        </TableCell>
                                        <TableCell className="text-right">
                                            <div className="flex flex-col">
                                                <span>{employee.refunds}</span>
                                                <span className="text-muted-foreground text-sm">
                                                    {formatCurrency(
                                                        employee.refundSales,
                                                    )}
                                                </span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow className="border-t">
                                    <TableCell className="font-medium">
                                        Totals
                                    </TableCell>
                                    <TableCell className="text-right font-medium">
                                        {formatCurrency(employeeTotals.sales)}
                                    </TableCell>
                                    <TableCell className="text-right font-medium">
                                        {formatCurrency(employeeTotals.tips)}
                                    </TableCell>
                                    <TableCell className="text-right">
                                        <div className="flex flex-col font-medium">
                                            <span>{employeeTotals.voids}</span>
                                            <span className="text-muted-foreground text-sm">
                                                {formatCurrency(
                                                    employeeTotals.voidSales,
                                                )}
                                            </span>
                                        </div>
                                    </TableCell>
                                    <TableCell className="text-right">
                                        <div className="flex flex-col font-medium">
                                            <span>
                                                {employeeTotals.discounts}
                                            </span>
                                            <span className="text-muted-foreground text-sm">
                                                {formatCurrency(
                                                    employeeTotals.discountSales,
                                                )}
                                            </span>
                                        </div>
                                    </TableCell>
                                    <TableCell className="text-right">
                                        <div className="flex flex-col font-medium">
                                            <span>
                                                {employeeTotals.refunds}
                                            </span>
                                            <span className="text-muted-foreground text-sm">
                                                {formatCurrency(
                                                    employeeTotals.refundSales,
                                                )}
                                            </span>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </>
                        ) : (
                            <TableRow>
                                <TableCell>No data available</TableCell>
                                <TableCell className="text-right">-</TableCell>
                                <TableCell className="text-right">-</TableCell>
                                <TableCell className="text-right">-</TableCell>
                                <TableCell className="text-right">-</TableCell>
                                <TableCell className="text-right">-</TableCell>
                            </TableRow>
                        )}
                    </>
                </CollapsibleSection>

                {/* Labor Section */}
                <CollapsibleSection
                    title="Labor"
                    tooltip="This data is available when you use Employee Clock In on SnackOS."
                >
                    <>
                        <TableRow>
                            <TableCell>Total Labor Cost</TableCell>
                            <TableCell className="text-right">
                                {formatCurrency(laborData.totalLaborCost)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Total Labor Hours</TableCell>
                            <TableCell className="text-right">
                                {laborData.totalLaborHours.toFixed(1)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Labor Cost % of Sales</TableCell>
                            <TableCell className="text-right">
                                {laborData.laborCostPercentage.toFixed(1)}%
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Sales per Labor Hour</TableCell>
                            <TableCell className="text-right">
                                {formatCurrency(laborData.salesPerLaborHour)}
                            </TableCell>
                        </TableRow>
                    </>
                </CollapsibleSection>

                {/* Void Section */}
                <CollapsibleSection title="Void">
                    <>
                        <TableRow>
                            <TableCell>Orders</TableCell>
                            <TableCell className="text-right">
                                {voidData.orders}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Items</TableCell>
                            <TableCell className="text-right">
                                {voidData.items}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Voided Sales</TableCell>
                            <TableCell className="text-right">
                                {formatCurrency(voidData.totalSales)}
                            </TableCell>
                        </TableRow>
                    </>
                </CollapsibleSection>

                {/* Guests Section */}
                <CollapsibleSection
                    title="Guests"
                    tooltip="Guest count is only available for dine-in orders"
                >
                    <>
                        <TableRow>
                            <TableCell>Total Guests</TableCell>
                            <TableCell className="text-right">
                                {guestData.totalGuests}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Average Sales per Guest</TableCell>
                            <TableCell className="text-right">
                                {formatCurrency(
                                    guestData.averageTicketPerGuest,
                                )}
                            </TableCell>
                        </TableRow>
                    </>
                </CollapsibleSection>
            </div>
        </div>
    );
};
