import { RouteItems } from "#navigation/route-data";
import { Routes } from "#navigation/routes";

import { SidebarSection } from "../types";

const quickActions = [
    RouteItems[Routes.Search],
    RouteItems[Routes.Onboarding],
    RouteItems[Routes.WhatsNew],
    RouteItems[Routes.Support],
    RouteItems[Routes.Referral],
];

const store = [
    RouteItems[Routes.Index],
    RouteItems[Routes.BetaOrders],
    {
        ...RouteItems[Routes.Menu],
        items: [
            RouteItems[Routes.MenuEditor],
            RouteItems[Routes.MenuManager],
            RouteItems[Routes.MultiMenus],
            RouteItems[Routes.MenuInventoryManager],
        ],
    },
];

const marketing = [
    RouteItems[Routes.Promotion],
    RouteItems[Routes.GuestbookCustomers],
    RouteItems[Routes.GuestbookConversations],
    RouteItems[Routes.GuestbookCampaignsSMS],
    RouteItems[Routes.GuestbookCampaignsPush],
    RouteItems[Routes.SettingsGiftCard],
];

const data = [
    {
        ...RouteItems[Routes.SalesReports],
        items: [
            RouteItems[Routes.FinancialReportsEssentials],
            RouteItems[Routes.MinuteToMinute],
            RouteItems[Routes.ReportsSalesSummary],
            RouteItems[Routes.FinancialReportsPayoutHistory],
        ],
    },
    {
        ...RouteItems[Routes.Labor],
        items: [
            RouteItems[Routes.EmployeeTimeCards],
            RouteItems[Routes.Tipping],
        ],
    },
    {
        ...RouteItems[Routes.Reports],
        items: [
            RouteItems[Routes.ReportsCustomerDirectoryInsights],
            {
                ...RouteItems[Routes.ReportsMenu],
                items: [
                    RouteItems[Routes.ReportsMenuItemInsights],
                    RouteItems[Routes.ReportsMenuCategoryInsights],
                ],
            },
            RouteItems[Routes.ReportsSalesChannels],
            RouteItems[Routes.ReportsPromotions],
            {
                ...RouteItems[Routes.ReportsGiftCard],
                items: [
                    RouteItems[Routes.ReportsGiftCardPurchases],
                    RouteItems[Routes.ReportsGiftCardBalances],
                    RouteItems[Routes.ReportsGiftCardTransactions],
                    RouteItems[Routes.ReportsGiftCardLiabilities],
                ],
            },
            RouteItems[Routes.FinancialReportsCash],
            {
                ...RouteItems[Routes.ReportsLocation],
                items: [
                    RouteItems[Routes.ReportsLocationSales],
                    RouteItems[Routes.ReportsLocationMenuCategories],
                    RouteItems[Routes.ReportsLocationMenuItems],
                ],
            },
        ],
    },
];

const tools = [
    RouteItems[Routes.BuyHardware],
    RouteItems[Routes.DigitalPresenceOnlineOrdering],
    RouteItems[Routes.SettingsQrCode],
    RouteItems[Routes.DigitalPresenceWebsite],
    RouteItems[Routes.SettingsCatering],
    RouteItems[Routes.Invoices],
    RouteItems[Routes.SettingsIntegrations],
    RouteItems[Routes.DigitalPresenceApp],
];

const settings = [
    RouteItems[Routes.SettingsBusinessInfo],
    RouteItems[Routes.SettingsPermissions],
    RouteItems[Routes.Devices],
    {
        ...RouteItems[Routes.SettingsFrontOfHouse],
        items: [
            RouteItems[Routes.SettingsBrandAndAppearance],
            RouteItems[Routes.SettingsTipping],
            RouteItems[Routes.SettingsAllOrders],
            RouteItems[Routes.SettingsOnlineOrders],
            RouteItems[Routes.SettingsPlatforms],
            RouteItems[Routes.NewPromotion],
        ],
    },
    {
        ...RouteItems[Routes.SettingsBOH],
        items: [
            RouteItems[Routes.SettingsBackOfHouse],
            RouteItems[Routes.PrepStations],
            RouteItems[Routes.SettingsStoreTablesLayout],
            RouteItems[Routes.SettingsEmployees],
            RouteItems[Routes.Machines],
        ],
    },
    {
        ...RouteItems[Routes.AccountAndTax],
        items: [
            RouteItems[Routes.Payouts],
            RouteItems[Routes.PayoutsSchedule],
            RouteItems[Routes.SettingsTax],
            RouteItems[Routes.TaxForms],
            RouteItems[Routes.Billing],
        ],
    },
    {
        ...RouteItems[Routes.Other],
        items: [
            RouteItems[Routes.SettingsProducts],
            RouteItems[Routes.SettingsLegal],
            {
                ...RouteItems[Routes.SnackpassAdmin],
                items: [
                    RouteItems[Routes.InternalSettings],
                    RouteItems[Routes.InternalFees],
                    RouteItems[Routes.InternalSeniorSupport],
                    RouteItems[Routes.InternalDevices],
                ],
            },
        ],
    },
];

export const sidebarSections: readonly SidebarSection[] = [
    {
        key: "quick-actions",
        items: quickActions,
    },
    {
        key: "store",
        label: "Store",
        items: store,
        defaultOpen: true,
    },
    {
        key: "marketing",
        label: "Marketing",
        defaultOpen: true,
        items: marketing,
    },
    {
        key: "data",
        label: "Data",
        defaultOpen: false,
        items: data,
    },
    {
        key: "tools",
        label: "Tools",
        defaultOpen: false,
        items: tools,
    },
    {
        key: "settings",
        label: "Settings",
        defaultOpen: false,
        items: settings,
    },
] as const;
