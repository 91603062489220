import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    usePubNubWithToken,
    useUser,
    useConversations,
} from "@snackpass/conversations.hooks";
import { utils } from "@snackpass/conversations.client";

import { setActiveStoreNumUnreadChannels } from "src/redux/slices";
import { getActiveStore } from "src/redux/selectors";

export const useLoadActiveStoreNumUnreads = () => {
    const dispatch = useDispatch();
    const pubnub = usePubNubWithToken();
    const [pnUser] = useUser(pubnub, { uuid: pubnub.getUUID() });
    const activeStore = useSelector(getActiveStore);
    const { conversations } = useConversations(pubnub);

    const filteredUnreadChannels = useMemo(() => {
        if (!pnUser || conversations.length === 0 || !activeStore?._id) {
            return [];
        }
        const unreadChannels = utils.getUnreadChannels(pnUser);
        if (unreadChannels.length === 0) {
            return [];
        }
        // Filter channels to only include ones for the current store
        return unreadChannels.filter((channel) => {
            const [, storeId] = channel.split(".");
            return storeId.includes(activeStore._id);
        });
    }, [pnUser, conversations, activeStore?._id]);

    useEffect(() => {
        // Clear unread channels when store changes
        dispatch(setActiveStoreNumUnreadChannels([]));

        // Update unread channels if we have any
        if (filteredUnreadChannels.length > 0) {
            dispatch(setActiveStoreNumUnreadChannels(filteredUnreadChannels));
        }
    }, [dispatch, filteredUnreadChannels, activeStore?._id]);
};
