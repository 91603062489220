import { useContext } from "react";

import { ReferralContext } from "./referral-provider";

export function useReferral() {
    const context = useContext(ReferralContext);
    if (context === undefined) {
        throw new Error(
            "useReferral must be used within a ReferralProvider"
        );
    }
    return context;
} 