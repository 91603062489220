import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { Moment } from "moment-timezone";
import { useSelector } from "react-redux";

import { getActiveStore } from "src/redux/selectors";
import API from "src/api/graphql";
import { mapEmployeeShiftDetailsGQLtoTS } from "#time-and-attendance/utils";

export const useQueryLabor = (startDate: Moment, endDate: Moment) => {
    const activeStore = useSelector(getActiveStore);
    const storeId = activeStore?._id;

    return useQuery({
        queryKey: ["labor", storeId, startDate?.valueOf(), endDate?.valueOf()],
        queryFn: async () => {
            if (!storeId || !startDate || !endDate) {
                return {
                    totalLaborCost: 0,
                    laborCostPercentage: 0,
                    salesPerLaborHour: 0,
                    totalLaborHours: 0,
                };
            }

            // Convert moment dates to Luxon for the API call
            const startLuxon = DateTime.fromJSDate(startDate.toDate());
            const endLuxon = DateTime.fromJSDate(endDate.toDate());

            const paySummary = await API.stores.getTimecards({
                storeId,
                startDate: startLuxon.startOf("day").toJSDate().toString(),
                endDate: endLuxon.endOf("day").toJSDate().toString(),
            });

            if (!paySummary) {
                return {
                    totalLaborCost: 0,
                    laborCostPercentage: 0,
                    salesPerLaborHour: 0,
                    totalLaborHours: 0,
                };
            }

            const employeeShiftDetails = paySummary.employeeShiftDetails.map(
                mapEmployeeShiftDetailsGQLtoTS,
            );

            const totalLaborCost = employeeShiftDetails.reduce(
                (sum, shift) => sum + (shift.estWages || 0),
                0,
            );

            const totalLaborHours = employeeShiftDetails.reduce(
                (sum, shift) =>
                    sum +
                    (shift.regularHours || 0) +
                    (shift.overtimeHours || 0) +
                    (shift.doubleOvertimeHours || 0),
                0,
            );

            const laborCostPercentage =
                paySummary.netSales > 0
                    ? (totalLaborCost / paySummary.netSales) * 100
                    : 0;

            const salesPerLaborHour =
                totalLaborHours > 0 ? paySummary.netSales / totalLaborHours : 0;

            return {
                totalLaborCost,
                laborCostPercentage,
                salesPerLaborHour,
                totalLaborHours,
            };
        },
        enabled: !!storeId && !!startDate && !!endDate,
    });
};
